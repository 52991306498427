import { IntlKeys } from 'lib/localization/keys';

enum RavCodesSerialized {
  RavCodes = 'RavCodes',
}

enum RavCodesUnserialized {
  RavCodes = 'RavCodes ',
}

export enum RavCodesSerializedHeaders {
  id = 'id',
  referenceUploadID = 'referenceUploadID',
  animalCategory = 'animalCategory',
  ravCode = 'ravCode',
  animalDescriptionNl = 'animalDescriptionNl',
  animalDescriptionEn = 'animalDescriptionEn',
  animalEnclosureCategory = 'animalEnclosureCategory',
  ammonia = 'ammonia',
  ammoniaUnit = 'ammoniaUnit',
  uploadType = 'uploadType',
  filename = 'filename',
  status = 'status',
  validDateFrom = 'validDateFrom',
  validDateTo = 'validDateTo',
  notes = 'notes',
  concepts = 'concepts',
  uploadedAt = 'uploadedAt',
  uploadedBy = 'uploadedBy',
}

export const RavCodesUnserializedHeaders = {
  id: 'id',
  referenceUploadID: ['rav_code_upload', 'id'],
  animalCategory: 'animal_category',
  ravCode: 'rav_code',
  animalDescriptionNl: 'animal_description_nl',
  animalDescriptionEn: 'animal_description_en',
  animalEnclosureCategory: 'animal_enclosure_category',
  ammonia: 'ammonia',
  ammoniaUnit: 'ammonia_unit',
  uploadType: ['rav_code_upload', 'upload_type'],
  filename: ['rav_code_upload', 'file_name'],
  status: ['rav_code_upload', 'status'],
  validDateFrom: ['rav_code_upload', 'valid_from'],
  validDateTo: ['rav_code_upload', 'valid_to'],
  notes: ['rav_code_upload', 'notes'],
  concepts: ['rav_code_upload', 'concepts'],
  uploadedAt: ['rav_code_upload', 'uploaded_at'],
  uploadedBy: ['rav_code_upload', 'uploaded_by', 'full_name'],
} as const;

export const RAV_CODES = {
  [RavCodesSerialized.RavCodes]: {
    serialized: RavCodesSerialized.RavCodes,
    unserialized: RavCodesUnserialized.RavCodes,
    view: IntlKeys.referenceTablesRavCodes,

    columns: {
      [RavCodesSerializedHeaders.id]: {
        serialized: RavCodesSerializedHeaders.id,
        unserialized: RavCodesUnserializedHeaders.id,
      },
      [RavCodesSerializedHeaders.referenceUploadID]: {
        serialized: RavCodesSerializedHeaders.referenceUploadID,
        unserialized: RavCodesUnserializedHeaders.referenceUploadID,
      },
      [RavCodesSerializedHeaders.animalCategory]: {
        serialized: RavCodesSerializedHeaders.animalCategory,
        unserialized: RavCodesUnserializedHeaders.animalCategory,
        view: IntlKeys.referenceTablesRavCodesAnimalCategory,
      },
      [RavCodesSerializedHeaders.ravCode]: {
        serialized: RavCodesSerializedHeaders.ravCode,
        unserialized: RavCodesUnserializedHeaders.ravCode,
        view: IntlKeys.referenceTablesRavCodesRavCode,
      },
      [RavCodesSerializedHeaders.animalDescriptionNl]: {
        serialized: RavCodesSerializedHeaders.animalDescriptionNl,
        unserialized: RavCodesUnserializedHeaders.animalDescriptionNl,
        view: IntlKeys.referenceTablesRavCodesAnimalDescriptionNl,
      },
      [RavCodesSerializedHeaders.animalDescriptionEn]: {
        serialized: RavCodesSerializedHeaders.animalDescriptionEn,
        unserialized: RavCodesUnserializedHeaders.animalDescriptionEn,
        view: IntlKeys.referenceTablesRavCodesAnimalDescriptionEn,
      },
      [RavCodesSerializedHeaders.animalEnclosureCategory]: {
        serialized: RavCodesSerializedHeaders.animalEnclosureCategory,
        unserialized: RavCodesUnserializedHeaders.animalEnclosureCategory,
        view: IntlKeys.referenceTablesRavCodesAnimalEnclosureCategory,
      },
      [RavCodesSerializedHeaders.ammonia]: {
        serialized: RavCodesSerializedHeaders.ammonia,
        unserialized: RavCodesUnserializedHeaders.ammonia,
        view: IntlKeys.referenceTablesRavCodesAmmonia,
      },
      [RavCodesSerializedHeaders.ammoniaUnit]: {
        serialized: RavCodesSerializedHeaders.ammoniaUnit,
        unserialized: RavCodesUnserializedHeaders.ammoniaUnit,
        view: IntlKeys.referenceTablesRavCodesAmmoniaUnit,
      },
      [RavCodesSerializedHeaders.status]: {
        serialized: RavCodesSerializedHeaders.status,
        unserialized: RavCodesUnserializedHeaders.status,
      },
      [RavCodesSerializedHeaders.uploadType]: {
        serialized: RavCodesSerializedHeaders.uploadType,
        unserialized: RavCodesUnserializedHeaders.uploadType,
      },
      [RavCodesSerializedHeaders.notes]: {
        serialized: RavCodesSerializedHeaders.notes,
        unserialized: RavCodesUnserializedHeaders.notes,
      },
      [RavCodesSerializedHeaders.concepts]: {
        serialized: RavCodesSerializedHeaders.concepts,
        unserialized: RavCodesUnserializedHeaders.concepts,
        view: IntlKeys.concepts,
      },
      [RavCodesSerializedHeaders.filename]: {
        serialized: RavCodesSerializedHeaders.filename,
        unserialized: RavCodesUnserializedHeaders.filename,
      },
      [RavCodesSerializedHeaders.validDateFrom]: {
        serialized: RavCodesSerializedHeaders.validDateFrom,
        unserialized: RavCodesUnserializedHeaders.validDateFrom,
      },
      [RavCodesSerializedHeaders.validDateTo]: {
        serialized: RavCodesSerializedHeaders.validDateTo,
        unserialized: RavCodesUnserializedHeaders.validDateTo,
      },
      [RavCodesSerializedHeaders.uploadedAt]: {
        serialized: RavCodesSerializedHeaders.uploadedAt,
        unserialized: RavCodesUnserializedHeaders.uploadedAt,
      },
      [RavCodesSerializedHeaders.uploadedBy]: {
        serialized: RavCodesSerializedHeaders.uploadedBy,
        unserialized: RavCodesUnserializedHeaders.uploadedBy,
      },
    },
  },
};
