import { createReducer } from '@reduxjs/toolkit';
import memoize from 'lodash/memoize';
import { RootState } from 'store/store';

import { asyncAction, AsyncActionCreator, CustomAction, syncAction, SyncActionCreator } from 'lib/actions';
import { createSelector } from 'reselect';
import { DocumentData } from 'lib/excel/serilizers/Sheet';

import { backendKeyMap, DocumentTypes, FileInfo, UploadEntities } from 'store/entities/Uploads';

export const downloadFileAction = asyncAction('upload/downloadFile');

interface UploadReducerState {
  meta: {
    isLoading: boolean;
    errors: Array<string>;

    isAlreadyExist: boolean;
  };

  rawFile: File | null;
  fileInfo: FileInfo | null;
  data: object | null;
}

export interface AdditionalFileData {
  from?: string;
  to?: string;
  rating?: string;

  validDateFrom?: string | null;
  validDateTo?: string | null;
  status?: string;
  notes?: string;
  uploadType?: string;
  resources?: Record<string, any>;
  rows?: number[];
  concepts?: number[];
}

const partialInitialState: UploadReducerState = {
  meta: {
    isLoading: false,
    isAlreadyExist: false,
    errors: new Array<string>(),
  },
  rawFile: null,
  fileInfo: null,
  data: null,
};

const documentTypesArr = Object.keys(DocumentTypes) as UploadEntities[];

const initialState = documentTypesArr
  .filter((key) => Number.isNaN(parseInt(key)))
  .reduce((acc, docType) => {
    acc[docType] = partialInitialState;
    return acc;
  }, {} as Record<UploadEntities, UploadReducerState>);

const attachPayloadEntity = <P>(entity: UploadEntities) => (payload: P) => {
  return {
    payload: {
      ...payload,
      entityName: entity,
      backendEntityName: backendKeyMap[DocumentTypes[entity]],
    },
  };
};

export type UploadEntityConfigType = {
  entity: UploadEntities;
  backendEntityKey: string;
  uploadFileAction: AsyncActionCreator<{ file: File }>;
  uploadDataAction: AsyncActionCreator<{ data: DocumentData | File; additionalFileData: AdditionalFileData }>;
  fileExistAction: SyncActionCreator<{ oldFile: FileInfo }>;
  reUploadFileAction: AsyncActionCreator;
  resetAction: SyncActionCreator;
};

export const {
  certificatesDailyConfig,
  certificatesWeeklyConfig,
  certificatesHistoryConfig,
  VRIDatabaseConfig,
  referenceTablesConfig,
  carbonFootprintConfig,
  hfmOutputConfig,
} = (Object.keys(DocumentTypes) as UploadEntities[]).reduce((acc, entity) => {
  acc[`${entity}Config`] = {
    entity,
    backendEntityKey: backendKeyMap[entity],
    uploadFileAction: asyncAction(`upload/${entity}/file`, attachPayloadEntity(entity)),
    uploadDataAction: asyncAction(`upload/${entity}/data`, attachPayloadEntity(entity)),
    fileExistAction: syncAction(`upload/${entity}/fileAlreadyExist`, attachPayloadEntity(entity)),
    reUploadFileAction: asyncAction(`upload/${entity}/reUploadFile`, attachPayloadEntity(entity)),
    resetAction: syncAction(`upload/${entity}/reset`, attachPayloadEntity(entity)),
  };

  return acc;
}, {} as Record<string, UploadEntityConfigType>);

export const uploadActionConfigs = [
  certificatesDailyConfig,
  certificatesWeeklyConfig,
  certificatesHistoryConfig,
  VRIDatabaseConfig,
  referenceTablesConfig,
  carbonFootprintConfig,
  hfmOutputConfig,
];

const actionMap = uploadActionConfigs.reduce((
  acc, //
  {
    //
    entity,
    uploadFileAction,
    uploadDataAction,
    resetAction,
    fileExistAction,
    reUploadFileAction,
  },
) => {
  Object.assign(acc, {
    [uploadFileAction.request]: (draft, { payload }) => {
      draft[entity].meta.isLoading = true;

      draft[entity].rawFile = payload.file;
    },
    [uploadFileAction.success]: (draft, { payload }) => {
      draft[entity].meta.isLoading = false;

      draft[entity].fileInfo = payload.fileInfo;
    },
    [uploadFileAction.failure]: (draft, { payload }) => {
      draft[entity].meta.isLoading = false;

      draft[entity].meta.errors.push(payload.errors);
    },

    [fileExistAction.type]: (draft, { payload }) => {
      draft[entity].meta.isLoading = false;
      draft[entity].meta.isAlreadyExist = true;

      draft[entity].fileInfo = payload.oldFile;
    },

    [reUploadFileAction.request]: (draft) => {
      draft[entity].meta.isLoading = true;
      draft[entity].meta.isAlreadyExist = false;
    },
    [reUploadFileAction.success]: (draft, { payload }) => {
      draft[entity].meta.isLoading = false;
      draft[entity].fileInfo = payload.fileInfo;
    },
    [reUploadFileAction.failure]: (draft) => {
      draft[entity].meta.isLoading = false;
    },

    [uploadDataAction.request]: (draft) => {
      draft[entity].meta.isLoading = true;
    },
    [uploadDataAction.success]: (draft) => {
      draft[entity].meta.isLoading = false;
    },
    [uploadDataAction.failure]: (draft, { payload }) => {
      if (payload?.response?.data?.errors?.length) {
        draft[entity].meta.errors.push(payload.response.data.errors);
      }
      if (payload?.response?.data?.record_errors?.length) {
        draft[entity].meta.errors.push(payload.response.data.record_errors);
      }
      draft[entity].meta.isLoading = false;
    },

    [resetAction.type]: (draft) => {
      draft[entity] = initialState[entity];
    },
  } as Record<string, (draft: typeof initialState, action: CustomAction) => void>);

  return acc;
}, {} as Record<string, (draft: typeof initialState) => void>);

const uploadReducer = createReducer(initialState, {
  ...actionMap,
});

const uploadSelector = (state: RootState) => state.upload;

export const isUploadLoading = createSelector(uploadSelector, (upload) => upload.certificatesDaily.meta.isLoading);
export const uploadEntitySelector = createSelector(uploadSelector, (upload) =>
  memoize((entity: UploadEntities) => upload[entity]),
);
export const uploadMetaEntitySelector = createSelector(uploadSelector, (upload) =>
  memoize((entity: UploadEntities) => upload[entity].meta),
);
export const uploadTokenEntitySelector = createSelector(uploadSelector, (upload) =>
  memoize((entity: UploadEntities) => upload[entity].fileInfo?.upload_token),
);

export default uploadReducer;
