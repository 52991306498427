import { IntlKeys } from 'lib/localization/keys';

enum PigHousingEmissionFactorsSerialized {
  PigHousingEmissionFactors = 'PigHousingEmissionFactors',
}

enum PigHousingEmissionFactorsUnserialized {
  PigHousingEmissionFactors = 'PigHousingEmissionFactors',
}

export enum PigHousingEmissionFactorsSerializedHeaders {
  id = 'id',
  referenceUploadID = 'referenceUploadID',
  livestockCategory = 'livestockCategory',
  housingSystem = 'housingSystem',
  yearValue = 'yearValue',
  emissionValue = 'emissionValue',
  unit = 'unit',
  uploadType = 'uploadType',
  filename = 'filename',
  status = 'status',
  validDateFrom = 'validDateFrom',
  validDateTo = 'validDateTo',
  notes = 'notes',
  concepts = 'concepts',
  uploadedAt = 'uploadedAt',
  uploadedBy = 'uploadedBy',
}

export const PigHousingEmissionFactorsUnserializedHeaders = {
  id: 'id',
  referenceUploadID: ['pig_housing_emission_factor_upload', 'id'],
  livestockCategory: 'livestock_category',
  housingSystem: 'housing_system',
  yearValue: 'year_value',
  emissionValue: 'emission_value',
  unit: 'unit',
  uploadType: ['pig_housing_emission_factor_upload', 'upload_type'],
  filename: ['pig_housing_emission_factor_upload', 'file_name'],
  status: ['pig_housing_emission_factor_upload', 'status'],
  validDateFrom: ['pig_housing_emission_factor_upload', 'valid_from'],
  validDateTo: ['pig_housing_emission_factor_upload', 'valid_to'],
  notes: ['pig_housing_emission_factor_upload', 'notes'],
  concepts: ['pig_housing_emission_factor_upload', 'concepts'],
  uploadedAt: ['pig_housing_emission_factor_upload', 'uploaded_at'],
  uploadedBy: ['pig_housing_emission_factor_upload', 'uploaded_by', 'full_name'],
} as const;

export const PIG_HOUSING_EMISSION_FACTORS = {
  [PigHousingEmissionFactorsSerialized.PigHousingEmissionFactors]: {
    serialized: PigHousingEmissionFactorsSerialized.PigHousingEmissionFactors,
    unserialized: PigHousingEmissionFactorsUnserialized.PigHousingEmissionFactors,
    view: IntlKeys.referenceTablesPigHousingEmissionFactors,

    columns: {
      [PigHousingEmissionFactorsSerializedHeaders.id]: {
        serialized: PigHousingEmissionFactorsSerializedHeaders.id,
        unserialized: PigHousingEmissionFactorsUnserializedHeaders.id,
      },
      [PigHousingEmissionFactorsSerializedHeaders.referenceUploadID]: {
        serialized: PigHousingEmissionFactorsSerializedHeaders.referenceUploadID,
        unserialized: PigHousingEmissionFactorsUnserializedHeaders.referenceUploadID,
      },
      [PigHousingEmissionFactorsSerializedHeaders.livestockCategory]: {
        serialized: PigHousingEmissionFactorsSerializedHeaders.livestockCategory,
        unserialized: PigHousingEmissionFactorsUnserializedHeaders.livestockCategory,
        view: IntlKeys.referenceTablesPigHousingEmissionFactorsCategory,
      },
      [PigHousingEmissionFactorsSerializedHeaders.housingSystem]: {
        serialized: PigHousingEmissionFactorsSerializedHeaders.housingSystem,
        unserialized: PigHousingEmissionFactorsUnserializedHeaders.housingSystem,
        view: IntlKeys.referenceTablesPigHousingEmissionFactorsSystem,
      },
      [PigHousingEmissionFactorsSerializedHeaders.yearValue]: {
        serialized: PigHousingEmissionFactorsSerializedHeaders.yearValue,
        unserialized: PigHousingEmissionFactorsUnserializedHeaders.yearValue,
        view: IntlKeys.referenceTablesPigHousingEmissionFactorsYearValue,
      },
      [PigHousingEmissionFactorsSerializedHeaders.emissionValue]: {
        serialized: PigHousingEmissionFactorsSerializedHeaders.emissionValue,
        unserialized: PigHousingEmissionFactorsUnserializedHeaders.emissionValue,
        view: IntlKeys.referenceTablesPigHousingEmissionFactorsEmissionValue,
      },
      [PigHousingEmissionFactorsSerializedHeaders.unit]: {
        serialized: PigHousingEmissionFactorsSerializedHeaders.unit,
        unserialized: PigHousingEmissionFactorsUnserializedHeaders.unit,
        view: IntlKeys.referenceTablesPigHousingEmissionFactorsUnit,
      },
      [PigHousingEmissionFactorsSerializedHeaders.status]: {
        serialized: PigHousingEmissionFactorsSerializedHeaders.status,
        unserialized: PigHousingEmissionFactorsUnserializedHeaders.status,
      },
      [PigHousingEmissionFactorsSerializedHeaders.uploadType]: {
        serialized: PigHousingEmissionFactorsSerializedHeaders.uploadType,
        unserialized: PigHousingEmissionFactorsUnserializedHeaders.uploadType,
      },
      [PigHousingEmissionFactorsSerializedHeaders.notes]: {
        serialized: PigHousingEmissionFactorsSerializedHeaders.notes,
        unserialized: PigHousingEmissionFactorsUnserializedHeaders.notes,
      },
      [PigHousingEmissionFactorsSerializedHeaders.concepts]: {
        serialized: PigHousingEmissionFactorsSerializedHeaders.concepts,
        unserialized: PigHousingEmissionFactorsUnserializedHeaders.concepts,
        view: IntlKeys.concepts,
      },
      [PigHousingEmissionFactorsSerializedHeaders.filename]: {
        serialized: PigHousingEmissionFactorsSerializedHeaders.filename,
        unserialized: PigHousingEmissionFactorsUnserializedHeaders.filename,
      },
      [PigHousingEmissionFactorsSerializedHeaders.validDateFrom]: {
        serialized: PigHousingEmissionFactorsSerializedHeaders.validDateFrom,
        unserialized: PigHousingEmissionFactorsUnserializedHeaders.validDateFrom,
      },
      [PigHousingEmissionFactorsSerializedHeaders.validDateTo]: {
        serialized: PigHousingEmissionFactorsSerializedHeaders.validDateTo,
        unserialized: PigHousingEmissionFactorsUnserializedHeaders.validDateTo,
      },
      [PigHousingEmissionFactorsSerializedHeaders.uploadedAt]: {
        serialized: PigHousingEmissionFactorsSerializedHeaders.uploadedAt,
        unserialized: PigHousingEmissionFactorsUnserializedHeaders.uploadedAt,
      },
      [PigHousingEmissionFactorsSerializedHeaders.uploadedBy]: {
        serialized: PigHousingEmissionFactorsSerializedHeaders.uploadedBy,
        unserialized: PigHousingEmissionFactorsUnserializedHeaders.uploadedBy,
      },
    },
  },
};
