import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { CellSerializer } from 'lib/excel/serilizers/Cell';

import {
  AdminUsersSerializedHeaders,
  PERMISSION_PROFILE_TEMPLATES,
} from 'lib/tables/config/admin/permission/profileTemplates/config';
import { SERVICE_FIELDS } from 'lib/excel/config/service';
import { ResourcesWrapper } from 'lib/http/utils';

export enum PERMISSION_RULES {
  DATA_CATALOGUE = 'entities',
  VRI = 'report_uploads',
  MY_FARMS = 'my_farms',
  MOVEMENT_EVENTS = 'movement_events',
  VETERINARIAN_DATA = 'veterinarian_data',
  DELIVERY_MESSAGES = 'delivery_messages',
  GENETICS = 'genetics',
  SLAUGHTER_MESSAGES = 'slaughter_messages',
  SLAUGHTER_PRICE_CORRECTIONS = 'slaughter_price_corrections',
  INVOICES = 'invoice_messages',
  CERTIFICATES_WELFARE = 'welfare_validity_certificate',
  CERTIFICATES_IKB = 'ikb_validity_certificate',
  CERTIFICATES_KIWA_WEEKLY = 'kiwa_weekly',
  CERTIFICATES_KIWA_DAILY = 'certificates',
  ANIMAL_PASSPORTS = 'animal_passports',
  PARTICIPATIONS = 'participations',
  ORGANIZATIONS = 'organizations',
  TRANSPORTS = 'transports',
  FARMER_INPUT = 'company_reports',
  FARMER_INPUT_SURVEY_JS = 'company_reports_survey_js',
  VRI_TOOL = 'calculated_reports',
  CERTIFICATES_ALL = 'certificates.all',
  MY_JOIN_DATA = 'join_data_link',
  CARBON_FOOTPRINTS = 'carbon_dioxide_footprints',
  MONITORING_DASHBOARD = 'request_logs',
  RAW_MATERIAL_ORIGINS = 'raw_material_origins',
  REFERENCE_TABLES = 'reference_tables',
  FARM_MANAGEMENT = 'farm_management',
  ERRORS_LOG = 'error_logs',
  HFM_OUTPUTS = 'hfm_outputs',
  VRI_COMPARISON_TOOL = 'vri_comparison_tool',
  LABELS = 'labels',
}

export type PermissionRulesListResponseEntry = {
  id: number;
  title: string;
  name: PERMISSION_RULES;
};

export type PermissionRulesAttrResponseEntry = PermissionRulesListResponseEntry & {
  is_allowed: boolean;

  notes?: string;
  allowed_from?: string | null;
  allowed_to?: string | null;
};

export type PermissionRulesAttrSubmitEntry = {
  permission_rule_id: number;
  is_allowed: boolean;
  //
  notes?: string;
  allowed_from?: string | null;
  allowed_to?: string | null;
};

export interface PermissionProfileResponseEntry {
  id: number;
  // name: '3voluptatem'; unused
  title: string;
  notes: string;
  permission_rules: PermissionRulesAttrResponseEntry[];
}

export type PermissionProfileResponseEntrySerialized = PermissionProfileResponseEntry &
  {
    [key in PERMISSION_RULES]: boolean;
  };

const { columns, serialized, unserialized } = PERMISSION_PROFILE_TEMPLATES.PermissionProfileTemplates;

const getPermissionProfileSerializer = (data: DocumentData) =>
  new DocumentSerializer(data, [
    new SheetSerializer(unserialized, serialized, [
      //
      new CellSerializer(SERVICE_FIELDS.id.unserialized, SERVICE_FIELDS.id.serialized),
      new CellSerializer(
        columns[AdminUsersSerializedHeaders.title].unserialized,
        columns[AdminUsersSerializedHeaders.title].serialized,
      ),
      new CellSerializer(
        columns[AdminUsersSerializedHeaders.notes].unserialized,
        columns[AdminUsersSerializedHeaders.notes].serialized,
      ),

      //
      new CellSerializer(
        columns[AdminUsersSerializedHeaders.permissionRules].unserialized,
        columns[AdminUsersSerializedHeaders.permissionRules].serialized,
      ),
      //
      new CellSerializer(
        columns[AdminUsersSerializedHeaders.dataCatalogue].unserialized,
        columns[AdminUsersSerializedHeaders.dataCatalogue].serialized,
      ),
      new CellSerializer(
        columns[AdminUsersSerializedHeaders.vri].unserialized,
        columns[AdminUsersSerializedHeaders.vri].serialized,
      ),
      new CellSerializer(
        columns[AdminUsersSerializedHeaders.myFarms].unserialized,
        columns[AdminUsersSerializedHeaders.myFarms].serialized,
      ),
      new CellSerializer(
        columns[AdminUsersSerializedHeaders.movementEvents].unserialized,
        columns[AdminUsersSerializedHeaders.movementEvents].serialized,
      ),
      new CellSerializer(
        columns[AdminUsersSerializedHeaders.veterinarianData].unserialized,
        columns[AdminUsersSerializedHeaders.veterinarianData].serialized,
      ),
      new CellSerializer(
        columns[AdminUsersSerializedHeaders.deliveryMessages].unserialized,
        columns[AdminUsersSerializedHeaders.deliveryMessages].serialized,
      ),
      new CellSerializer(
        columns[AdminUsersSerializedHeaders.genetics].unserialized,
        columns[AdminUsersSerializedHeaders.genetics].serialized,
      ),
      new CellSerializer(
        columns[AdminUsersSerializedHeaders.slaughterMessages].unserialized,
        columns[AdminUsersSerializedHeaders.slaughterMessages].serialized,
      ),
      new CellSerializer(
        columns[AdminUsersSerializedHeaders.slaughterPriceCorrections].unserialized,
        columns[AdminUsersSerializedHeaders.slaughterPriceCorrections].serialized,
      ),
      new CellSerializer(
        columns[AdminUsersSerializedHeaders.invoiceMessages].unserialized,
        columns[AdminUsersSerializedHeaders.invoiceMessages].serialized,
      ),
      new CellSerializer(
        columns[AdminUsersSerializedHeaders.welfareValidityCertificate].unserialized,
        columns[AdminUsersSerializedHeaders.welfareValidityCertificate].serialized,
      ),
      new CellSerializer(
        columns[AdminUsersSerializedHeaders.ikbValidityCertificate].unserialized,
        columns[AdminUsersSerializedHeaders.ikbValidityCertificate].serialized,
      ),
      new CellSerializer(
        columns[AdminUsersSerializedHeaders.kiwaWeekly].unserialized,
        columns[AdminUsersSerializedHeaders.kiwaWeekly].serialized,
      ),
      new CellSerializer(
        columns[AdminUsersSerializedHeaders.kiwaDaily].unserialized,
        columns[AdminUsersSerializedHeaders.kiwaDaily].serialized,
      ),
      new CellSerializer(
        columns[AdminUsersSerializedHeaders.animalPassports].unserialized,
        columns[AdminUsersSerializedHeaders.animalPassports].serialized,
      ),
      new CellSerializer(
        columns[AdminUsersSerializedHeaders.participations].unserialized,
        columns[AdminUsersSerializedHeaders.participations].serialized,
      ),
      new CellSerializer(
        columns[AdminUsersSerializedHeaders.organizations].unserialized,
        columns[AdminUsersSerializedHeaders.organizations].serialized,
      ),
      new CellSerializer(
        columns[AdminUsersSerializedHeaders.transports].unserialized,
        columns[AdminUsersSerializedHeaders.transports].serialized,
      ),
      new CellSerializer(
        columns[AdminUsersSerializedHeaders.companyReports].unserialized,
        columns[AdminUsersSerializedHeaders.companyReports].serialized,
      ),
      new CellSerializer(
        columns[AdminUsersSerializedHeaders.companyReportsSurveyJs].unserialized,
        columns[AdminUsersSerializedHeaders.companyReportsSurveyJs].serialized,
      ),
      new CellSerializer(
        columns[AdminUsersSerializedHeaders.vriTool].unserialized,
        columns[AdminUsersSerializedHeaders.vriTool].serialized,
      ),
      new CellSerializer(
        columns[AdminUsersSerializedHeaders.certificatesAll].unserialized,
        columns[AdminUsersSerializedHeaders.certificatesAll].serialized,
      ),
      new CellSerializer(
        columns[AdminUsersSerializedHeaders.myJoinData].unserialized,
        columns[AdminUsersSerializedHeaders.myJoinData].serialized,
      ),
      new CellSerializer(
        columns[AdminUsersSerializedHeaders.carbonFootprints].unserialized,
        columns[AdminUsersSerializedHeaders.carbonFootprints].serialized,
      ),
      new CellSerializer(
        columns[AdminUsersSerializedHeaders.monitoringDashboard].unserialized,
        columns[AdminUsersSerializedHeaders.monitoringDashboard].serialized,
      ),
      new CellSerializer(
        columns[AdminUsersSerializedHeaders.rawMaterialOrigins].unserialized,
        columns[AdminUsersSerializedHeaders.rawMaterialOrigins].serialized,
      ),
      new CellSerializer(
        columns[AdminUsersSerializedHeaders.referenceTables].unserialized,
        columns[AdminUsersSerializedHeaders.referenceTables].serialized,
      ),
      new CellSerializer(
        columns[AdminUsersSerializedHeaders.farmManagement].unserialized,
        columns[AdminUsersSerializedHeaders.farmManagement].serialized,
      ),
      new CellSerializer(
        columns[AdminUsersSerializedHeaders.errorsLog].unserialized,
        columns[AdminUsersSerializedHeaders.errorsLog].serialized,
      ),
      new CellSerializer(
        columns[AdminUsersSerializedHeaders.hfmOutputs].unserialized,
        columns[AdminUsersSerializedHeaders.hfmOutputs].serialized,
      ),
      new CellSerializer(
        columns[AdminUsersSerializedHeaders.vriComparisonTool].unserialized,
        columns[AdminUsersSerializedHeaders.vriComparisonTool].serialized,
      ),
    ]),
  ]);

export const serializePermissionProfile = (
  { permission_rules, ...rest } = {} as PermissionProfileResponseEntry,
): PermissionProfileResponseEntrySerialized => {
  return {
    ...rest,
    permission_rules,
    ...permission_rules.reduce((acc, { name, is_allowed }) => {
      //
      acc[name] = is_allowed;
      return acc;
    }, {} as Record<PERMISSION_RULES, boolean>),
  };
};

export const serializePermissionProfileTemplates = (serverData: ResourcesWrapper<PermissionProfileResponseEntry>) => {
  const transformedResources = serverData.data.resources.map(serializePermissionProfile);

  const data = {
    [unserialized]: transformedResources,
  };

  const serializer = getPermissionProfileSerializer(data);

  return serializer.serialize();
};
