import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer } from 'lib/excel/serilizers/Cell';
import { ResourcesWrapper, ResourceWrapper } from 'lib/http/utils';

import { SERVICE_FIELDS } from 'lib/excel/config/service';

import { UserPassportPayload } from 'store/entities/userPassports';

import {
  COMPANY_REPORTS,
  COMPANY_REPORTS_SUBMIT,
  CompanyReportsSubmitSerializedHeaders,
  CompanyReportsUnserializedHeaders,
  RAV_CODES,
} from './config';

export type CompanyReportPayload = {
  id: number;
  status?: 'draft' | 'submitted';

  // general
  id_type: string | null; // id type
  user_passport: UserPassportPayload | null; // party id type code
  reporting_period_date_start: string; // '2021-03-29'
  reporting_period_date_end: string; // '2021-05-12'
  household_composition?: string | null;
  by_products_or_loose?: string | null;
  feed_fermentation?: string | null;
  company_type?: string | null;

  // rav-codes
  farmer_rav_codes: Array<{
    stable_number: string;
    rav_code?: string;
    animal_count?: number; // integer?
    storage_duration_of_manure_under_the_stable?: string;
  }>;
  is_manure_being_removed?: string | null;
  manure_removing_frequency?: string | null;
  manure_digestion?: string | null;

  manure_file_name?: string | null;
  manure_file?: File | null;

  farmer_feed_suppliers_piglets: Array<string>;
  farmer_feed_suppliers_sows: Array<string>;
  farmer_feed_suppliers_fattening_pigs: Array<string>;

  //
  other_manure_storage_types: string | null;
  duration_of_storage_in_current_type?: string | null;
  monthly_manure_removal?: string | null;

  // production-results-1
  avg_sow_count?: number;
  avg_rearing_sow_count?: number;
  avg_piglet_count?: number;
  total_sold_sows_price?: number;
  total_sold_piglets_price?: number;
  avg_piglet_count_per_year?: number;
  breeding_feed_conversion?: number;
  average_number_suckling_piglets?: number;

  // production-results-2
  avg_finishing_pig_count?: number;
  avg_purchased_piglet_weight?: number;
  fattening_feed_conversion?: number;
  culling_percentage?: number;
  growth_per_day?: number;
  ew_conversion?: number;

  // energy-use
  non_renewable_gas_purchased_volume?: number;
  renewable_gas_purchased_volume?: number;
  non_renewable_electricity_purchased_volume?: number;
  renewable_electricity_purchased_volume?: number;
  tap_water_used_volume?: number;

  water_from_own_source: boolean;
  water_meter_available: boolean;
  gas_meter_available: boolean;
  electricity_meter_available: boolean;
  shared_smart_meter: boolean;

  quantity_of_electricity_produced?: number;
  product_count: number;
  fermentation_count: number;
  manure_count: number;

  updated_at: string;
  updated_by: {
    email: string;
    full_name: string;
  };
};

export type CompanyReportSerialized = {
  id: number;
  status: 'draft' | 'submitted';

  // general
  idType: string | null;
  partyIdTypeCode: number | null;
  reportingPeriodDateStart: string;
  reportingPeriodDateEnd: string;
  householdComposition?: string | null;
  companyType: 'closed' | 'partly_closed' | 'sows' | 'sows_or_piglets' | 'piglets' | 'piglets_or_pigs' | 'finishing_pigs' | undefined;
  byProductsOrLoose: string | null;
  feedFermentation: string | null;
  //
  ubn: string;

  // rav-codes
  farmerRavCodes: Array<{
    stableNumber: string;
    ravCode: string;
    animalCount?: number;
    storageDurationOfManureUnderTheStable: string;
  }>;

  feedSuppliersPiglets: Array<string>;
  feedSuppliersSows: Array<string>;
  feedSuppliersFatteningPigs: Array<string>;

  //
  otherManureStorageTypes: string | null;
  durationOfStorageInCurrentType?: string | null;
  monthlyManureRemoval?: string | null;
  isManureBeingRemoved: string | null;
  manureRemovingFrequency: string | null;
  manureDigestion: string | null;

  // production-results-1
  avgSowCount?: number;
  avgRearingSowCount?: number;
  avgPigletCount?: number;
  totalSoldSowsPrice?: number;
  totalSoldPigletsPrice?: number;
  avgPigletCountPerYear?: number;
  breedingFeedConversion?: number;
  averageNumberSucklingPiglets?: number;

  // production-results-2
  avgFinishingPigCount?: number;
  avgPurchasedPigletWeight?: number;
  fatteningFeedConversion?: number;
  cullingPercentage?: number;
  growthPerDay?: number;
  ewConversion?: number;

  // energy-use
  nonRenewableGasPurchasedVolume?: number;
  renewableGasPurchasedVolume?: number;
  nonRenewableElectricityPurchasedVolume?: number;
  renewableElectricityPurchasedVolume?: number;
  tapWaterUsedVolume?: number;

  waterFromOwnSource: boolean;
  waterMeterAvailable: boolean;
  gasMeterAvailable: boolean;
  electricityMeterAvailable: boolean;
  sharedSmartMeter: boolean;

  quantityOfElectricityProduced?: number;
  solarPanelsSquare?: number;
  productCount: number;
  fermentationCount: number;
  manureCount: number;

  manureFileName?: string;
  manureFile?: string;

  updatedAt: string;
  updatedBy: string;
};

export type CompanyReportSubmitPayload = Omit<
  CompanyReportPayload,
  'id' | 'farmer_rav_codes' | 'updated_at' | 'updatedBy' | 'productCount' | 'fermentationCount' | 'manureCount'
> & {
  farmer_rav_codes_attributes: CompanyReportPayload['farmer_rav_codes'];
};

export type CompanyReportSubmitSerialized = Omit<
  CompanyReportSerialized,
  'id' | 'farmerRavCodes' | 'updatedAt' | 'updatedBy' | 'ubn' | 'productCount' | 'fermentationCount' | 'manureCount'
> & {
  farmerRavCodesAttributes: CompanyReportSerialized['farmerRavCodes'];
};

const { columns: defaultColumns } = COMPANY_REPORTS.CompanyReports;
const { columns: submitColumns } = COMPANY_REPORTS_SUBMIT.CompanyReportsSubmit;
const { columns: ravColumns } = RAV_CODES.RavCodes;

const getRavCodesSerializer = <S = 'serialize' | 'unserialize'>(
  data: DocumentData<
    S extends 'serialize' ? CompanyReportPayload['farmer_rav_codes'] : CompanyReportSerialized['farmerRavCodes']
  >,
) => {
  return new DocumentSerializer<
    CompanyReportPayload['farmer_rav_codes'][0],
    CompanyReportSerialized['farmerRavCodes'][0]
  >(data, [
    new SheetSerializer(RAV_CODES.RavCodes.unserialized, RAV_CODES.RavCodes.serialized, [
      new CellSerializer(ravColumns.stableNumber.unserialized, ravColumns.stableNumber.serialized),
      new CellSerializer(ravColumns.ravCode.unserialized, ravColumns.ravCode.serialized),
      new CellSerializer(ravColumns.animalCount.unserialized, ravColumns.animalCount.serialized),
      new CellSerializer(
        ravColumns.storageDurationOfManureUnderTheStable.unserialized,
        ravColumns.storageDurationOfManureUnderTheStable.serialized,
      ),
    ]),
  ]);
};

const serializeRavCodes = (
  resource: CompanyReportPayload['farmer_rav_codes'],
): CompanyReportSerialized['farmerRavCodes'] => {
  const { serialized, unserialized } = RAV_CODES.RavCodes;
  const data = {
    [unserialized]: resource,
  };

  const serializer = getRavCodesSerializer<'serialize'>(data);

  return serializer.serialize()[serialized];
};

const unserializeRavCodes = (
  resource: CompanyReportSerialized['farmerRavCodes'],
): CompanyReportPayload['farmer_rav_codes'] => {
  const { serialized, unserialized } = RAV_CODES.RavCodes;
  const data = {
    [unserialized]: resource,
  };

  const serializer = getRavCodesSerializer<'unserialize'>(data);

  return serializer.unserialize()[serialized];
};

const getDefaultSerializer = (data: DocumentData<CompanyReportPayload[]>) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(COMPANY_REPORTS.CompanyReports.unserialized, COMPANY_REPORTS.CompanyReports.serialized, [
      new CellSerializer(SERVICE_FIELDS.id.unserialized, SERVICE_FIELDS.id.serialized),
      new CellSerializer(defaultColumns.status.unserialized, defaultColumns.status.serialized),
      new CellSerializer(defaultColumns.idType.unserialized, defaultColumns.idType.serialized),
      new CellSerializer(defaultColumns.byProductsOrLoose.unserialized, defaultColumns.byProductsOrLoose.serialized),
      new CellSerializer(defaultColumns.feedFermentation.unserialized, defaultColumns.feedFermentation.serialized),
      new CellSerializer(
        defaultColumns.feedSuppliersPiglets.unserialized,
        defaultColumns.feedSuppliersPiglets.serialized,
      ),
      new CellSerializer(
        defaultColumns.feedSuppliersSows.unserialized,
        defaultColumns.feedSuppliersSows.serialized,
      ),
      new CellSerializer(
        defaultColumns.feedSuppliersFatteningPigs.unserialized,
        defaultColumns.feedSuppliersFatteningPigs.serialized,
      ),
      new CellSerializer(
        defaultColumns.otherManureStorageTypes.unserialized,
        defaultColumns.otherManureStorageTypes.serialized,
      ),
      new CellSerializer(
        defaultColumns.durationOfStorageInCurrentType.unserialized,
        defaultColumns.durationOfStorageInCurrentType.serialized,
      ),
      new CellSerializer(
        defaultColumns.monthlyManureRemoval.unserialized,
        defaultColumns.monthlyManureRemoval.serialized,
      ),
      new CellSerializer(
        defaultColumns.isManureBeingRemoved.unserialized,
        defaultColumns.isManureBeingRemoved.serialized,
      ),
      new CellSerializer(
        defaultColumns.manureRemovingFrequency.unserialized,
        defaultColumns.manureRemovingFrequency.serialized,
      ),
      new CellSerializer(
        defaultColumns.manureDigestion.unserialized,
        defaultColumns.manureDigestion.serialized,
      ),
      new CellSerializer(defaultColumns.partyIdTypeCode.unserialized, defaultColumns.partyIdTypeCode.serialized),
      new CellSerializer(defaultColumns.ubn.unserialized, defaultColumns.ubn.serialized),
      new CellSerializer(
        defaultColumns.reportingPeriodDateStart.unserialized,
        defaultColumns.reportingPeriodDateStart.serialized,
      ),
      new CellSerializer(
        defaultColumns.reportingPeriodDateEnd.unserialized,
        defaultColumns.reportingPeriodDateEnd.serialized,
      ),
      new CellSerializer(
        defaultColumns.householdComposition.unserialized,
        defaultColumns.householdComposition.serialized,
      ),
      new CellSerializer(defaultColumns.companyType.unserialized, defaultColumns.companyType.serialized),
      //////
      new CellSerializer(
        defaultColumns.farmerRavCodes.unserialized,
        defaultColumns.farmerRavCodes.serialized,
        (data, sheetData, excelRow, propertyOriginalPath) => {
          const property = excelRow[propertyOriginalPath as typeof CompanyReportsUnserializedHeaders.farmerRavCodes];

          return serializeRavCodes(property);
        },
      ),
      //////
      new CellSerializer(defaultColumns.avgSowCount.unserialized, defaultColumns.avgSowCount.serialized),
      new CellSerializer(defaultColumns.avgRearingSowCount.unserialized, defaultColumns.avgRearingSowCount.serialized),
      new CellSerializer(defaultColumns.avgPigletCount.unserialized, defaultColumns.avgPigletCount.serialized),
      new CellSerializer(defaultColumns.totalSoldSowsPrice.unserialized, defaultColumns.totalSoldSowsPrice.serialized),
      new CellSerializer(
        defaultColumns.totalSoldPigletsPrice.unserialized,
        defaultColumns.totalSoldPigletsPrice.serialized,
      ),
      new CellSerializer(
        defaultColumns.avgPigletCountPerYear.unserialized,
        defaultColumns.avgPigletCountPerYear.serialized,
      ),
      new CellSerializer(
        defaultColumns.breedingFeedConversion.unserialized,
        defaultColumns.breedingFeedConversion.serialized,
      ),
      new CellSerializer(
        defaultColumns.averageNumberSucklingPiglets.unserialized,
        defaultColumns.averageNumberSucklingPiglets.serialized,
      ),
      new CellSerializer(
        defaultColumns.avgFinishingPigCount.unserialized,
        defaultColumns.avgFinishingPigCount.serialized,
      ),
      new CellSerializer(
        defaultColumns.avgPurchasedPigletWeight.unserialized,
        defaultColumns.avgPurchasedPigletWeight.serialized,
      ),
      new CellSerializer(
        defaultColumns.fatteningFeedConversion.unserialized,
        defaultColumns.fatteningFeedConversion.serialized,
      ),
      new CellSerializer(defaultColumns.cullingPercentage.unserialized, defaultColumns.cullingPercentage.serialized),
      new CellSerializer(defaultColumns.growthPerDay.unserialized, defaultColumns.growthPerDay.serialized),
      new CellSerializer(defaultColumns.ewConversion.unserialized, defaultColumns.ewConversion.serialized),
      new CellSerializer(
        defaultColumns.nonRenewableGasPurchasedVolume.unserialized,
        defaultColumns.nonRenewableGasPurchasedVolume.serialized,
      ),
      new CellSerializer(
        defaultColumns.renewableGasPurchasedVolume.unserialized,
        defaultColumns.renewableGasPurchasedVolume.serialized,
      ),
      new CellSerializer(
        defaultColumns.nonRenewableElectricityPurchasedVolume.unserialized,
        defaultColumns.nonRenewableElectricityPurchasedVolume.serialized,
      ),
      new CellSerializer(
        defaultColumns.renewableElectricityPurchasedVolume.unserialized,
        defaultColumns.renewableElectricityPurchasedVolume.serialized,
      ),
      new CellSerializer(defaultColumns.tapWaterUsedVolume.unserialized, defaultColumns.tapWaterUsedVolume.serialized),
      new CellSerializer(defaultColumns.waterFromOwnSource.unserialized, defaultColumns.waterFromOwnSource.serialized),
      new CellSerializer(defaultColumns.sharedSmartMeter.unserialized, defaultColumns.sharedSmartMeter.serialized),
      new CellSerializer(
        defaultColumns.quantityOfElectricityProduced.unserialized,
        defaultColumns.quantityOfElectricityProduced.serialized,
      ),
      new CellSerializer(
        defaultColumns.solarPanelsSquare.unserialized,
        defaultColumns.solarPanelsSquare.serialized,
      ),
      new CellSerializer(
        defaultColumns.waterMeterAvailable.unserialized,
        defaultColumns.waterMeterAvailable.serialized,
      ),
      new CellSerializer(defaultColumns.gasMeterAvailable.unserialized, defaultColumns.gasMeterAvailable.serialized),
      new CellSerializer(
        defaultColumns.electricityMeterAvailable.unserialized,
        defaultColumns.electricityMeterAvailable.serialized,
      ),
      new CellSerializer(defaultColumns.productCount.unserialized, defaultColumns.productCount.serialized),
      new CellSerializer(defaultColumns.fermentationCount.unserialized, defaultColumns.fermentationCount.serialized),
      new CellSerializer(defaultColumns.manureCount.unserialized, defaultColumns.manureCount.serialized),
      new CellSerializer(defaultColumns.manureFile.unserialized, defaultColumns.manureFile.serialized),
      new CellSerializer(defaultColumns.manureFileName.unserialized, defaultColumns.manureFileName.serialized),
      new CellSerializer(defaultColumns.updatedAt.unserialized, defaultColumns.updatedAt.serialized),
      new CellSerializer(defaultColumns.updatedBy.unserialized, defaultColumns.updatedBy.serialized),
    ]),
  ]);
};

const getSubmitUnserializer = (data: DocumentData<CompanyReportSubmitSerialized[]>) => {
  return new DocumentSerializer<CompanyReportSubmitPayload, CompanyReportSubmitSerialized>(data, [
    new SheetSerializer(
      COMPANY_REPORTS_SUBMIT.CompanyReportsSubmit.serialized,
      COMPANY_REPORTS_SUBMIT.CompanyReportsSubmit.unserialized,
      [
        new CellSerializer(submitColumns.status.unserialized, submitColumns.status.serialized),
        new CellSerializer(submitColumns.idType.unserialized, submitColumns.idType.serialized),
        new CellSerializer(submitColumns.byProductsOrLoose.unserialized, submitColumns.byProductsOrLoose.serialized),
        new CellSerializer(submitColumns.feedFermentation.unserialized, submitColumns.feedFermentation.serialized),
        new CellSerializer(
          submitColumns.feedSuppliersPiglets.unserialized,
          submitColumns.feedSuppliersPiglets.serialized,
        ),
        new CellSerializer(
          submitColumns.feedSuppliersSows.unserialized,
          submitColumns.feedSuppliersSows.serialized,
        ),
        new CellSerializer(
          submitColumns.feedSuppliersFatteningPigs.unserialized,
          submitColumns.feedSuppliersFatteningPigs.serialized,
        ),
        new CellSerializer(
          submitColumns.otherManureStorageTypes.unserialized,
          submitColumns.otherManureStorageTypes.serialized,
        ),
        new CellSerializer(
          submitColumns.durationOfStorageInCurrentType.unserialized,
          submitColumns.durationOfStorageInCurrentType.serialized,
        ),
        new CellSerializer(
          submitColumns.monthlyManureRemoval.unserialized,
          submitColumns.monthlyManureRemoval.serialized,
        ),
        new CellSerializer(
          submitColumns.isManureBeingRemoved.unserialized,
          submitColumns.isManureBeingRemoved.serialized,
        ),
        new CellSerializer(
          submitColumns.manureRemovingFrequency.unserialized,
          submitColumns.manureRemovingFrequency.serialized,
        ),
        new CellSerializer(
          submitColumns.manureDigestion.unserialized,
          submitColumns.manureDigestion.serialized,
        ),
        new CellSerializer(submitColumns.partyIdTypeCode.unserialized, submitColumns.partyIdTypeCode.serialized),
        new CellSerializer(
          submitColumns.reportingPeriodDateStart.unserialized,
          submitColumns.reportingPeriodDateStart.serialized,
        ),
        new CellSerializer(
          submitColumns.reportingPeriodDateEnd.unserialized,
          submitColumns.reportingPeriodDateEnd.serialized,
        ),
        new CellSerializer(
          submitColumns.householdComposition.unserialized,
          submitColumns.householdComposition.serialized,
        ),
        new CellSerializer(submitColumns.companyType.unserialized, submitColumns.companyType.serialized),
        //////
        new CellSerializer(
          submitColumns.farmerRavCodesAttributes.unserialized,
          submitColumns.farmerRavCodesAttributes.serialized,
          (data, sheetData, excelRow, propertyOriginalPath) => {
            const property = (excelRow as CompanyReportSubmitSerialized)[
              propertyOriginalPath as CompanyReportsSubmitSerializedHeaders.farmerRavCodesAttributes
            ];

            return unserializeRavCodes(property);
          },
        ),
        //////
        new CellSerializer(
          submitColumns.avgSowCount.unserialized,
          submitColumns.avgSowCount.serialized,
          undefined,
          undefined,
          null,
        ),
        new CellSerializer(
          submitColumns.avgRearingSowCount.unserialized,
          submitColumns.avgRearingSowCount.serialized,
          undefined,
          undefined,
          null,
        ),
        new CellSerializer(
          submitColumns.avgPigletCount.unserialized,
          submitColumns.avgPigletCount.serialized,
          undefined,
          undefined,
          null,
        ),
        new CellSerializer(
          submitColumns.totalSoldSowsPrice.unserialized,
          submitColumns.totalSoldSowsPrice.serialized,
          undefined,
          undefined,
          null,
        ),
        new CellSerializer(
          submitColumns.totalSoldPigletsPrice.unserialized,
          submitColumns.totalSoldPigletsPrice.serialized,
          undefined,
          undefined,
          null,
        ),
        new CellSerializer(
          submitColumns.avgPigletCountPerYear.unserialized,
          submitColumns.avgPigletCountPerYear.serialized,
          undefined,
          undefined,
          null,
        ),
        new CellSerializer(
          submitColumns.breedingFeedConversion.unserialized,
          submitColumns.breedingFeedConversion.serialized,
          undefined,
          undefined,
          null,
        ),
        new CellSerializer(
          submitColumns.averageNumberSucklingPiglets.unserialized,
          submitColumns.averageNumberSucklingPiglets.serialized,
          undefined,
          undefined,
          null,
        ),
        new CellSerializer(
          submitColumns.avgFinishingPigCount.unserialized,
          submitColumns.avgFinishingPigCount.serialized,
          undefined,
          undefined,
          null,
        ),
        new CellSerializer(
          submitColumns.avgPurchasedPigletWeight.unserialized,
          submitColumns.avgPurchasedPigletWeight.serialized,
          undefined,
          undefined,
          null,
        ),
        new CellSerializer(
          submitColumns.fatteningFeedConversion.unserialized,
          submitColumns.fatteningFeedConversion.serialized,
          undefined,
          undefined,
          null,
        ),
        new CellSerializer(
          submitColumns.cullingPercentage.unserialized,
          submitColumns.cullingPercentage.serialized,
          undefined,
          undefined,
          null,
        ),
        new CellSerializer(
          submitColumns.growthPerDay.unserialized,
          submitColumns.growthPerDay.serialized,
          undefined,
          undefined,
          null,
        ),
        new CellSerializer(
          submitColumns.ewConversion.unserialized,
          submitColumns.ewConversion.serialized,
          undefined,
          undefined,
          null,
        ),
        new CellSerializer(
          submitColumns.nonRenewableGasPurchasedVolume.unserialized,
          submitColumns.nonRenewableGasPurchasedVolume.serialized,
          undefined,
          undefined,
          null,
        ),
        new CellSerializer(
          submitColumns.renewableGasPurchasedVolume.unserialized,
          submitColumns.renewableGasPurchasedVolume.serialized,
          undefined,
          undefined,
          null,
        ),
        new CellSerializer(
          submitColumns.nonRenewableElectricityPurchasedVolume.unserialized,
          submitColumns.nonRenewableElectricityPurchasedVolume.serialized,
          undefined,
          undefined,
          null,
        ),
        new CellSerializer(
          submitColumns.renewableElectricityPurchasedVolume.unserialized,
          submitColumns.renewableElectricityPurchasedVolume.serialized,
          undefined,
          undefined,
          null,
        ),
        new CellSerializer(
          submitColumns.tapWaterUsedVolume.unserialized,
          submitColumns.tapWaterUsedVolume.serialized,
          undefined,
          undefined,
          null,
        ),
        new CellSerializer(submitColumns.waterFromOwnSource.unserialized, submitColumns.waterFromOwnSource.serialized),
        new CellSerializer(submitColumns.sharedSmartMeter.unserialized, submitColumns.sharedSmartMeter.serialized),
        new CellSerializer(
          submitColumns.quantityOfElectricityProduced.unserialized,
          submitColumns.quantityOfElectricityProduced.serialized,
          undefined,
          undefined,
          null,
        ),
        new CellSerializer(
          submitColumns.solarPanelsSquare.unserialized,
          submitColumns.solarPanelsSquare.serialized,
          undefined,
          undefined,
          null,
        ),
        new CellSerializer(
          submitColumns.waterMeterAvailable.unserialized,
          submitColumns.waterMeterAvailable.serialized,
        ),
        new CellSerializer(submitColumns.gasMeterAvailable.unserialized, submitColumns.gasMeterAvailable.serialized),
        new CellSerializer(
          submitColumns.electricityMeterAvailable.unserialized,
          submitColumns.electricityMeterAvailable.serialized,
        ),
        new CellSerializer(
          submitColumns.manureFile.unserialized,
          submitColumns.manureFile.serialized
        ),
        new CellSerializer(
          submitColumns.manureFileName.unserialized,
          submitColumns.manureFileName.serialized,
        ),
      ],
    ),
  ]);
};

// Serialized into Serialized Submit
const getStoSSSerializer = (data: DocumentData<CompanyReportSerialized[]>) => {
  return new DocumentSerializer<CompanyReportSerialized, CompanyReportSubmitSerialized>(data, [
    new SheetSerializer(
      COMPANY_REPORTS_SUBMIT.CompanyReportsSubmit.serialized,
      COMPANY_REPORTS_SUBMIT.CompanyReportsSubmit.unserialized,
      [
        new CellSerializer(defaultColumns.status.serialized, submitColumns.status.serialized),
        new CellSerializer(defaultColumns.idType.serialized, submitColumns.idType.serialized),
        new CellSerializer(defaultColumns.byProductsOrLoose.serialized, submitColumns.byProductsOrLoose.serialized),
        new CellSerializer(defaultColumns.feedFermentation.serialized, submitColumns.feedFermentation.serialized),
        new CellSerializer(
          defaultColumns.feedSuppliersPiglets.serialized,
          submitColumns.feedSuppliersPiglets.serialized,
        ),
        new CellSerializer(
          defaultColumns.feedSuppliersSows.serialized,
          submitColumns.feedSuppliersSows.serialized,
        ),
        new CellSerializer(
          defaultColumns.feedSuppliersFatteningPigs.serialized,
          submitColumns.feedSuppliersFatteningPigs.serialized,
        ),
        new CellSerializer(
          defaultColumns.otherManureStorageTypes.serialized,
          submitColumns.otherManureStorageTypes.serialized,
        ),
        new CellSerializer(
          defaultColumns.durationOfStorageInCurrentType.serialized,
          submitColumns.durationOfStorageInCurrentType.serialized,
        ),
        new CellSerializer(
          defaultColumns.monthlyManureRemoval.serialized,
          submitColumns.monthlyManureRemoval.serialized,
        ),
        new CellSerializer(
          defaultColumns.isManureBeingRemoved.serialized,
          submitColumns.isManureBeingRemoved.serialized,
        ),
        new CellSerializer(
          defaultColumns.manureRemovingFrequency.serialized,
          submitColumns.manureRemovingFrequency.serialized,
        ),
        new CellSerializer(
          defaultColumns.manureDigestion.serialized,
          submitColumns.manureDigestion.serialized,
        ),
        new CellSerializer(defaultColumns.partyIdTypeCode.serialized, submitColumns.partyIdTypeCode.serialized),
        new CellSerializer(
          defaultColumns.reportingPeriodDateStart.serialized,
          submitColumns.reportingPeriodDateStart.serialized,
        ),
        new CellSerializer(
          defaultColumns.reportingPeriodDateEnd.serialized,
          submitColumns.reportingPeriodDateEnd.serialized,
        ),
        new CellSerializer(
          defaultColumns.householdComposition.serialized,
          submitColumns.householdComposition.serialized,
        ),
        new CellSerializer(defaultColumns.companyType.serialized, submitColumns.companyType.serialized),
        //////
        new CellSerializer(defaultColumns.farmerRavCodes.serialized, submitColumns.farmerRavCodesAttributes.serialized),
        //////
        new CellSerializer(
          defaultColumns.avgSowCount.serialized,
          submitColumns.avgSowCount.serialized,
          undefined,
          undefined,
          undefined,
        ),
        new CellSerializer(
          defaultColumns.avgRearingSowCount.serialized,
          submitColumns.avgRearingSowCount.serialized,
          undefined,
          undefined,
          undefined,
        ),
        new CellSerializer(
          defaultColumns.avgPigletCount.serialized,
          submitColumns.avgPigletCount.serialized,
          undefined,
          undefined,
          undefined,
        ),
        new CellSerializer(
          defaultColumns.totalSoldSowsPrice.serialized,
          submitColumns.totalSoldSowsPrice.serialized,
          undefined,
          undefined,
          undefined,
        ),
        new CellSerializer(
          defaultColumns.totalSoldPigletsPrice.serialized,
          submitColumns.totalSoldPigletsPrice.serialized,
          undefined,
          undefined,
          undefined,
        ),
        new CellSerializer(
          defaultColumns.avgPigletCountPerYear.serialized,
          submitColumns.avgPigletCountPerYear.serialized,
          undefined,
          undefined,
          undefined,
        ),
        new CellSerializer(
          defaultColumns.breedingFeedConversion.serialized,
          submitColumns.breedingFeedConversion.serialized,
          undefined,
          undefined,
          undefined,
        ),
        new CellSerializer(
          defaultColumns.averageNumberSucklingPiglets.serialized,
          submitColumns.averageNumberSucklingPiglets.serialized,
          undefined,
          undefined,
          undefined,
        ),
        new CellSerializer(
          defaultColumns.avgFinishingPigCount.serialized,
          submitColumns.avgFinishingPigCount.serialized,
          undefined,
          undefined,
          undefined,
        ),
        new CellSerializer(
          defaultColumns.avgPurchasedPigletWeight.serialized,
          submitColumns.avgPurchasedPigletWeight.serialized,
          undefined,
          undefined,
          undefined,
        ),
        new CellSerializer(
          defaultColumns.fatteningFeedConversion.serialized,
          submitColumns.fatteningFeedConversion.serialized,
          undefined,
          undefined,
          undefined,
        ),
        new CellSerializer(
          defaultColumns.cullingPercentage.serialized,
          submitColumns.cullingPercentage.serialized,
          undefined,
          undefined,
          undefined,
        ),
        new CellSerializer(
          defaultColumns.growthPerDay.serialized,
          submitColumns.growthPerDay.serialized,
          undefined,
          undefined,
          undefined,
        ),
        new CellSerializer(
          defaultColumns.ewConversion.serialized,
          submitColumns.ewConversion.serialized,
          undefined,
          undefined,
          undefined,
        ),
        new CellSerializer(
          defaultColumns.nonRenewableGasPurchasedVolume.serialized,
          submitColumns.nonRenewableGasPurchasedVolume.serialized,
          undefined,
          undefined,
          undefined,
        ),
        new CellSerializer(
          defaultColumns.renewableGasPurchasedVolume.serialized,
          submitColumns.renewableGasPurchasedVolume.serialized,
          undefined,
          undefined,
          undefined,
        ),
        new CellSerializer(
          defaultColumns.nonRenewableElectricityPurchasedVolume.serialized,
          submitColumns.nonRenewableElectricityPurchasedVolume.serialized,
          undefined,
          undefined,
          undefined,
        ),
        new CellSerializer(
          defaultColumns.renewableElectricityPurchasedVolume.serialized,
          submitColumns.renewableElectricityPurchasedVolume.serialized,
          undefined,
          undefined,
          undefined,
        ),
        new CellSerializer(
          defaultColumns.tapWaterUsedVolume.serialized,
          submitColumns.tapWaterUsedVolume.serialized,
          undefined,
          undefined,
          undefined,
        ),
        new CellSerializer(defaultColumns.waterFromOwnSource.serialized, submitColumns.waterFromOwnSource.serialized),
        new CellSerializer(defaultColumns.sharedSmartMeter.serialized, submitColumns.sharedSmartMeter.serialized),
        new CellSerializer(
          defaultColumns.quantityOfElectricityProduced.serialized,
          submitColumns.quantityOfElectricityProduced.serialized,
          undefined,
          undefined,
          undefined,
        ),
        new CellSerializer(
          defaultColumns.solarPanelsSquare.serialized,
          submitColumns.solarPanelsSquare.serialized,
          undefined,
          undefined,
          undefined,
        ),
        new CellSerializer(defaultColumns.waterMeterAvailable.serialized, submitColumns.waterMeterAvailable.serialized),
        new CellSerializer(defaultColumns.gasMeterAvailable.serialized, submitColumns.gasMeterAvailable.serialized),
        new CellSerializer(
          defaultColumns.electricityMeterAvailable.serialized,
          submitColumns.electricityMeterAvailable.serialized,
        ),
        new CellSerializer(defaultColumns.manureFile.serialized, submitColumns.manureFile.serialized),
        new CellSerializer(defaultColumns.manureFileName.serialized, submitColumns.manureFileName.serialized),
      ],
    ),
  ]);
};

export const serializeCompanyReports = (serverData: ResourcesWrapper<CompanyReportPayload>) => {
  const { unserialized } = COMPANY_REPORTS.CompanyReports;
  const data = {
    [unserialized]: serverData.data.resources,
  };

  const serializer = getDefaultSerializer(data);

  return serializer.serialize();
};

export const serializeCompanyReport = (serverData: ResourceWrapper<CompanyReportPayload>) => {
  const { serialized, unserialized } = COMPANY_REPORTS.CompanyReports;
  const data = {
    [unserialized]: [serverData.data.resource],
  };

  const serializer = getDefaultSerializer(data);

  return serializer.serialize()[serialized][0];
};

export const unserializeCompanyReportForSubmit = (
  resource: CompanyReportSubmitSerialized,
): CompanyReportSubmitPayload => {
  const { serialized, unserialized } = COMPANY_REPORTS_SUBMIT.CompanyReportsSubmit;
  const data = {
    [unserialized]: [resource],
  };

  const serializer = getSubmitUnserializer(data);

  return serializer.unserialize()[serialized][0];
};

// Serialized into Serialized Submit
export const serializeCompanyReportStoSS = (resource: CompanyReportSerialized) => {
  const { serialized, unserialized } = COMPANY_REPORTS_SUBMIT.CompanyReportsSubmit;
  const data = {
    [unserialized]: [resource],
  };

  const serializer = getStoSSSerializer(data);

  return serializer.serialize()[serialized][0];
};
