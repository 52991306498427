import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer } from 'lib/excel/serilizers/Cell';
import { ResourcesWrapper } from 'lib/http/utils';

import { RAV_CODES } from './config';
import { ManageUploadPayload } from '../manageUploads';

export type RavCodesPayload = {
  id: number;
  animal_category: string;
  rav_code: string;
  animal_description_nl: string;
  animal_description_en: string;
  animal_enclosure_category: string;
  ammonia: number;
  ammonia_unit: string;
  rav_code_upload: ManageUploadPayload;
};

export type RavCodesSerialized = {
  id: number;
  referenceUploadID: number;
  animalCategory: string;
  ravCode: string;
  animalDescriptionNl: string;
  animalDescriptionEn: string;
  animalEnclosureCategory: string;
  ammonia: number;
  ammoniaUnit: string;
  uploadType: string;
  status: 'active' | 'inactive';
  filename: string;
  validDateFrom: string | null;
  validDateTo: string | null;
  notes: string;
  concepts: string[];
  uploadedAt: string;
  uploadedBy: string;
};

const { columns, serialized, unserialized } = RAV_CODES.RavCodes;

const getDefaultSerializer = (data: DocumentData<RavCodesPayload[]>) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(unserialized, serialized, [
      new CellSerializer(columns.id.unserialized, columns.id.serialized),
      new CellSerializer(columns.referenceUploadID.unserialized, columns.referenceUploadID.serialized),
      new CellSerializer(columns.animalCategory.unserialized, columns.animalCategory.serialized),
      new CellSerializer(columns.ravCode.unserialized, columns.ravCode.serialized),
      new CellSerializer(columns.animalDescriptionNl.unserialized, columns.animalDescriptionNl.serialized),
      new CellSerializer(columns.animalDescriptionEn.unserialized, columns.animalDescriptionEn.serialized),
      new CellSerializer(columns.animalEnclosureCategory.unserialized, columns.animalEnclosureCategory.serialized),
      new CellSerializer(columns.ammonia.unserialized, columns.ammonia.serialized),
      new CellSerializer(columns.ammoniaUnit.unserialized, columns.ammoniaUnit.serialized),
      new CellSerializer(columns.uploadType.unserialized, columns.uploadType.serialized),
      new CellSerializer(columns.status.unserialized, columns.status.serialized),
      new CellSerializer(columns.filename.unserialized, columns.filename.serialized),
      new CellSerializer(
        columns.validDateFrom.unserialized,
        columns.validDateFrom.serialized,
        undefined,
        undefined,
        null,
      ),
      new CellSerializer(columns.validDateTo.unserialized, columns.validDateTo.serialized, undefined, undefined, null),
      new CellSerializer(columns.notes.unserialized, columns.notes.serialized),
      new CellSerializer(columns.concepts.unserialized, columns.concepts.serialized),
      new CellSerializer(columns.uploadedAt.unserialized, columns.uploadedAt.serialized),
      new CellSerializer(columns.uploadedBy.unserialized, columns.uploadedBy.serialized),
    ]),
  ]);
};

export const serializeRavCodes = (serverData: ResourcesWrapper<RavCodesPayload>) => {
  const data = {
    [unserialized]: serverData.data.resources,
  };

  const serializer = getDefaultSerializer(data);

  return serializer.serialize();
};
