import { ResourcesWrapper, ResourceWrapper } from 'lib/http/utils';

import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { CellSerializer } from 'lib/excel/serilizers/Cell';

import { SERVICE_FIELDS } from 'lib/excel/config/service';
import { RAW_MATERIALS_ORIGIN } from 'lib/tables/config/rawMaterialsOrigin/config';

export interface RawMaterialsOriginReports {
  animal_category_code: string;
  sub_animal_category_code: string;
  article_number: string;
  article_type_number_code: string;
  article_description: string;
  net_quantity_delivered_product: number;
  quantity_unit: string;
  nutrient_ds: number;
  nutrient_re: number;
  breed: number;
  nutrient_vcre: number;
  nutrient_vcos: number;
}

export interface RawMaterialsOriginPayload {
  id: number;
  messageId: string;
  source: string;
  supplier_number: string;
  supplier_name: string;
  customer_number: string;
  customer_name: string;
  ubn: string;
  beginning_time: string;
  end_time: string;
  region_of_origin_code: string;
  feed_ingredient_reports: RawMaterialsOriginReports[];
  created_at: string;
}

const { columns, serialized, unserialized } = RAW_MATERIALS_ORIGIN.RawMaterialsOrigin;
const {
  messageId,
  source,
  createdAt,
  supplierNumber,
  supplierName,
  customerNumber,
  customerName,
  ubn,
  beginningTime,
  endTime,
  regionOfOriginCode,
  feedIngredientReports,
} = columns;

const getSerializer = (data: DocumentData) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(unserialized, serialized, [
      new CellSerializer(SERVICE_FIELDS.id.unserialized, SERVICE_FIELDS.id.serialized),
      new CellSerializer(messageId.unserialized, supplierNumber.serialized),
      new CellSerializer(source.unserialized, supplierNumber.serialized),
      new CellSerializer(supplierNumber.unserialized, supplierNumber.serialized),
      new CellSerializer(supplierName.unserialized, supplierName.serialized),
      new CellSerializer(customerNumber.unserialized, customerNumber.serialized),
      new CellSerializer(customerName.unserialized, customerName.serialized),
      new CellSerializer(ubn.unserialized, ubn.serialized),
      new CellSerializer(beginningTime.unserialized, beginningTime.serialized),
      new CellSerializer(endTime.unserialized, endTime.serialized),
      new CellSerializer(createdAt.unserialized, createdAt.serialized),
      new CellSerializer(feedIngredientReports.unserialized, feedIngredientReports.serialized),
      new CellSerializer(regionOfOriginCode.unserialized, regionOfOriginCode.serialized),
    ]),
  ]);
};

export const serializeRawMaterialsOrigins = (serverData: ResourcesWrapper<RawMaterialsOriginPayload>) => {
  const data = {
    [unserialized]: serverData.data.resources,
  };

  const serializer = getSerializer(data);

  return serializer.serialize();
};

export const serializeRawMaterialsOrigin = (serverData: ResourceWrapper<RawMaterialsOriginPayload>) => {
  const data = {
    [unserialized]: [serverData.data.resource],
  };

  const serializer = getSerializer(data);

  return serializer.serialize()[serialized][0];
};
