import { IntlKeys } from 'lib/localization/keys';

enum CfpCo2EmissionFactorSerialized {
  CfpCo2EmissionFactor = 'CfpCo2EmissionFactors',
}

enum CfpCo2EmissionFactorUnserialized {
  CfpCo2EmissionFactor = 'CfpCo2EmissionFactors',
}

export enum CfpCo2EmissionFactorSerializedHeaders {
  id = 'id',
  referenceUploadID = 'referenceUploadID',
  emissionSource = 'emissionSource',
  co2Volume = 'co2Volume',
  reference = 'reference',
  uploadType = 'uploadType',
  filename = 'filename',
  status = 'status',
  validDateFrom = 'validDateFrom',
  validDateTo = 'validDateTo',
  notes = 'notes',
  concepts = 'concepts',
  uploadedAt = 'uploadedAt',
  uploadedBy = 'uploadedBy',
}

export const CfpCo2EmissionFactorUnserializedHeaders = {
  id: 'id',
  referenceUploadID: ['co2_emission_factor_upload', 'id'],
  emissionSource: 'emission_source',
  co2Volume: 'co2_volume',
  reference: 'reference',
  uploadType: ['co2_emission_factor_upload', 'upload_type'],
  filename: ['co2_emission_factor_upload', 'file_name'],
  status: ['co2_emission_factor_upload', 'status'],
  validDateFrom: ['co2_emission_factor_upload', 'valid_from'],
  validDateTo: ['co2_emission_factor_upload', 'valid_to'],
  notes: ['co2_emission_factor_upload', 'notes'],
  concepts: ['co2_emission_factor_upload', 'concepts'],
  uploadedAt: ['co2_emission_factor_upload', 'uploaded_at'],
  uploadedBy: ['co2_emission_factor_upload', 'uploaded_by', 'full_name'],
} as const;

export const CFP_CO2_EMISSION_FACTOR = {
  [CfpCo2EmissionFactorSerialized.CfpCo2EmissionFactor]: {
    serialized: CfpCo2EmissionFactorSerialized.CfpCo2EmissionFactor,
    unserialized: CfpCo2EmissionFactorUnserialized.CfpCo2EmissionFactor,
    view: IntlKeys.referenceTablesCfpCo2EmissionFactors,

    columns: {
      [CfpCo2EmissionFactorSerializedHeaders.id]: {
        serialized: CfpCo2EmissionFactorSerializedHeaders.id,
        unserialized: CfpCo2EmissionFactorUnserializedHeaders.id,
      },
      [CfpCo2EmissionFactorSerializedHeaders.referenceUploadID]: {
        serialized: CfpCo2EmissionFactorSerializedHeaders.referenceUploadID,
        unserialized: CfpCo2EmissionFactorUnserializedHeaders.referenceUploadID,
      },
      [CfpCo2EmissionFactorSerializedHeaders.emissionSource]: {
        serialized: CfpCo2EmissionFactorSerializedHeaders.emissionSource,
        unserialized: CfpCo2EmissionFactorUnserializedHeaders.emissionSource,
        view: IntlKeys.referenceTablesCfpCo2EmissionFactorsSource,
      },
      [CfpCo2EmissionFactorSerializedHeaders.co2Volume]: {
        serialized: CfpCo2EmissionFactorSerializedHeaders.co2Volume,
        unserialized: CfpCo2EmissionFactorUnserializedHeaders.co2Volume,
        view: IntlKeys.referenceTablesCfpCo2EmissionFactorsVolume,
      },
      [CfpCo2EmissionFactorSerializedHeaders.reference]: {
        serialized: CfpCo2EmissionFactorSerializedHeaders.reference,
        unserialized: CfpCo2EmissionFactorUnserializedHeaders.reference,
        view: IntlKeys.referenceTablesCfpCo2EmissionFactorsReference,
      },
      [CfpCo2EmissionFactorSerializedHeaders.status]: {
        serialized: CfpCo2EmissionFactorSerializedHeaders.status,
        unserialized: CfpCo2EmissionFactorUnserializedHeaders.status,
      },
      [CfpCo2EmissionFactorSerializedHeaders.uploadType]: {
        serialized: CfpCo2EmissionFactorSerializedHeaders.uploadType,
        unserialized: CfpCo2EmissionFactorUnserializedHeaders.uploadType,
      },
      [CfpCo2EmissionFactorSerializedHeaders.notes]: {
        serialized: CfpCo2EmissionFactorSerializedHeaders.notes,
        unserialized: CfpCo2EmissionFactorUnserializedHeaders.notes,
      },
      [CfpCo2EmissionFactorSerializedHeaders.concepts]: {
        serialized: CfpCo2EmissionFactorSerializedHeaders.concepts,
        unserialized: CfpCo2EmissionFactorUnserializedHeaders.concepts,
        original: IntlKeys.concepts,
      },
      [CfpCo2EmissionFactorSerializedHeaders.filename]: {
        serialized: CfpCo2EmissionFactorSerializedHeaders.filename,
        unserialized: CfpCo2EmissionFactorUnserializedHeaders.filename,
      },
      [CfpCo2EmissionFactorSerializedHeaders.validDateFrom]: {
        serialized: CfpCo2EmissionFactorSerializedHeaders.validDateFrom,
        unserialized: CfpCo2EmissionFactorUnserializedHeaders.validDateFrom,
      },
      [CfpCo2EmissionFactorSerializedHeaders.validDateTo]: {
        serialized: CfpCo2EmissionFactorSerializedHeaders.validDateTo,
        unserialized: CfpCo2EmissionFactorUnserializedHeaders.validDateTo,
      },
      [CfpCo2EmissionFactorSerializedHeaders.uploadedAt]: {
        serialized: CfpCo2EmissionFactorSerializedHeaders.uploadedAt,
        unserialized: CfpCo2EmissionFactorUnserializedHeaders.uploadedAt,
      },
      [CfpCo2EmissionFactorSerializedHeaders.uploadedBy]: {
        serialized: CfpCo2EmissionFactorSerializedHeaders.uploadedBy,
        unserialized: CfpCo2EmissionFactorUnserializedHeaders.uploadedBy,
      },
    },
  },
};
