import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { CellSerializer } from 'lib/excel/serilizers/Cell';
import { SERVICE_FIELDS } from 'lib/excel/config/service';
import { ResourcesWrapper, ResourceWrapper } from 'lib/http/utils';
import { ADMIN_CONCEPTS } from 'lib/tables/config/admin/concepts/config';

export interface AdminConceptUnserialized {
  id: number;
  name: string;
  companies: string[];
  inUse: boolean;
}

export interface AdminConceptSerialized {
  id: number;
  name: string;
  companies: string[];
  inUse: boolean;
}

const getSerializer = (data: DocumentData<AdminConceptUnserialized[]>) => {
  const {
    name,
    companies,
    inUse
  } = ADMIN_CONCEPTS.AdminConcepts.columns;

  return new DocumentSerializer(data, [
    new SheetSerializer(ADMIN_CONCEPTS.AdminConcepts.unserialized, ADMIN_CONCEPTS.AdminConcepts.serialized, [
      new CellSerializer(SERVICE_FIELDS.id.unserialized, SERVICE_FIELDS.id.serialized),
      new CellSerializer(name.unserialized, name.serialized),
      new CellSerializer(companies.unserialized, companies.serialized),
      new CellSerializer(inUse.unserialized, inUse.serialized),
      new CellSerializer(SERVICE_FIELDS.createdAt.unserialized, SERVICE_FIELDS.createdAt.serialized),
      new CellSerializer(SERVICE_FIELDS.updatedAt.unserialized, SERVICE_FIELDS.updatedAt.serialized),
    ]),
  ]);
};

export const serializeAdminConcepts = (response: ResourcesWrapper<AdminConceptUnserialized>) => {
  const data = {
    [ADMIN_CONCEPTS.AdminConcepts.unserialized]: response.data.resources,
  };

  const serializer = getSerializer(data);

  return serializer.serialize();
};


export const serializeAdminConcept = (response: ResourceWrapper<AdminConceptUnserialized>) => {
  const data = {
    [ADMIN_CONCEPTS.AdminConcepts.unserialized]: [response.data.resource],
  };

  const serializer = getSerializer(data);

  return serializer.serialize()[ADMIN_CONCEPTS.AdminConcepts.serialized][0];
};
