import { IntlKeys } from 'lib/localization/keys';

enum ManageUploadsSerialized {
  ManageUploads = 'ManageUploads',
  ManageUploadsSubmit = 'ManageUploadsSubmit',
}

enum ManageUploadsUnserialized {
  ManageUploads = 'ManageUploads',
  ManageUploadsSubmit = 'ManageUploadsSubmit',
}

export enum ManageUploadsSerializedHeaders {
  id = 'id',
  status = 'status',
  uploadType = 'uploadType',
  filename = 'filename',
  validDateFrom = 'validDateFrom',
  validDateTo = 'validDateTo',
  notes = 'notes',
  uploadedAt = 'uploadedAt',
  uploadedBy = 'uploadedBy',
  concepts = 'concepts',
}

export const ManageUploadsUnserializedHeaders = {
  id: 'id',
  status: 'status',
  uploadType: 'upload_type',
  filename: 'file_name',
  validDateFrom: 'valid_from',
  validDateTo: 'valid_to',
  notes: 'notes',
  concepts: 'concepts',
  uploadedAt: 'uploaded_at',
  uploadedBy: ['uploaded_by', 'full_name'],
} as const;

export enum ManageUploadsSubmitSerializedHeaders {
  id = 'id',
  status = 'status',
  validDateFrom = 'validDateFrom',
  validDateTo = 'validDateTo',
  notes = 'notes',
  concepts = 'concepts',
}

export const ManageUploadsSubmitUnserializedHeaders = {
  id: 'id',
  status: 'status',
  validDateFrom: 'valid_from',
  validDateTo: 'valid_to',
  notes: 'notes',
  concepts: 'concepts',
} as const;

export const MANAGE_UPLOADS = {
  [ManageUploadsSerialized.ManageUploads]: {
    serialized: ManageUploadsSerialized.ManageUploads,
    unserialized: ManageUploadsUnserialized.ManageUploads,
    view: IntlKeys.titleUploadsList,

    columns: {
      [ManageUploadsSerializedHeaders.id]: {
        serialized: ManageUploadsSerializedHeaders.id,
        unserialized: ManageUploadsUnserializedHeaders.id,
        view: IntlKeys.referenceTablesId,
      },
      [ManageUploadsSerializedHeaders.status]: {
        serialized: ManageUploadsSerializedHeaders.status,
        unserialized: ManageUploadsUnserializedHeaders.status,
        view: IntlKeys.referenceTablesStatus,
      },
      [ManageUploadsSerializedHeaders.uploadType]: {
        serialized: ManageUploadsSerializedHeaders.uploadType,
        unserialized: ManageUploadsUnserializedHeaders.uploadType,
        view: IntlKeys.referenceTablesCategory,
      },
      [ManageUploadsSerializedHeaders.filename]: {
        serialized: ManageUploadsSerializedHeaders.filename,
        unserialized: ManageUploadsUnserializedHeaders.filename,
        view: IntlKeys.referenceTablesFilename,
      },
      [ManageUploadsSerializedHeaders.validDateFrom]: {
        serialized: ManageUploadsSerializedHeaders.validDateFrom,
        unserialized: ManageUploadsUnserializedHeaders.validDateFrom,
        view: IntlKeys.referenceTablesValidDateFrom,
      },
      [ManageUploadsSerializedHeaders.validDateTo]: {
        serialized: ManageUploadsSerializedHeaders.validDateTo,
        unserialized: ManageUploadsUnserializedHeaders.validDateTo,
        view: IntlKeys.referenceTablesValidDateTo,
      },
      [ManageUploadsSerializedHeaders.notes]: {
        serialized: ManageUploadsSerializedHeaders.notes,
        unserialized: ManageUploadsUnserializedHeaders.notes,
        view: IntlKeys.referenceTablesNotes,
      },
      [ManageUploadsSerializedHeaders.uploadedAt]: {
        serialized: ManageUploadsSerializedHeaders.uploadedAt,
        unserialized: ManageUploadsUnserializedHeaders.uploadedAt,
      },
      [ManageUploadsSerializedHeaders.uploadedBy]: {
        serialized: ManageUploadsSerializedHeaders.uploadedBy,
        unserialized: ManageUploadsUnserializedHeaders.uploadedBy,
      },
      [ManageUploadsSerializedHeaders.concepts]: {
        serialized: ManageUploadsSerializedHeaders.concepts,
        unserialized: ManageUploadsUnserializedHeaders.concepts,
        view: IntlKeys.concepts,
      },
    },
  },
};

export const MANAGE_UPLOADS_SUBMIT = {
  [ManageUploadsSerialized.ManageUploadsSubmit]: {
    serialized: ManageUploadsSerialized.ManageUploadsSubmit,
    unserialized: ManageUploadsUnserialized.ManageUploadsSubmit,

    columns: {
      [ManageUploadsSubmitSerializedHeaders.id]: {
        serialized: ManageUploadsSubmitSerializedHeaders.id,
        unserialized: ManageUploadsSubmitUnserializedHeaders.id,
      },
      [ManageUploadsSubmitSerializedHeaders.status]: {
        serialized: ManageUploadsSubmitSerializedHeaders.status,
        unserialized: ManageUploadsSubmitUnserializedHeaders.status,
      },
      [ManageUploadsSubmitSerializedHeaders.validDateFrom]: {
        serialized: ManageUploadsSubmitSerializedHeaders.validDateFrom,
        unserialized: ManageUploadsSubmitUnserializedHeaders.validDateFrom,
      },
      [ManageUploadsSubmitSerializedHeaders.validDateTo]: {
        serialized: ManageUploadsSubmitSerializedHeaders.validDateTo,
        unserialized: ManageUploadsSubmitUnserializedHeaders.validDateTo,
      },
      [ManageUploadsSubmitSerializedHeaders.notes]: {
        serialized: ManageUploadsSubmitSerializedHeaders.notes,
        unserialized: ManageUploadsSubmitUnserializedHeaders.notes,
      },
      [ManageUploadsSubmitSerializedHeaders.concepts]: {
        serialized: ManageUploadsSubmitSerializedHeaders.concepts,
        unserialized: ManageUploadsSubmitUnserializedHeaders.concepts,
      },
    },
  },
};
