import { Validator } from '../index';
import { ValidationError } from './errors';
import { isNumeric, isInteger } from '../../helpers/renderHelpers';

const customValidationStub = (value: unknown, fieldName: string, sheetName: string, index: number) =>
  undefined as ValidationError | void;

export const isRequired = (value: unknown, fieldName: string, sheetName: string, index: number) => {
  if (!value) {
    return new ValidationError(`Row ${index+1}: ${fieldName} is required`);
  }
}

export const isInt = (value: unknown, fieldName: string, sheetName: string, index: number) => {
  if (!value || !isInteger(value)) {
    return new ValidationError(`Row ${index+1}: ${fieldName} should be number`);
  }
}

export const isOptionalNumber = (value: unknown, fieldName: string, sheetName: string, index: number) => {
  if (value && !isNumeric(value)) {
    return new ValidationError(`Row ${index+1}: ${fieldName} should be number`);
  }
}

export class CellValidator implements Validator {
  errors: ValidationError[] = [];

  constructor(readonly propertyName: string, private readonly customValidation = customValidationStub) {}

  private verifyPropertyExist = (data: object, sheetName: string) => {
    if (!data.hasOwnProperty(this.propertyName)) {
      const error = new ValidationError(`Property ${this.propertyName} is not found in sheet ${sheetName}`);

      this.errors.push(error);
    }
  };

  validate = (data: Record<string, unknown>, sheetName: string, index: number) => {
    this.verifyPropertyExist(data, sheetName);

    const error = this.customValidation(data[this.propertyName], this.propertyName, sheetName, index);
    if (error) {
      this.errors.push(error);
    }

    return {
      errors: this.errors,
      warnings: [],
      infos: [],
    };
  };
}
