import { IntlKeys } from 'lib/localization/keys';

enum CountryCodesSerialized {
  CountryCodes = 'CountryCodes',
}

enum CountryCodesUnserialized {
  CountryCodes = 'CountryCodes',
}

export enum CountryCodesSerializedHeaders {
  id = 'id',
  referenceUploadID = 'referenceUploadID',
  iso31661Code = 'iso31661Code',
  subdivisionName = 'subdivisionName',
  iso31662Code = 'iso31662Code',
  countryName = 'countryName',
  uploadType = 'uploadType',
  filename = 'filename',
  status = 'status',
  validDateFrom = 'validDateFrom',
  validDateTo = 'validDateTo',
  notes = 'notes',
  concepts = 'concepts',
  uploadedAt = 'uploadedAt',
  uploadedBy = 'uploadedBy',
}

export const CountryCodesUnserializedHeaders = {
  id: 'id',
  referenceUploadID: ['country_code_upload', 'id'],
  iso31661Code: 'iso_3166_1_code',
  subdivisionName: 'subdivision_name',
  iso31662Code: 'iso_3166_2_code',
  countryName: 'country_name',
  uploadType: ['country_code_upload', 'upload_type'],
  filename: ['country_code_upload', 'file_name'],
  status: ['country_code_upload', 'status'],
  validDateFrom: ['country_code_upload', 'valid_from'],
  validDateTo: ['country_code_upload', 'valid_to'],
  notes: ['country_code_upload', 'notes'],
  concepts: ['country_code_upload', 'concepts'],
  uploadedAt: ['country_code_upload', 'uploaded_at'],
  uploadedBy: ['country_code_upload', 'uploaded_by', 'full_name'],
} as const;

export const COUNTRY_CODES = {
  [CountryCodesSerialized.CountryCodes]: {
    serialized: CountryCodesSerialized.CountryCodes,
    unserialized: CountryCodesUnserialized.CountryCodes,
    view: IntlKeys.referenceTablesCountryCodes,

    columns: {
      [CountryCodesSerializedHeaders.id]: {
        serialized: CountryCodesSerializedHeaders.id,
        unserialized: CountryCodesUnserializedHeaders.id,
      },
      [CountryCodesSerializedHeaders.referenceUploadID]: {
        serialized: CountryCodesSerializedHeaders.referenceUploadID,
        unserialized: CountryCodesUnserializedHeaders.referenceUploadID,
      },
      [CountryCodesSerializedHeaders.iso31661Code]: {
        serialized: CountryCodesSerializedHeaders.iso31661Code,
        unserialized: CountryCodesUnserializedHeaders.iso31661Code,
        view: IntlKeys.referenceTablesCountryCodesIso31661Code,
      },
      [CountryCodesSerializedHeaders.subdivisionName]: {
        serialized: CountryCodesSerializedHeaders.subdivisionName,
        unserialized: CountryCodesUnserializedHeaders.subdivisionName,
        view: IntlKeys.referenceTablesCountryCodesSubdivisionName,
      },
      [CountryCodesSerializedHeaders.iso31662Code]: {
        serialized: CountryCodesSerializedHeaders.iso31662Code,
        unserialized: CountryCodesUnserializedHeaders.iso31662Code,
        view: IntlKeys.referenceTablesCountryCodesIso31662Code,
      },
      [CountryCodesSerializedHeaders.countryName]: {
        serialized: CountryCodesSerializedHeaders.countryName,
        unserialized: CountryCodesUnserializedHeaders.countryName,
        view: IntlKeys.referenceTablesCountryCodesCountryName,
      },
      [CountryCodesSerializedHeaders.status]: {
        serialized: CountryCodesSerializedHeaders.status,
        unserialized: CountryCodesUnserializedHeaders.status,
      },
      [CountryCodesSerializedHeaders.uploadType]: {
        serialized: CountryCodesSerializedHeaders.uploadType,
        unserialized: CountryCodesUnserializedHeaders.uploadType,
      },
      [CountryCodesSerializedHeaders.notes]: {
        serialized: CountryCodesSerializedHeaders.notes,
        unserialized: CountryCodesUnserializedHeaders.notes,
      },
      [CountryCodesSerializedHeaders.concepts]: {
        serialized: CountryCodesSerializedHeaders.concepts,
        unserialized: CountryCodesUnserializedHeaders.concepts,
        view: IntlKeys.concepts,
      },
      [CountryCodesSerializedHeaders.filename]: {
        serialized: CountryCodesSerializedHeaders.filename,
        unserialized: CountryCodesUnserializedHeaders.filename,
      },
      [CountryCodesSerializedHeaders.validDateFrom]: {
        serialized: CountryCodesSerializedHeaders.validDateFrom,
        unserialized: CountryCodesUnserializedHeaders.validDateFrom,
      },
      [CountryCodesSerializedHeaders.validDateTo]: {
        serialized: CountryCodesSerializedHeaders.validDateTo,
        unserialized: CountryCodesUnserializedHeaders.validDateTo,
      },
      [CountryCodesSerializedHeaders.uploadedAt]: {
        serialized: CountryCodesSerializedHeaders.uploadedAt,
        unserialized: CountryCodesUnserializedHeaders.uploadedAt,
      },
      [CountryCodesSerializedHeaders.uploadedBy]: {
        serialized: CountryCodesSerializedHeaders.uploadedBy,
        unserialized: CountryCodesUnserializedHeaders.uploadedBy,
      },
    },
  },
};
