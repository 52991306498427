import { createReducer } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

import { UserPassportsUnserializedHeaders } from 'lib/tables/config/userPassports/config';
import { asyncAction, syncAction } from 'lib/actions';

import { RootState } from 'store/store';
import { UserPassportSerialized } from 'store/entities/userPassports';
import { OrgPassportOptionsSerializedEntry } from 'store/entities/admin/orgPassportOptions';
import { UbnRelatedImpersonalUsersSerialized } from 'store/entities/admin/organization/impersonalUbnData';

import { PermissionProfileResponseEntry, PermissionRulesListResponseEntry } from './entities';

const staticInitialState = {
  meta: {
    isLoading: false,
    ubnRelatedDataIsLoading: false,
    isSubmitting: false,
    isError: false,
  },

  entityList: [] as PermissionRulesListResponseEntry[],
  permissionProfileTemplateList: [] as PermissionProfileResponseEntry[],

  ubnData: [] as UserPassportSerialized[],
  impersonalUbnUsers: [] as UbnRelatedImpersonalUsersSerialized[],

  existingUserTypes: [] as string[],
  orgPassportOptions: {} as OrgPassportOptionsSerializedEntry,
  forms: {
    isAliasesFormDirty: false,
    isFMSFormDirty: false,
  },
};

export const createOrganizationAction = asyncAction('admin/createOrganizationAction');
export const updateOrganizationAction = asyncAction('admin/updateOrganizationAction');
export const updateOrganizationByUserAction = asyncAction('admin/updateOrganizationByUserAction');
export const deleteOrgAction = asyncAction('admin/deleteOrgAction');
export const deleteTemplateConfigAction = asyncAction('admin/deleteTemplateConfigAction');
export const cloneTemplateConfigAction = asyncAction('admin/cloneTemplateConfigAction');
export const createNewConceptAction = asyncAction('admin/CREATE_CONCEPT');
export const updateAdminConceptAction = asyncAction('admin/UPDATE_CONCEPT');
export const deleteConceptAction = asyncAction('admin/DELETE_CONCEPT');
export const inviteNewUserAction = asyncAction('admin/createNewUser');
export const changeUserStatusAction = asyncAction('admin/CHANGE_USER_STATUS');
export const updateAdminUserAction = asyncAction('admin/UPDATE_USER');
export const deleteUserAction = asyncAction('admin/DELETE_USER');
export const createUserPassportAction = asyncAction('admin/CREATE_USER_PASSPORT');
export const adminUpdateUserPassportAction = asyncAction('admin/UPDATE_USER_PASSPORT');
export const userUpdateUserPassportAction = asyncAction('user/UPDATE_USER_PASSPORT');
export const userUpdateCustomUbnNameAction = asyncAction<{
  resource: { [UserPassportsUnserializedHeaders.customUbnName]: string };
  params: { id: number | string };
}>('user/updateUserPassport');
export const userCreateCompanyReport = asyncAction('user/createCompanyReport');
export const userUpdateCompanyReport = asyncAction('user/updateCompanyReport');
export const userCloneCompanyReport = asyncAction('user/cloneCompanyReport');
export const userDeleteCompanyReport = asyncAction('user/deleteCompanyReport');
export const userCreateByProductReport = asyncAction('user/createByProductReport');
export const userUpdateByProductReport = asyncAction('user/updateByProductReport');
export const userCloneByProductReport = asyncAction('user/cloneByProductReport');
export const userDeleteByProductReport = asyncAction('user/deleteByProductReport');
export const userCreateFermentationReport = asyncAction('user/createFermentationReport');
export const userUpdateFermentationReport = asyncAction('user/updateFermentationReport');
export const userCloneFermentationReport = asyncAction('user/cloneFermentationReport');
export const userDeleteFermentationReport = asyncAction('user/deleteFermentationReport');
export const userCreateManureReport = asyncAction('user/createManurenReport');
export const userUpdateManureReport = asyncAction('user/updateManureReport');
export const userCloneManureReport = asyncAction('user/cloneManureReport');
export const userDeleteManureReport = asyncAction('user/cloneDeleteReport');
export const deleteUserPassportAction = asyncAction('admin/DELETE_USER_PASSPORT');
export const acceptNewUserInviteAction = asyncAction('admin/acceptNewUserInvite');
export const uploadAdminOrgPassportUsersAction = asyncAction('admin/uploadAdminOrgPassportUsers');
export const uploadAdminOrganizationAction = asyncAction('admin/uploadOrganization');
export const updateOrganizationPermissionProfileAction = asyncAction('admin/updateOrganizationPermissionProfile');
export const uploadAdminUsersAction = asyncAction('admin/uploadUsers');
export const uploadUserPassportsAction = asyncAction('admin/uploadUserPassports');
export const getEntityListAction = asyncAction('admin/getEntityList');
export const getPermissionProfileTemplateAction = asyncAction('admin/getPermissionProfileTemplate');
export const getPermissionProfileTemplatesAction = asyncAction('admin/getPermissionProfileTemplates');
export const createPermissionProfileTemplateAction = asyncAction('admin/createPermissionProfileTemplate');
export const updatePermissionProfileTemplateAction = asyncAction('admin/updatePermissionProfileTemplate');
export const deletePermissionProfileTemplateAction = asyncAction('admin/deletePermissionProfileTemplate');
export const getUbnRelatedImpersonalUsersAction = asyncAction('admin/getUbnRelatedImpersonalUsers');
export const adminGetUbnRelatedUserPassportsAction = asyncAction('admin/adminGetUbnRelatedUserPassports');
export const getExistingUserTypesAction = asyncAction('admin/getExistingUserTypesAction');
export const reinviteUserAction = asyncAction('admin/reinviteUser');
export const getOrgPassportOptionsAction = asyncAction('admin/getOrgPassportOptionsAction');
export const getDefaultOrgPassportOptionsAction = asyncAction('admin/getDefaultOrgPassportOptionsAction');
export const getLastChangesAction = asyncAction('admin/getLastChanges');
export const updateManageUploadAction = asyncAction('admin/updateManageUpload');
export const downloadManageUploadAction = asyncAction('admin/downloadManageUpload');
export const deleteManageUploadAction = asyncAction('admin/deleteManageUpload');
export const downloadFarmManagementFtpUpdatesAction = asyncAction('admin/downloadFarmManagementFtpUpdates');
export const deleteFarmManagementFtpUpdatesAction = asyncAction('admin/deleteFarmManagementFtpUpdates');
export const createFarmManagementPassportAction = asyncAction('admin/createFarmManagementPassport');
export const updateFarmManagementPassportAction = asyncAction('admin/updateFarmManagementPassport');
export const deleteFarmManagementPassportAction = asyncAction('admin/deleteFarmManagementPassport');
export const createAliasAction = asyncAction('admin/createAlias');
export const updateAliasAction = asyncAction('admin/updateAlias');
export const deleteAliasAction = asyncAction('admin/deleteAlias');
export const deleteCarbonFootprintAction = asyncAction('admin/deleteCarbonFootprint');
export const updateCarbonFootprintAction = asyncAction('admin/updateCarbonFootprint');
export const deleteCarbonFootprint = asyncAction('admin/deleteCarbonFootprint');
export const uploadAliasesAction = asyncAction('admin/uploadAliasesAction');
export const createLabelAction = asyncAction('admin/createLabel');
export const userCreateLabelAction = asyncAction('user/createLabel');
export const updateLabelAction = asyncAction('admin/updateLabel');
export const userUpdateLabelAction = asyncAction('user/updateLabel');
export const deleteLabelAction = asyncAction('admin/deleteLabel');
export const userDeleteLabelAction = asyncAction('user/deleteLabel');
export const setIsAliasesFormDirty = syncAction('admin/setIsAliasesFormDirty');
export const setIsFMSFormDirty = syncAction('admin/setIsFMSFormDirty');
export const deleteHFMOutputAction = asyncAction('admin/deleteHFMOutputAction');
export const updateHFMOutputAction = asyncAction('admin/updateHFMOutputAction');
export const fetchPermissionTemplates = asyncAction('admin/fetchPermissionTemplates');

const adminReducer = createReducer(staticInitialState, {
  ////////////////////////////////////////////////////////////////////////////////////
  [getEntityListAction.request]: (draft, { payload }) => {
    draft.meta.isLoading = true;
  },
  [getEntityListAction.success]: (draft, { payload }) => {
    draft.meta.isLoading = false;
    draft.entityList = payload;
  },
  [getEntityListAction.failure]: (draft, { payload }) => {
    draft.meta.isLoading = false;
    draft.meta.isError = true;
  },
  ////////////////////////////////////////////////////////////////////////////////////
  [getPermissionProfileTemplatesAction.request]: (draft, { payload }) => {
    draft.meta.isLoading = true;
  },
  [getPermissionProfileTemplatesAction.success]: (draft, { payload }) => {
    draft.meta.isLoading = false;
    draft.permissionProfileTemplateList = payload;
  },
  [getPermissionProfileTemplatesAction.failure]: (draft, { payload }) => {
    draft.meta.isLoading = false;
    draft.meta.isError = true;
  },

  ////////////////////////////////////////////////////////////////////////////////////
  [getUbnRelatedImpersonalUsersAction.request]: (draft, { payload }) => {
    draft.meta.ubnRelatedDataIsLoading = true;
  },
  [getUbnRelatedImpersonalUsersAction.success]: (draft, { payload }) => {
    draft.meta.ubnRelatedDataIsLoading = false;
    draft.impersonalUbnUsers = payload;
  },

  [adminGetUbnRelatedUserPassportsAction.request]: (draft, { payload }) => {
    draft.meta.ubnRelatedDataIsLoading = true;
  },
  [adminGetUbnRelatedUserPassportsAction.success]: (draft, { payload }) => {
    draft.meta.ubnRelatedDataIsLoading = false;
    draft.ubnData = payload;
  },

  ////////////////////////////////////////////////////////////////////////////////////

  [getExistingUserTypesAction.success]: (draft, { payload }) => {
    draft.existingUserTypes = payload;
  },

  ////////////////////////////////////////////////////////////////////////////////////

  [getOrgPassportOptionsAction.success]: (draft, { payload }) => {
    draft.orgPassportOptions = payload;
  },

  [setIsAliasesFormDirty.type]: (draft, { payload }) => {
    const { isAliasesFormDirty } = payload;
    draft.forms.isAliasesFormDirty = isAliasesFormDirty;
  },

  [setIsFMSFormDirty.type]: (draft, { payload }) => {
    const { isFMSFormDirty } = payload;
    draft.forms.isFMSFormDirty = isFMSFormDirty;
  },
});

const adminSelector = (state: RootState) => state.admin;
export const adminMetaSelector = createSelector(adminSelector, (admin) => admin.meta);
export const adminEntityListSelector = createSelector(adminSelector, (admin) => admin.entityList);
export const adminPermissionProfileTemplatesDataSelector = createSelector(
  adminSelector,
  (admin) => admin.permissionProfileTemplateList,
);
export const adminUbnDataSelector = createSelector(adminSelector, (admin) => admin.ubnData);
export const userImpersonalUbnDataSelector = createSelector(adminSelector, (admin) => admin.impersonalUbnUsers);
export const adminUserTypesSelector = createSelector(adminSelector, (admin) => admin.existingUserTypes);
export const orgPassportOptionsSelector = createSelector(adminSelector, (admin) => admin.orgPassportOptions);
export const isAliasFormDirty = createSelector(adminSelector, (admin) => admin.forms.isAliasesFormDirty);
export const isFMSFormDirty = createSelector(adminSelector, (admin) => admin.forms.isFMSFormDirty);

export default adminReducer;
