import { IntlKeys } from 'lib/localization/keys';

enum IpccEmissionFactorsSerialized {
  IpccEmissionFactors = 'IpccEmissionFactors',
}

enum IpccEmissionFactorsUnserialized {
  IpccEmissionFactors = 'IpccEmissionFactors',
}

export enum IpccEmissionFactorsSerializedHeaders {
  id = 'id',
  referenceUploadID = 'referenceUploadID',
  factor = 'factor',
  description = 'description',
  defaultValue = 'defaultValue',
  uncertaintyRange = 'uncertaintyRange',
  uploadType = 'uploadType',
  filename = 'filename',
  status = 'status',
  validDateFrom = 'validDateFrom',
  validDateTo = 'validDateTo',
  notes = 'notes',
  concepts = 'concepts',
  uploadedAt = 'uploadedAt',
  uploadedBy = 'uploadedBy',
}

export const IpccEmissionFactorsUnserializedHeaders = {
  id: 'id',
  referenceUploadID: ['ipcc_emission_factor_upload', 'id'],
  factor: 'factor',
  description: 'description',
  defaultValue: 'default_value',
  uncertaintyRange: 'uncertainty_range',
  uploadType: ['ipcc_emission_factor_upload', 'upload_type'],
  filename: ['ipcc_emission_factor_upload', 'file_name'],
  status: ['ipcc_emission_factor_upload', 'status'],
  validDateFrom: ['ipcc_emission_factor_upload', 'valid_from'],
  validDateTo: ['ipcc_emission_factor_upload', 'valid_to'],
  notes: ['ipcc_emission_factor_upload', 'notes'],
  concepts: ['ipcc_emission_factor_upload', 'concepts'],
  uploadedAt: ['ipcc_emission_factor_upload', 'uploaded_at'],
  uploadedBy: ['ipcc_emission_factor_upload', 'uploaded_by', 'full_name'],
} as const;

export const IPCC_EMISSION_FACTORS = {
  [IpccEmissionFactorsSerialized.IpccEmissionFactors]: {
    serialized: IpccEmissionFactorsSerialized.IpccEmissionFactors,
    unserialized: IpccEmissionFactorsUnserialized.IpccEmissionFactors,
    view: IntlKeys.referenceTablesIpccEmissionFactors,

    columns: {
      [IpccEmissionFactorsSerializedHeaders.id]: {
        serialized: IpccEmissionFactorsSerializedHeaders.id,
        unserialized: IpccEmissionFactorsUnserializedHeaders.id,
      },
      [IpccEmissionFactorsSerializedHeaders.referenceUploadID]: {
        serialized: IpccEmissionFactorsSerializedHeaders.referenceUploadID,
        unserialized: IpccEmissionFactorsUnserializedHeaders.referenceUploadID,
      },
      [IpccEmissionFactorsSerializedHeaders.factor]: {
        serialized: IpccEmissionFactorsSerializedHeaders.factor,
        unserialized: IpccEmissionFactorsUnserializedHeaders.factor,
        view: IntlKeys.referenceTablesFactor,
      },
      [IpccEmissionFactorsSerializedHeaders.description]: {
        serialized: IpccEmissionFactorsSerializedHeaders.description,
        unserialized: IpccEmissionFactorsUnserializedHeaders.description,
        view: IntlKeys.referenceTablesDescription,
      },
      [IpccEmissionFactorsSerializedHeaders.defaultValue]: {
        serialized: IpccEmissionFactorsSerializedHeaders.defaultValue,
        unserialized: IpccEmissionFactorsUnserializedHeaders.defaultValue,
        view: IntlKeys.referenceTablesDefaultValue,
      },
      [IpccEmissionFactorsSerializedHeaders.uncertaintyRange]: {
        serialized: IpccEmissionFactorsSerializedHeaders.uncertaintyRange,
        unserialized: IpccEmissionFactorsUnserializedHeaders.uncertaintyRange,
        view: IntlKeys.referenceTablesUncertaintyRange,
      },
      [IpccEmissionFactorsSerializedHeaders.status]: {
        serialized: IpccEmissionFactorsSerializedHeaders.status,
        unserialized: IpccEmissionFactorsUnserializedHeaders.status,
      },
      [IpccEmissionFactorsSerializedHeaders.uploadType]: {
        serialized: IpccEmissionFactorsSerializedHeaders.uploadType,
        unserialized: IpccEmissionFactorsUnserializedHeaders.uploadType,
      },
      [IpccEmissionFactorsSerializedHeaders.notes]: {
        serialized: IpccEmissionFactorsSerializedHeaders.notes,
        unserialized: IpccEmissionFactorsUnserializedHeaders.notes,
      },
      [IpccEmissionFactorsSerializedHeaders.concepts]: {
        serialized: IpccEmissionFactorsSerializedHeaders.concepts,
        unserialized: IpccEmissionFactorsUnserializedHeaders.concepts,
        view: IntlKeys.concepts,
      },
      [IpccEmissionFactorsSerializedHeaders.filename]: {
        serialized: IpccEmissionFactorsSerializedHeaders.filename,
        unserialized: IpccEmissionFactorsUnserializedHeaders.filename,
      },
      [IpccEmissionFactorsSerializedHeaders.validDateFrom]: {
        serialized: IpccEmissionFactorsSerializedHeaders.validDateFrom,
        unserialized: IpccEmissionFactorsUnserializedHeaders.validDateFrom,
      },
      [IpccEmissionFactorsSerializedHeaders.validDateTo]: {
        serialized: IpccEmissionFactorsSerializedHeaders.validDateTo,
        unserialized: IpccEmissionFactorsUnserializedHeaders.validDateTo,
      },
      [IpccEmissionFactorsSerializedHeaders.uploadedAt]: {
        serialized: IpccEmissionFactorsSerializedHeaders.uploadedAt,
        unserialized: IpccEmissionFactorsUnserializedHeaders.uploadedAt,
      },
      [IpccEmissionFactorsSerializedHeaders.uploadedBy]: {
        serialized: IpccEmissionFactorsSerializedHeaders.uploadedBy,
        unserialized: IpccEmissionFactorsUnserializedHeaders.uploadedBy,
      },
    },
  },
};
