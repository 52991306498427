import { getUrl } from '../routes';
import { ClientStorage } from './storage/Storage';
import { store } from '../store/store';
import { signOutAction } from '../store/auth/reducer';

export const unAuthHandler = (pushHistory: (url: string) => void) => {
  return (err) => {
    const { response } = err;
    if (response?.status === 401) {
      
      if ((response?.data as any).error_type === 'user_password_expired') {
        const updatePasswordUrl = getUrl('authUpdatePassword');
        if (window.location.pathname !== updatePasswordUrl) {
          pushHistory(updatePasswordUrl);
        }
      }
      if ((response?.data as any).error_type === 'user_session_expired') {
        ClientStorage.setItem('user_session_expired', 'true');
        store.dispatch(signOutAction())
      }
    }

    return Promise.reject(err);
  }
}