import { IntlKeys } from 'lib/localization/keys';

enum YesNoSerialized {
  YesNo = 'YesNo',
}

enum YesNoUnserialized {
  YesNo = 'YesNo',
}

export enum YesNoSerializedHeaders {
  id = 'id',
  referenceUploadID = 'referenceUploadID',
  yesNoCode = 'yesNoCode',
  yesNoName = 'yesNoName',
  uploadType = 'uploadType',
  filename = 'filename',
  status = 'status',
  validDateFrom = 'validDateFrom',
  validDateTo = 'validDateTo',
  notes = 'notes',
  concepts = 'concepts',
  uploadedAt = 'uploadedAt',
  uploadedBy = 'uploadedBy',
}

export const YesNoUnserializedHeaders = {
  id: 'id',
  referenceUploadID: ['answer_code_upload', 'id'],
  yesNoCode: 'answer_code',
  yesNoName: 'answer_name',
  uploadType: ['answer_code_upload', 'upload_type'],
  filename: ['answer_code_upload', 'file_name'],
  status: ['answer_code_upload', 'status'],
  validDateFrom: ['answer_code_upload', 'valid_from'],
  validDateTo: ['answer_code_upload', 'valid_to'],
  notes: ['answer_code_upload', 'notes'],
  concepts: ['answer_code_upload', 'concepts'],
  uploadedAt: ['answer_code_upload', 'uploaded_at'],
  uploadedBy: ['answer_code_upload', 'uploaded_by', 'full_name'],
} as const;

export const YES_NO = {
  [YesNoSerialized.YesNo]: {
    serialized: YesNoSerialized.YesNo,
    unserialized: YesNoUnserialized.YesNo,
    view: IntlKeys.referenceTablesAsYesNo,

    columns: {
      [YesNoSerializedHeaders.id]: {
        serialized: YesNoSerializedHeaders.id,
        unserialized: YesNoUnserializedHeaders.id,
      },
      [YesNoSerializedHeaders.referenceUploadID]: {
        serialized: YesNoSerializedHeaders.referenceUploadID,
        unserialized: YesNoUnserializedHeaders.referenceUploadID,
      },
      [YesNoSerializedHeaders.yesNoCode]: {
        serialized: YesNoSerializedHeaders.yesNoCode,
        unserialized: YesNoUnserializedHeaders.yesNoCode,
        view: IntlKeys.referenceTablesYesNoCode,
      },
      [YesNoSerializedHeaders.yesNoName]: {
        serialized: YesNoSerializedHeaders.yesNoName,
        unserialized: YesNoUnserializedHeaders.yesNoName,
        view: IntlKeys.referenceTablesYesNoName,
      },
      [YesNoSerializedHeaders.status]: {
        serialized: YesNoSerializedHeaders.status,
        unserialized: YesNoUnserializedHeaders.status,
      },
      [YesNoSerializedHeaders.uploadType]: {
        serialized: YesNoSerializedHeaders.uploadType,
        unserialized: YesNoUnserializedHeaders.uploadType,
      },
      [YesNoSerializedHeaders.notes]: {
        serialized: YesNoSerializedHeaders.notes,
        unserialized: YesNoUnserializedHeaders.notes,
      },
      [YesNoSerializedHeaders.concepts]: {
        serialized: YesNoSerializedHeaders.concepts,
        unserialized: YesNoUnserializedHeaders.concepts,
        view: IntlKeys.concepts,
      },
      [YesNoSerializedHeaders.filename]: {
        serialized: YesNoSerializedHeaders.filename,
        unserialized: YesNoUnserializedHeaders.filename,
      },
      [YesNoSerializedHeaders.validDateFrom]: {
        serialized: YesNoSerializedHeaders.validDateFrom,
        unserialized: YesNoUnserializedHeaders.validDateFrom,
      },
      [YesNoSerializedHeaders.validDateTo]: {
        serialized: YesNoSerializedHeaders.validDateTo,
        unserialized: YesNoUnserializedHeaders.validDateTo,
      },
      [YesNoSerializedHeaders.uploadedAt]: {
        serialized: YesNoSerializedHeaders.uploadedAt,
        unserialized: YesNoUnserializedHeaders.uploadedAt,
      },
      [YesNoSerializedHeaders.uploadedBy]: {
        serialized: YesNoSerializedHeaders.uploadedBy,
        unserialized: YesNoUnserializedHeaders.uploadedBy,
      },
    },
  },
};
