import { takeEvery, put, call, all, cancel, select } from 'redux-saga/effects';
import ensure from 'lib/ensure';
import { AxiosError, AxiosResponse } from 'axios';
import { serialize as objectToFormData } from 'object-to-formdata';

import {
  certificatesDailyConfig,
  certificatesWeeklyConfig,
  AdditionalFileData,
  uploadActionConfigs,
  uploadEntitySelector,
  uploadTokenEntitySelector,
  VRIDatabaseConfig,
  referenceTablesConfig,
  downloadFileAction,
  carbonFootprintConfig,
  hfmOutputConfig,
} from 'store/reducers/uploadReducer';
import { DocumentTypes, FileInfo, UploadEntities } from 'store/entities/Uploads';
import { CVB_TABLE } from 'store/entities/referenceTables/cvbTable/config';

import { ApiClient } from 'lib/http/ApiClient';
import { DocumentData } from 'lib/excel/serilizers/Sheet';
import { unserializeDailyData } from 'lib/excel/config/daily/unserialization';
import { unserializeWeeklyData } from 'lib/excel/config/weekly/unserialization';
import { CustomAction } from 'lib/actions';
import { unserializeVRIData } from 'lib/excel/config/vriDatabase/unserialization';
import { deserializeRow } from 'lib/excel/serilizers/utils';

import { REFERENCE_TABLES_TYPES } from 'pages/upload/components/ReferenceTablesUploadForm/utils';

const referenceTablesTransformRequest = (payload: { data: File; additionalFileData: AdditionalFileData }) => {
  return objectToFormData({
    resource: {
      valid_from: payload.additionalFileData.validDateFrom,
      valid_to: payload.additionalFileData.validDateTo,
      status: payload.additionalFileData.status,
      notes: payload.additionalFileData.notes,
      concepts: payload.additionalFileData.concepts,
      file: payload.data,
      rows: payload.additionalFileData.rows
    },
  });
};

export const ensureDocumentCarbonFootprintUpload = ensure({
  api: ApiClient.uploadCarbonFootprintDocument,
  action: carbonFootprintConfig.uploadDataAction,
  transformRequestData: (payload: { data: File; additionalFileData: AdditionalFileData }) => {
    return objectToFormData({
      resource: {
        valid_from: payload.additionalFileData.validDateFrom,
        valid_to: payload.additionalFileData.validDateTo,
        status: payload.additionalFileData.status,
        notes: payload.additionalFileData.notes,
        file: payload.data,
        rows: payload.additionalFileData.rows
      },
    });
  },
});

export const ensureDocumentHFMOutputUpload = ensure({
  api: ApiClient.uploadHFMOutputDocument,
  action: hfmOutputConfig.uploadDataAction,
  transformRequestData: (payload: { data: File; additionalFileData: AdditionalFileData }) =>
    objectToFormData({
      resource: {
        file: payload.data,
        rows: payload.additionalFileData.rows
      },
    }),
});

export const ensureDocumentDailyUpload = ensure({
  api: ApiClient.uploadDailyDocument,
  action: certificatesDailyConfig.uploadDataAction,
  transformRequestData: (payload: { data: DocumentData; backendEntityName: string; uploadToken: string }) => {
    const unserializer = unserializeDailyData(payload.data);
    const resources = unserializer.serialize();

    return {
      ...resources,
      upload_token: payload.uploadToken,
      // meta: {
      //   is_edited: false,
      // },
    };
  },
});

export const ensureDocumentWeeklyUpload = ensure({
  api: ApiClient.uploadWeeklyDocument,
  action: certificatesWeeklyConfig.uploadDataAction,
  transformRequestData: (payload: { data: DocumentData; backendEntityName: string; uploadToken: string }) => {
    const unserializer = unserializeWeeklyData(payload.data);
    const resources = unserializer.serialize();

    return {
      resources,
      upload_token: payload.uploadToken,
      // meta: {
      //   is_edited: false,
      // },
    };
  },
});
export const ensureDocumentVRIDatabaseUpload = ensure({
  api: ApiClient.uploadVRIDatabaseDocument,
  action: VRIDatabaseConfig.uploadDataAction,
  transformRequestData: (payload: {
    data: DocumentData;
    backendEntityName: string;
    uploadToken: string;
    additionalFileData: AdditionalFileData;
  }) => {
    const unserializer = unserializeVRIData(payload.data);
    const [reports] = Object.values(unserializer.serialize());

    return {
      resources: {
        report_date_from: payload.additionalFileData.from,
        report_date_to: payload.additionalFileData.to,
        rating: payload.additionalFileData.rating,
        reports,
      },
      upload_token: payload.uploadToken,
      // meta: {
      //   is_edited: false,
      // },
    };
  },
});

const ensureDownloadFile = ensure({
  api: ApiClient.downloadFile,
  action: downloadFileAction,
});

const getReferenceTablesEnsure = (uploadType: REFERENCE_TABLES_TYPES) => {
  switch (uploadType) {
    case REFERENCE_TABLES_TYPES.penLevel:
      return ensure({
        api: ApiClient.uploadPenLevelDocument,
        action: referenceTablesConfig.uploadDataAction,
        transformRequestData: referenceTablesTransformRequest,
      });

    case REFERENCE_TABLES_TYPES.pensTypeSowBarn:
      return ensure({
        api: ApiClient.uploadPensTypeSowBarnDocument,
        action: referenceTablesConfig.uploadDataAction,
        transformRequestData: referenceTablesTransformRequest,
      });

    case REFERENCE_TABLES_TYPES.yesNo:
      return ensure({
        api: ApiClient.uploadYesNoDocument,
        action: referenceTablesConfig.uploadDataAction,
        transformRequestData: referenceTablesTransformRequest,
      });

    case REFERENCE_TABLES_TYPES.cfpCo2EmissionFactor:
      return ensure({
        api: ApiClient.uploadCfpCo2EmissionFactorsDocument,
        action: referenceTablesConfig.uploadDataAction,
        transformRequestData: referenceTablesTransformRequest,
      });

    case REFERENCE_TABLES_TYPES.cvbTable:
      return ensure({
        api: ApiClient.uploadCvbTableDocument,
        action: referenceTablesConfig.uploadDataAction,
        transformRequestData: (payload: { data: File; additionalFileData: AdditionalFileData }) => {
          return objectToFormData({
            resource: {
              valid_from: payload.additionalFileData.validDateFrom,
              valid_to: payload.additionalFileData.validDateTo,
              status: payload.additionalFileData.status,
              notes: payload.additionalFileData.notes,
              concepts: payload.additionalFileData.concepts,
              file: payload.data,
            },
          });
        },
        responseConfigData: ({ additionalFileData }) => {
          const resources: any[] = additionalFileData.resources.map((entry: Record<string, any>) =>
            deserializeRow(CVB_TABLE.CvbTable.columns, entry),
          );
          const dataObject = {
            resources,
          };
          return { resConfigData: JSON.stringify(dataObject), saveConfigData: true };
        },
      });

    case REFERENCE_TABLES_TYPES.ipccEmissionFactors:
      return ensure({
        api: ApiClient.uploadIpccEmissionFactorsDocument,
        action: referenceTablesConfig.uploadDataAction,
        transformRequestData: referenceTablesTransformRequest,
      });

    case REFERENCE_TABLES_TYPES.cfpGfliFeedprintTable:
      return ensure({
        api: ApiClient.uploadcfpGfliFeedprintTableDocument,
        action: referenceTablesConfig.uploadDataAction,
        transformRequestData: referenceTablesTransformRequest,
      });

    case REFERENCE_TABLES_TYPES.contentOfNitricOxide:
      return ensure({
        api: ApiClient.uploadContentOfNitricOxideDocument,
        action: referenceTablesConfig.uploadDataAction,
        transformRequestData: referenceTablesTransformRequest,
      });

    case REFERENCE_TABLES_TYPES.pigHousingEmissionFactors:
      return ensure({
        api: ApiClient.uploadPigHousingEmissionFactorsDocument,
        action: referenceTablesConfig.uploadDataAction,
        transformRequestData: referenceTablesTransformRequest,
      });

    case REFERENCE_TABLES_TYPES.electricityConsumptionRates:
      return ensure({
        api: ApiClient.uploadElectricityConsumptionRates,
        action: referenceTablesConfig.uploadDataAction,
        transformRequestData: referenceTablesTransformRequest,
      });

    case REFERENCE_TABLES_TYPES.gasConsumptionRates:
      return ensure({
        api: ApiClient.uploadGasConsumptionRates,
        action: referenceTablesConfig.uploadDataAction,
        transformRequestData: referenceTablesTransformRequest,
      });

    case REFERENCE_TABLES_TYPES.waterConsumptionRates:
      return ensure({
        api: ApiClient.uploadWaterConsumptionRates,
        action: referenceTablesConfig.uploadDataAction,
        transformRequestData: referenceTablesTransformRequest,
      });

    case REFERENCE_TABLES_TYPES.ravCodes:
      return ensure({
        api: ApiClient.uploadRavCodes,
        action: referenceTablesConfig.uploadDataAction,
        transformRequestData: referenceTablesTransformRequest,
      });

    case REFERENCE_TABLES_TYPES.gwp100Factors:
      return ensure({
        api: ApiClient.uploadGwp100Factors,
        action: referenceTablesConfig.uploadDataAction,
        transformRequestData: referenceTablesTransformRequest,
      });

    case REFERENCE_TABLES_TYPES.wurMfcEfem:
      return ensure({
        api: ApiClient.uploadWurMfcEfem,
        action: referenceTablesConfig.uploadDataAction,
        transformRequestData: referenceTablesTransformRequest,
      });

    case REFERENCE_TABLES_TYPES.cl550:
      return ensure({
        api: ApiClient.uploadCl550,
        action: referenceTablesConfig.uploadDataAction,
        transformRequestData: referenceTablesTransformRequest,
      });

    case REFERENCE_TABLES_TYPES.cl649:
      return ensure({
        api: ApiClient.uploadCl649,
        action: referenceTablesConfig.uploadDataAction,
        transformRequestData: referenceTablesTransformRequest,
      });

    case REFERENCE_TABLES_TYPES.cl650:
      return ensure({
        api: ApiClient.uploadCl650,
        action: referenceTablesConfig.uploadDataAction,
        transformRequestData: referenceTablesTransformRequest,
      });

    case REFERENCE_TABLES_TYPES.cl607:
      return ensure({
        api: ApiClient.uploadCl607,
        action: referenceTablesConfig.uploadDataAction,
        transformRequestData: referenceTablesTransformRequest,
      });

    case REFERENCE_TABLES_TYPES.suppliersNumbers:
      return ensure({
        api: ApiClient.uploadSuppliersNumbers,
        action: referenceTablesConfig.uploadDataAction,
        transformRequestData: referenceTablesTransformRequest,
      });

    case REFERENCE_TABLES_TYPES.countryCodes:
      return ensure({
        api: ApiClient.uploadCountryCodes,
        action: referenceTablesConfig.uploadDataAction,
        transformRequestData: referenceTablesTransformRequest,
      });

    case REFERENCE_TABLES_TYPES.ravReferenceList:
      return ensure({
        api: ApiClient.uploadRavReferenceList,
        action: referenceTablesConfig.uploadDataAction,
        transformRequestData: referenceTablesTransformRequest,
      });

    case REFERENCE_TABLES_TYPES.allocationManure:
      return ensure({
        api: ApiClient.uploadAllocationManure,
        action: referenceTablesConfig.uploadDataAction,
        transformRequestData: referenceTablesTransformRequest,
      });
    case REFERENCE_TABLES_TYPES.greenhouseGasEmission:
      return ensure({
        api: ApiClient.uploadGreenhouseGasEmission,
        action: referenceTablesConfig.uploadDataAction,
        transformRequestData: referenceTablesTransformRequest,
      });
    case REFERENCE_TABLES_TYPES.entitiesList:
      return ensure({
        api: ApiClient.uploadEntitiesList,
        action: referenceTablesConfig.uploadDataAction,
        transformRequestData: referenceTablesTransformRequest,
      });
  }
};

function* verifyAndUploadFile(action: CustomAction) {
  const ensureUploadFile = ensure({
    api: ApiClient.uploadFile,
    action: action.actionCreator,
    transformRequestData: ({ file, backendEntityName }) => {
      return objectToFormData({
        resource: {
          file,
          upload_type: backendEntityName,
        },
      });
    },
    serializeSuccessPayload: (response: AxiosResponse) => ({
      isAlreadyExist: false,
      fileInfo: response.data.resource,
    }),
    serializeFailurePayload: (error: AxiosError) => error.response?.data,
  });

  yield call(ensureUploadFile, {
    ...action.payload,
    onFailure: function* (result) {
      const { requestPayload } = result;
      const payload = result.payload as { errors: string; old_file_upload: FileInfo };

      if (Reflect.has(payload, 'old_file_upload')) {
        const { entityName } = requestPayload as { entityName: UploadEntities };
        const entityConfig = uploadActionConfigs.find(({ entity }) => entity === entityName);

        if (process.env.NODE_ENV === 'development' && !entityConfig) {
          console.error(`No config for entity "${entityName} found"`);
          throw new Error();
        }

        yield put(entityConfig!.fileExistAction({ oldFile: payload.old_file_upload }));

        // prevent ensure autodispatch FAILURE
        yield cancel();
      }
    },
  });
}

function* reUploadFile(action: CustomAction) {
  const { entityName } = action.payload as { entityName: UploadEntities };
  const { fileInfo } = (yield select(uploadEntitySelector))(entityName);

  const ensureReUploadFile = ensure({
    api: ApiClient.reUploadFile,
    action: action.actionCreator,
    serializeSuccessPayload: (response: AxiosResponse) => ({
      fileInfo: response.data.resource,
    }),
  });

  yield call(ensureReUploadFile, {
    params: {
      fileId: fileInfo.id,
    },
  });
}

function* uploadDaily({ payload }: CustomAction) {
  const uploadToken = (yield select(uploadTokenEntitySelector))(DocumentTypes.certificatesDaily);
  yield call(ensureDocumentDailyUpload, { ...payload, uploadToken });
}

function* uploadWeekly({ payload }: CustomAction) {
  const uploadToken = (yield select(uploadTokenEntitySelector))(DocumentTypes.certificatesWeekly);
  yield call(ensureDocumentWeeklyUpload, { ...payload, uploadToken });
}

function* uploadVRIDatabase({ payload }: CustomAction) {
  const uploadToken = (yield select(uploadTokenEntitySelector))(DocumentTypes.VRIDatabase);
  yield call(ensureDocumentVRIDatabaseUpload, { ...payload, uploadToken });
}

function* uploadReferenceTables({ payload }: CustomAction) {
  const ensure = getReferenceTablesEnsure(payload.additionalFileData.uploadType);

  if (ensure) {
    yield call(ensure, payload);
  }
}

function* uploadCarbonFootprint({ payload }: CustomAction) {
  yield call(ensureDocumentCarbonFootprintUpload, payload);
}

function* uploadHFMOutput({ payload }: CustomAction) {
  yield call(ensureDocumentHFMOutputUpload, payload);
}

function* downloadFile({ payload }: CustomAction) {
  yield call(ensureDownloadFile, payload);
}

export default function* () {
  yield all([
    takeEvery(
      [
        certificatesDailyConfig.uploadFileAction.request,
        certificatesWeeklyConfig.uploadFileAction.request,
        VRIDatabaseConfig.uploadFileAction.request,
        // uploadCertificatesHistoryFile.request,
        carbonFootprintConfig.uploadFileAction.request,
        hfmOutputConfig.uploadFileAction.request,
      ],
      verifyAndUploadFile,
    ),

    takeEvery(
      [
        certificatesDailyConfig.reUploadFileAction.request,
        certificatesWeeklyConfig.reUploadFileAction.request,
        VRIDatabaseConfig.reUploadFileAction.request,
        // uploadCertificatesHistoryFile.request,
        carbonFootprintConfig.reUploadFileAction.request,
        hfmOutputConfig.reUploadFileAction.request,
      ],
      reUploadFile,
    ),

    takeEvery([certificatesDailyConfig.uploadDataAction.request], uploadDaily),
    takeEvery([certificatesWeeklyConfig.uploadDataAction.request], uploadWeekly),
    takeEvery([VRIDatabaseConfig.uploadDataAction.request], uploadVRIDatabase),
    takeEvery([referenceTablesConfig.uploadDataAction.request], uploadReferenceTables),
    takeEvery([carbonFootprintConfig.uploadDataAction.request], uploadCarbonFootprint),
    takeEvery([hfmOutputConfig.uploadDataAction.request], uploadHFMOutput),
    takeEvery(downloadFileAction.request, downloadFile),
  ]);
}
