import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer } from 'lib/excel/serilizers/Cell';
import { ResourcesWrapper } from 'lib/http/utils';

import { CFP_GFLI_FEEDPRINT_TABLE } from './config';
import { ManageUploadPayload } from '../manageUploads';

export type CfpGfliFeedprintTablePayload = {
  id: number;
  feed_print_code: number | null;
  cvb_code: string | null;
  source: string | null;
  product_name_en: string | null;
  product_name_nl: string | null;
  product_code_eu: string | null;
  product_name_eu: string | null;
  origin: string | null;
  climate: number | null;
  feed_print_upload: ManageUploadPayload;
};

export type CfpGfliFeedprintTableSerialized = {
  id: number;
  referenceUploadID: number;
  feedPrintCode: number;
  cvbCode: string;
  source: string;
  productNameEn: string;
  productNameNl: string;
  productCodeEu: string;
  productNameEu: string;
  origin: string;
  climate: number;
  uploadType: string;
  status: 'active' | 'inactive';
  filename: string;
  validDateFrom: string | null;
  validDateTo: string | null;
  notes: string;
  concepts: string[];
  uploadedAt: string;
  uploadedBy: string;
};

const { columns, serialized, unserialized } = CFP_GFLI_FEEDPRINT_TABLE.CfpGfliFeedprintTable;

const getDefaultSerializer = (data: DocumentData<CfpGfliFeedprintTablePayload[]>) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(unserialized, serialized, [
      new CellSerializer(columns.id.unserialized, columns.id.serialized),
      new CellSerializer(columns.referenceUploadID.unserialized, columns.referenceUploadID.serialized),
      new CellSerializer(columns.feedPrintCode.unserialized, columns.feedPrintCode.serialized),
      new CellSerializer(columns.cvbCode.unserialized, columns.cvbCode.serialized),
      new CellSerializer(columns.source.unserialized, columns.source.serialized),
      new CellSerializer(columns.productNameEn.unserialized, columns.productNameEn.serialized),
      new CellSerializer(columns.productNameNl.unserialized, columns.productNameNl.serialized),
      new CellSerializer(columns.productCodeEu.unserialized, columns.productCodeEu.serialized),
      new CellSerializer(columns.productNameEu.unserialized, columns.productNameEu.serialized),
      new CellSerializer(columns.origin.unserialized, columns.origin.serialized),
      new CellSerializer(columns.climate.unserialized, columns.climate.serialized),
      new CellSerializer(columns.uploadType.unserialized, columns.uploadType.serialized),
      new CellSerializer(columns.status.unserialized, columns.status.serialized),
      new CellSerializer(columns.filename.unserialized, columns.filename.serialized),
      new CellSerializer(
        columns.validDateFrom.unserialized,
        columns.validDateFrom.serialized,
        undefined,
        undefined,
        null,
      ),
      new CellSerializer(columns.validDateTo.unserialized, columns.validDateTo.serialized, undefined, undefined, null),
      new CellSerializer(columns.notes.unserialized, columns.notes.serialized),
      new CellSerializer(columns.concepts.unserialized, columns.concepts.serialized),
      new CellSerializer(columns.uploadedAt.unserialized, columns.uploadedAt.serialized),
      new CellSerializer(columns.uploadedBy.unserialized, columns.uploadedBy.serialized),
    ]),
  ]);
};

export const serializeCfpGfliFeedprintTableFactor = (serverData: ResourcesWrapper<CfpGfliFeedprintTablePayload>) => {
  const data = {
    [unserialized]: serverData.data.resources,
  };

  const serializer = getDefaultSerializer(data);

  return serializer.serialize();
};
