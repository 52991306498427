import { takeEvery } from 'redux-saga/effects';

import { ApiClient } from 'lib/http/ApiClient';
import ensure from 'lib/ensure';

import {
  fetchDashboardAppInfoAction,
  getSideMenuCounters,
  myJoinDataAction
} from 'store/reducers/uiReducer';
import { serializeDashboardTotal } from 'store/entities/UI';
import { unAuthHandler } from 'lib/unAuthHandler';

export const ensureGetDashboardTotal = ensure({
  api: ApiClient.dashboardTotal,
  action: getSideMenuCounters,
  serializeSuccessPayload: serializeDashboardTotal,
  transformRequestData: () => ({}),
  serializeFailurePayload: (err) => {
    unAuthHandler(url => window.location.replace(url))(err);
  }
});

export const ensureGetDashboardAppInfo = ensure({
  api: ApiClient.getDashboardAppInfo,
  action: fetchDashboardAppInfoAction,
});

export const ensureVisitVriComparisonTool = ensure({
  api: ApiClient.visitMyJoinDataLink,
  action: myJoinDataAction,
});

export default function* () {
  yield takeEvery(getSideMenuCounters.request, ensureGetDashboardTotal);
  yield takeEvery(fetchDashboardAppInfoAction.request, ensureGetDashboardAppInfo);
  yield takeEvery(myJoinDataAction.request, ensureVisitVriComparisonTool);
}
