import { IntlKeys } from 'lib/localization/keys';

enum Cl550Serialized {
  Cl550 = 'Cl550',
}

enum Cl550Unserialized {
  Cl550 = 'Cl550 ',
}

export enum Cl550SerializedHeaders {
  id = 'id',
  referenceUploadID = 'referenceUploadID',
  mainGroupCode = 'mainGroupCode',
  subGroupCode = 'subGroupCode',
  codeDetails = 'codeDetails',
  mainGroupName = 'mainGroupName',
  subgroupName = 'subgroupName',
  detailing = 'detailing',
  english = 'english',
  german = 'german',
  codeCondition = 'codeCondition',
  edi = 'edi',
  dgr = 'dgr',
  diseaseAndTreatment = 'diseaseAndTreatment',
  failure = 'failure',
  disposal = 'disposal',
  selection = 'selection',
  labResult = 'labResult',
  section = 'section',
  slaughterhouseData = 'slaughterhouseData',
  agility = 'agility',
  coverage = 'coverage',
  birth = 'birth',
  suckingPiglet = 'suckingPiglet',
  weanedBig = 'weanedBig',
  rearingSowAndBoar = 'rearingSowAndBoar',
  goodSow = 'goodSow',
  gestationSow = 'gestationSow',
  lactatingSow = 'lactatingSow',
  beer = 'beer',
  finisher = 'finisher',
  comment = 'comment',
  added = 'added',
  deleted = 'deleted',
  uploadType = 'uploadType',
  filename = 'filename',
  status = 'status',
  validDateFrom = 'validDateFrom',
  validDateTo = 'validDateTo',
  notes = 'notes',
  concepts = 'concepts',
  uploadedAt = 'uploadedAt',
  uploadedBy = 'uploadedBy',
}

export const Cl550UnserializedHeaders = {
  id: 'id',
  referenceUploadID: ['pig_disease_code_upload', 'id'],
  mainGroupCode: 'main_group_code',
  subGroupCode: 'subgroup_code',
  codeDetails: 'code_details',
  mainGroupName: 'main_group_name',
  subgroupName: 'subgroup_name',
  detailing: 'detailing',
  english: 'english',
  german: 'german',
  codeCondition: 'code_condition',
  edi: 'edi',
  dgr: 'dgr',
  diseaseAndTreatment: 'disease_and_treatment',
  failure: 'failure',
  disposal: 'disposal',
  selection: 'selection',
  labResult: 'lab_result',
  section: 'section',
  slaughterhouseData: 'slaughterhouse_data',
  agility: 'agility',
  coverage: 'coverage',
  birth: 'birth',
  suckingPiglet: 'sucking_piglet',
  weanedBig: 'weaned_big',
  rearingSowAndBoar: 'rearing_sow_and_boar',
  goodSow: 'good_sow',
  gestationSow: 'gestation_sow',
  lactatingSow: 'lactating_sow',
  beer: 'beer',
  finisher: 'finisher',
  comment: 'comment',
  added: 'added',
  deleted: 'deleted',
  uploadType: ['pig_disease_code_upload', 'upload_type'],
  filename: ['pig_disease_code_upload', 'file_name'],
  status: ['pig_disease_code_upload', 'status'],
  validDateFrom: ['pig_disease_code_upload', 'valid_from'],
  validDateTo: ['pig_disease_code_upload', 'valid_to'],
  notes: ['pig_disease_code_upload', 'notes'],
  concepts: ['pig_disease_code_upload', 'concepts'],
  uploadedAt: ['pig_disease_code_upload', 'uploaded_at'],
  uploadedBy: ['pig_disease_code_upload', 'uploaded_by', 'full_name'],
} as const;

export const CL550 = {
  [Cl550Serialized.Cl550]: {
    serialized: Cl550Serialized.Cl550,
    unserialized: Cl550Unserialized.Cl550,
    view: IntlKeys.referenceTablesCl550,

    columns: {
      [Cl550SerializedHeaders.id]: {
        serialized: Cl550SerializedHeaders.id,
        unserialized: Cl550UnserializedHeaders.id,
      },
      [Cl550SerializedHeaders.referenceUploadID]: {
        serialized: Cl550SerializedHeaders.referenceUploadID,
        unserialized: Cl550UnserializedHeaders.referenceUploadID,
      },
      [Cl550SerializedHeaders.mainGroupCode]: {
        serialized: Cl550SerializedHeaders.mainGroupCode,
        unserialized: Cl550UnserializedHeaders.mainGroupCode,
        view: IntlKeys.referenceTablesCl550MainGroupCode,
      },
      [Cl550SerializedHeaders.subGroupCode]: {
        serialized: Cl550SerializedHeaders.subGroupCode,
        unserialized: Cl550UnserializedHeaders.subGroupCode,
        view: IntlKeys.referenceTablesCl550SubGroupCode,
      },
      [Cl550SerializedHeaders.codeDetails]: {
        serialized: Cl550SerializedHeaders.codeDetails,
        unserialized: Cl550UnserializedHeaders.codeDetails,
        view: IntlKeys.referenceTablesCl550CodeDetails,
      },
      [Cl550SerializedHeaders.mainGroupName]: {
        serialized: Cl550SerializedHeaders.mainGroupName,
        unserialized: Cl550UnserializedHeaders.mainGroupName,
        view: IntlKeys.referenceTablesCl550MainGroupName,
      },
      [Cl550SerializedHeaders.subgroupName]: {
        serialized: Cl550SerializedHeaders.subgroupName,
        unserialized: Cl550UnserializedHeaders.subgroupName,
        view: IntlKeys.referenceTablesCl550SubgroupName,
      },
      [Cl550SerializedHeaders.detailing]: {
        serialized: Cl550SerializedHeaders.detailing,
        unserialized: Cl550UnserializedHeaders.detailing,
        view: IntlKeys.referenceTablesCl550Detailing,
      },
      [Cl550SerializedHeaders.english]: {
        serialized: Cl550SerializedHeaders.english,
        unserialized: Cl550UnserializedHeaders.english,
        view: IntlKeys.referenceTablesCl550English,
      },
      [Cl550SerializedHeaders.german]: {
        serialized: Cl550SerializedHeaders.german,
        unserialized: Cl550UnserializedHeaders.german,
        view: IntlKeys.referenceTablesCl550German,
      },
      [Cl550SerializedHeaders.codeCondition]: {
        serialized: Cl550SerializedHeaders.codeCondition,
        unserialized: Cl550UnserializedHeaders.codeCondition,
        view: IntlKeys.referenceTablesCl550CodeCondition,
      },
      [Cl550SerializedHeaders.edi]: {
        serialized: Cl550SerializedHeaders.edi,
        unserialized: Cl550UnserializedHeaders.edi,
        view: IntlKeys.referenceTablesCl550Edi,
      },
      [Cl550SerializedHeaders.dgr]: {
        serialized: Cl550SerializedHeaders.dgr,
        unserialized: Cl550UnserializedHeaders.dgr,
        view: IntlKeys.referenceTablesCl550Dgr,
      },
      [Cl550SerializedHeaders.diseaseAndTreatment]: {
        serialized: Cl550SerializedHeaders.diseaseAndTreatment,
        unserialized: Cl550UnserializedHeaders.diseaseAndTreatment,
        view: IntlKeys.referenceTablesCl550DiseaseAndTreatment,
      },
      [Cl550SerializedHeaders.failure]: {
        serialized: Cl550SerializedHeaders.failure,
        unserialized: Cl550UnserializedHeaders.failure,
        view: IntlKeys.referenceTablesCl550Failure,
      },
      [Cl550SerializedHeaders.disposal]: {
        serialized: Cl550SerializedHeaders.disposal,
        unserialized: Cl550UnserializedHeaders.disposal,
        view: IntlKeys.referenceTablesCl550Disposal,
      },
      [Cl550SerializedHeaders.selection]: {
        serialized: Cl550SerializedHeaders.selection,
        unserialized: Cl550UnserializedHeaders.selection,
        view: IntlKeys.referenceTablesCl550Selection,
      },
      [Cl550SerializedHeaders.labResult]: {
        serialized: Cl550SerializedHeaders.labResult,
        unserialized: Cl550UnserializedHeaders.labResult,
        view: IntlKeys.referenceTablesCl550LabResult,
      },
      [Cl550SerializedHeaders.section]: {
        serialized: Cl550SerializedHeaders.section,
        unserialized: Cl550UnserializedHeaders.section,
        view: IntlKeys.referenceTablesCl550Section,
      },
      [Cl550SerializedHeaders.slaughterhouseData]: {
        serialized: Cl550SerializedHeaders.slaughterhouseData,
        unserialized: Cl550UnserializedHeaders.slaughterhouseData,
        view: IntlKeys.referenceTablesCl550SlaughterhouseData,
      },
      [Cl550SerializedHeaders.agility]: {
        serialized: Cl550SerializedHeaders.agility,
        unserialized: Cl550UnserializedHeaders.agility,
        view: IntlKeys.referenceTablesCl550Agility,
      },
      [Cl550SerializedHeaders.coverage]: {
        serialized: Cl550SerializedHeaders.coverage,
        unserialized: Cl550UnserializedHeaders.coverage,
        view: IntlKeys.referenceTablesCl550Coverage,
      },
      [Cl550SerializedHeaders.birth]: {
        serialized: Cl550SerializedHeaders.birth,
        unserialized: Cl550UnserializedHeaders.birth,
        view: IntlKeys.referenceTablesCl550Birth,
      },
      [Cl550SerializedHeaders.suckingPiglet]: {
        serialized: Cl550SerializedHeaders.suckingPiglet,
        unserialized: Cl550UnserializedHeaders.suckingPiglet,
        view: IntlKeys.referenceTablesCl550SuckingPiglet,
      },
      [Cl550SerializedHeaders.weanedBig]: {
        serialized: Cl550SerializedHeaders.weanedBig,
        unserialized: Cl550UnserializedHeaders.weanedBig,
        view: IntlKeys.referenceTablesCl550WeanedBig,
      },
      [Cl550SerializedHeaders.rearingSowAndBoar]: {
        serialized: Cl550SerializedHeaders.rearingSowAndBoar,
        unserialized: Cl550UnserializedHeaders.rearingSowAndBoar,
        view: IntlKeys.referenceTablesCl550RearingSowAndBoar,
      },
      [Cl550SerializedHeaders.goodSow]: {
        serialized: Cl550SerializedHeaders.goodSow,
        unserialized: Cl550UnserializedHeaders.goodSow,
        view: IntlKeys.referenceTablesCl550GoodSow,
      },
      [Cl550SerializedHeaders.gestationSow]: {
        serialized: Cl550SerializedHeaders.gestationSow,
        unserialized: Cl550UnserializedHeaders.gestationSow,
        view: IntlKeys.referenceTablesCl550GestationSow,
      },
      [Cl550SerializedHeaders.lactatingSow]: {
        serialized: Cl550SerializedHeaders.lactatingSow,
        unserialized: Cl550UnserializedHeaders.lactatingSow,
        view: IntlKeys.referenceTablesCl550LactatingSow,
      },
      [Cl550SerializedHeaders.beer]: {
        serialized: Cl550SerializedHeaders.beer,
        unserialized: Cl550UnserializedHeaders.beer,
        view: IntlKeys.referenceTablesCl550Beer,
      },
      [Cl550SerializedHeaders.finisher]: {
        serialized: Cl550SerializedHeaders.finisher,
        unserialized: Cl550UnserializedHeaders.finisher,
        view: IntlKeys.referenceTablesCl550Finisher,
      },
      [Cl550SerializedHeaders.comment]: {
        serialized: Cl550SerializedHeaders.comment,
        unserialized: Cl550UnserializedHeaders.comment,
        view: IntlKeys.referenceTablesCl550Comment,
      },
      [Cl550SerializedHeaders.added]: {
        serialized: Cl550SerializedHeaders.added,
        unserialized: Cl550UnserializedHeaders.added,
        view: IntlKeys.referenceTablesCl550Added,
      },
      [Cl550SerializedHeaders.deleted]: {
        serialized: Cl550SerializedHeaders.deleted,
        unserialized: Cl550UnserializedHeaders.deleted,
        view: IntlKeys.referenceTablesCl550Deleted,
      },
      [Cl550SerializedHeaders.status]: {
        serialized: Cl550SerializedHeaders.status,
        unserialized: Cl550UnserializedHeaders.status,
      },
      [Cl550SerializedHeaders.uploadType]: {
        serialized: Cl550SerializedHeaders.uploadType,
        unserialized: Cl550UnserializedHeaders.uploadType,
      },
      [Cl550SerializedHeaders.notes]: {
        serialized: Cl550SerializedHeaders.notes,
        unserialized: Cl550UnserializedHeaders.notes,
      },
      [Cl550SerializedHeaders.concepts]: {
        serialized: Cl550SerializedHeaders.concepts,
        unserialized: Cl550UnserializedHeaders.concepts,
        view: IntlKeys.concepts,
      },
      [Cl550SerializedHeaders.filename]: {
        serialized: Cl550SerializedHeaders.filename,
        unserialized: Cl550UnserializedHeaders.filename,
      },
      [Cl550SerializedHeaders.validDateFrom]: {
        serialized: Cl550SerializedHeaders.validDateFrom,
        unserialized: Cl550UnserializedHeaders.validDateFrom,
      },
      [Cl550SerializedHeaders.validDateTo]: {
        serialized: Cl550SerializedHeaders.validDateTo,
        unserialized: Cl550UnserializedHeaders.validDateTo,
      },
      [Cl550SerializedHeaders.uploadedAt]: {
        serialized: Cl550SerializedHeaders.uploadedAt,
        unserialized: Cl550UnserializedHeaders.uploadedAt,
      },
      [Cl550SerializedHeaders.uploadedBy]: {
        serialized: Cl550SerializedHeaders.uploadedBy,
        unserialized: Cl550UnserializedHeaders.uploadedBy,
      },
    },
  },
};
