import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer } from 'lib/excel/serilizers/Cell';
import { ResourcesWrapper } from 'lib/http/utils';

import { IPCC_EMISSION_FACTORS } from './config';
import { ManageUploadPayload } from '../manageUploads';

export type IpccEmissionFactorsPayload = {
  id: number;
  factor: string | null;
  description: string | null;
  default_value: number | null;
  uncertainty_range: string | null;
  ipcc_emission_factor_upload: ManageUploadPayload;
};

export type IpccEmissionFactorsSerialized = {
  id: number;
  referenceUploadID: number;
  factor: string | null;
  description: string | null;
  defaultValue: string | null;
  uncertaintyRange: string | null;
  uploadType: string;
  status: 'active' | 'inactive';
  filename: string;
  validDateFrom: string | null;
  validDateTo: string | null;
  notes: string;
  concepts: string[];
  uploadedAt: string;
  uploadedBy: string;
};

const { columns, serialized, unserialized } = IPCC_EMISSION_FACTORS.IpccEmissionFactors;

const getDefaultSerializer = (data: DocumentData<IpccEmissionFactorsPayload[]>) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(unserialized, serialized, [
      new CellSerializer(columns.id.unserialized, columns.id.serialized),
      new CellSerializer(columns.referenceUploadID.unserialized, columns.referenceUploadID.serialized),
      new CellSerializer(columns.factor.unserialized, columns.factor.serialized),
      new CellSerializer(columns.description.unserialized, columns.description.serialized),
      new CellSerializer(columns.defaultValue.unserialized, columns.defaultValue.serialized),
      new CellSerializer(columns.uncertaintyRange.unserialized, columns.uncertaintyRange.serialized),
      new CellSerializer(columns.uploadType.unserialized, columns.uploadType.serialized),
      new CellSerializer(columns.status.unserialized, columns.status.serialized),
      new CellSerializer(columns.filename.unserialized, columns.filename.serialized),
      new CellSerializer(
        columns.validDateFrom.unserialized,
        columns.validDateFrom.serialized,
        undefined,
        undefined,
        null,
      ),
      new CellSerializer(columns.validDateTo.unserialized, columns.validDateTo.serialized, undefined, undefined, null),
      new CellSerializer(columns.notes.unserialized, columns.notes.serialized),
      new CellSerializer(columns.concepts.unserialized, columns.concepts.serialized),
      new CellSerializer(columns.uploadedAt.unserialized, columns.uploadedAt.serialized),
      new CellSerializer(columns.uploadedBy.unserialized, columns.uploadedBy.serialized),
    ]),
  ]);
};

export const serializeIpccEmissionFactors = (serverData: ResourcesWrapper<IpccEmissionFactorsPayload>) => {
  const data = {
    [unserialized]: serverData.data.resources,
  };

  const serializer = getDefaultSerializer(data);

  return serializer.serialize();
};
