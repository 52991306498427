import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer } from 'lib/excel/serilizers/Cell';
import { ResourcesWrapper } from 'lib/http/utils';

import { CONTENT_OF_NITRIC_OXIDE } from './config';
import { ManageUploadPayload } from '../manageUploads';

export type ContentOfNitricOxidePayload = {
  id: number;
  livestockCategory: string | null;
  manureType: string | null;
  valueN2oAndNo: number | null;
  unitN2oAndNo: string | null;
  valueN2: number | null;
  unitN2: string | null;
  content_of_nitric_oxide_upload: ManageUploadPayload;
};

export type ContentOfNitricOxideSerialized = {
  id: number;
  referenceUploadID: number;
  livestockCategory: string;
  manureType: string;
  valueN2oAndNo: number;
  unitN2oAndNo: string;
  valueN2: number;
  unitN2: string;
  uploadType: string;
  status: 'active' | 'inactive';
  filename: string;
  validDateFrom: string | null;
  validDateTo: string | null;
  notes: string;
  concepts: string[];
  uploadedAt: string;
  uploadedBy: string;
};

const { columns, serialized, unserialized } = CONTENT_OF_NITRIC_OXIDE.ContentOfNitricOxide;

const getDefaultSerializer = (data: DocumentData<ContentOfNitricOxidePayload[]>) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(unserialized, serialized, [
      new CellSerializer(columns.id.unserialized, columns.id.serialized),
      new CellSerializer(columns.referenceUploadID.unserialized, columns.referenceUploadID.serialized),
      new CellSerializer(columns.livestockCategory.unserialized, columns.livestockCategory.serialized),
      new CellSerializer(columns.manureType.unserialized, columns.manureType.serialized),
      new CellSerializer(columns.valueN2oAndNo.unserialized, columns.valueN2oAndNo.serialized),
      new CellSerializer(columns.unitN2oAndNo.unserialized, columns.unitN2oAndNo.serialized),
      new CellSerializer(columns.valueN2.unserialized, columns.valueN2.serialized),
      new CellSerializer(columns.unitN2.unserialized, columns.unitN2.serialized),
      new CellSerializer(columns.uploadType.unserialized, columns.uploadType.serialized),
      new CellSerializer(columns.status.unserialized, columns.status.serialized),
      new CellSerializer(columns.filename.unserialized, columns.filename.serialized),
      new CellSerializer(
        columns.validDateFrom.unserialized,
        columns.validDateFrom.serialized,
        undefined,
        undefined,
        null,
      ),
      new CellSerializer(columns.validDateTo.unserialized, columns.validDateTo.serialized, undefined, undefined, null),
      new CellSerializer(columns.notes.unserialized, columns.notes.serialized),
      new CellSerializer(columns.concepts.unserialized, columns.concepts.serialized),
      new CellSerializer(columns.uploadedAt.unserialized, columns.uploadedAt.serialized),
      new CellSerializer(columns.uploadedBy.unserialized, columns.uploadedBy.serialized),
    ]),
  ]);
};

export const serializeContentOfNitricOxide = (serverData: ResourcesWrapper<ContentOfNitricOxidePayload>) => {
  const data = {
    [unserialized]: serverData.data.resources,
  };

  const serializer = getDefaultSerializer(data);

  return serializer.serialize();
};
