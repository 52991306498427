import { IntlKeys } from 'lib/localization/keys';

enum AllocationManureSerialized {
  AllocationManure = 'AllocationManure',
}

enum AllocationManureUnserialized {
  AllocationManure = 'AllocationManure',
}

export enum AllocationManureSerializedHeaders {
  id = 'id',
  referenceUploadID = 'referenceUploadID',
  animalCategory = 'animalCategory',
  manure = 'manure',
  gas = 'gas',
  electricity = 'electricity',
  water = 'water',
  uploadType = 'uploadType',
  filename = 'filename',
  status = 'status',
  validDateFrom = 'validDateFrom',
  validDateTo = 'validDateTo',
  notes = 'notes',
  concepts = 'concepts',
  uploadedAt = 'uploadedAt',
  uploadedBy = 'uploadedBy',
}

export const AllocationManureUnserializedHeaders = {
  id: 'id',
  referenceUploadID: ['allocation_manure_upload', 'id'],
  animalCategory: 'animal_category',
  manure: 'manure',
  gas: 'gas',
  electricity: 'electricity',
  water: 'water',
  uploadType: ['allocation_manure_upload', 'upload_type'],
  filename: ['allocation_manure_upload', 'file_name'],
  status: ['allocation_manure_upload', 'status'],
  validDateFrom: ['allocation_manure_upload', 'valid_from'],
  validDateTo: ['allocation_manure_upload', 'valid_to'],
  notes: ['allocation_manure_upload', 'notes'],
  concepts: ['allocation_manure_upload', 'concepts'],
  uploadedAt: ['allocation_manure_upload', 'uploaded_at'],
  uploadedBy: ['allocation_manure_upload', 'uploaded_by', 'full_name'],
} as const;

export const ALLOCATION_MANURE = {
  [AllocationManureSerialized.AllocationManure]: {
    serialized: AllocationManureSerialized.AllocationManure,
    unserialized: AllocationManureUnserialized.AllocationManure,
    view: IntlKeys.referenceTablesAllocationManure,

    columns: {
      [AllocationManureSerializedHeaders.id]: {
        serialized: AllocationManureSerializedHeaders.id,
        unserialized: AllocationManureUnserializedHeaders.id,
      },
      [AllocationManureSerializedHeaders.referenceUploadID]: {
        serialized: AllocationManureSerializedHeaders.referenceUploadID,
        unserialized: AllocationManureUnserializedHeaders.referenceUploadID,
      },
      [AllocationManureSerializedHeaders.animalCategory]: {
        serialized: AllocationManureSerializedHeaders.animalCategory,
        unserialized: AllocationManureUnserializedHeaders.animalCategory,
        view: IntlKeys.referenceTablesAllocationManureAnimalCategory,
      },
      [AllocationManureSerializedHeaders.manure]: {
        serialized: AllocationManureSerializedHeaders.manure,
        unserialized: AllocationManureUnserializedHeaders.manure,
        view: IntlKeys.referenceTablesAllocationManureManure,
      },
      [AllocationManureSerializedHeaders.gas]: {
        serialized: AllocationManureSerializedHeaders.gas,
        unserialized: AllocationManureUnserializedHeaders.gas,
        view: IntlKeys.referenceTablesAllocationManureGas,
      },
      [AllocationManureSerializedHeaders.electricity]: {
        serialized: AllocationManureSerializedHeaders.electricity,
        unserialized: AllocationManureUnserializedHeaders.electricity,
        view: IntlKeys.referenceTablesAllocationManureElectricity,
      },
      [AllocationManureSerializedHeaders.water]: {
        serialized: AllocationManureSerializedHeaders.water,
        unserialized: AllocationManureUnserializedHeaders.water,
        view: IntlKeys.referenceTablesAllocationManureWater,
      },
      [AllocationManureSerializedHeaders.status]: {
        serialized: AllocationManureSerializedHeaders.status,
        unserialized: AllocationManureUnserializedHeaders.status,
      },
      [AllocationManureSerializedHeaders.uploadType]: {
        serialized: AllocationManureSerializedHeaders.uploadType,
        unserialized: AllocationManureUnserializedHeaders.uploadType,
      },
      [AllocationManureSerializedHeaders.notes]: {
        serialized: AllocationManureSerializedHeaders.notes,
        unserialized: AllocationManureUnserializedHeaders.notes,
      },
      [AllocationManureSerializedHeaders.concepts]: {
        serialized: AllocationManureSerializedHeaders.concepts,
        unserialized: AllocationManureUnserializedHeaders.concepts,
        original: IntlKeys.concepts,
      },
      [AllocationManureSerializedHeaders.filename]: {
        serialized: AllocationManureSerializedHeaders.filename,
        unserialized: AllocationManureUnserializedHeaders.filename,
      },
      [AllocationManureSerializedHeaders.validDateFrom]: {
        serialized: AllocationManureSerializedHeaders.validDateFrom,
        unserialized: AllocationManureUnserializedHeaders.validDateFrom,
      },
      [AllocationManureSerializedHeaders.validDateTo]: {
        serialized: AllocationManureSerializedHeaders.validDateTo,
        unserialized: AllocationManureUnserializedHeaders.validDateTo,
      },
      [AllocationManureSerializedHeaders.uploadedAt]: {
        serialized: AllocationManureSerializedHeaders.uploadedAt,
        unserialized: AllocationManureUnserializedHeaders.uploadedAt,
      },
      [AllocationManureSerializedHeaders.uploadedBy]: {
        serialized: AllocationManureSerializedHeaders.uploadedBy,
        unserialized: AllocationManureUnserializedHeaders.uploadedBy,
      },
    },
  },
};
