import { IntlKeys } from 'lib/localization/keys';

enum Gwp100FactorsSerialized {
  Gwp100Factors = 'Gwp100Factors',
}

enum Gwp100FactorsUnserialized {
  Gwp100Factors = 'Gwp100Factors ',
}

export enum Gwp100FactorsSerializedHeaders {
  id = 'id',
  referenceUploadID = 'referenceUploadID',
  greenhouseGas = 'greenhouseGas',
  gwp100Factor = 'gwp100Factor',
  gwp100FactorUnit = 'gwp100FactorUnit',
  uploadType = 'uploadType',
  filename = 'filename',
  status = 'status',
  validDateFrom = 'validDateFrom',
  validDateTo = 'validDateTo',
  notes = 'notes',
  concepts = 'concepts',
  uploadedAt = 'uploadedAt',
  uploadedBy = 'uploadedBy',
}

export const Gwp100FactorsUnserializedHeaders = {
  id: 'id',
  referenceUploadID: ['gwp100_factor_upload', 'id'],
  greenhouseGas: 'greenhouse_gas',
  gwp100Factor: 'gwp100_factor',
  gwp100FactorUnit: 'gwp100_factor_unit',
  uploadType: ['gwp100_factor_upload', 'upload_type'],
  filename: ['gwp100_factor_upload', 'file_name'],
  status: ['gwp100_factor_upload', 'status'],
  validDateFrom: ['gwp100_factor_upload', 'valid_from'],
  validDateTo: ['gwp100_factor_upload', 'valid_to'],
  notes: ['gwp100_factor_upload', 'notes'],
  concepts: ['gwp100_factor_upload', 'concepts'],
  uploadedAt: ['gwp100_factor_upload', 'uploaded_at'],
  uploadedBy: ['gwp100_factor_upload', 'uploaded_by', 'full_name'],
} as const;

export const GWP_100_FACTORS = {
  [Gwp100FactorsSerialized.Gwp100Factors]: {
    serialized: Gwp100FactorsSerialized.Gwp100Factors,
    unserialized: Gwp100FactorsUnserialized.Gwp100Factors,
    view: IntlKeys.referenceTablesGwp100Factors,

    columns: {
      [Gwp100FactorsSerializedHeaders.id]: {
        serialized: Gwp100FactorsSerializedHeaders.id,
        unserialized: Gwp100FactorsUnserializedHeaders.id,
      },
      [Gwp100FactorsSerializedHeaders.referenceUploadID]: {
        serialized: Gwp100FactorsSerializedHeaders.referenceUploadID,
        unserialized: Gwp100FactorsUnserializedHeaders.referenceUploadID,
      },
      [Gwp100FactorsSerializedHeaders.greenhouseGas]: {
        serialized: Gwp100FactorsSerializedHeaders.greenhouseGas,
        unserialized: Gwp100FactorsUnserializedHeaders.greenhouseGas,
        view: IntlKeys.referenceTablesGwp100FactorsGreenhouseGas,
      },
      [Gwp100FactorsSerializedHeaders.gwp100Factor]: {
        serialized: Gwp100FactorsSerializedHeaders.gwp100Factor,
        unserialized: Gwp100FactorsUnserializedHeaders.gwp100Factor,
        view: IntlKeys.referenceTablesGwp100FactorsGwp,
      },
      [Gwp100FactorsSerializedHeaders.gwp100FactorUnit]: {
        serialized: Gwp100FactorsSerializedHeaders.gwp100FactorUnit,
        unserialized: Gwp100FactorsUnserializedHeaders.gwp100FactorUnit,
        view: IntlKeys.referenceTablesGwp100FactorsGwpUnit,
      },
      [Gwp100FactorsSerializedHeaders.status]: {
        serialized: Gwp100FactorsSerializedHeaders.status,
        unserialized: Gwp100FactorsUnserializedHeaders.status,
      },
      [Gwp100FactorsSerializedHeaders.uploadType]: {
        serialized: Gwp100FactorsSerializedHeaders.uploadType,
        unserialized: Gwp100FactorsUnserializedHeaders.uploadType,
      },
      [Gwp100FactorsSerializedHeaders.notes]: {
        serialized: Gwp100FactorsSerializedHeaders.notes,
        unserialized: Gwp100FactorsUnserializedHeaders.notes,
      },
      [Gwp100FactorsSerializedHeaders.concepts]: {
        serialized: Gwp100FactorsSerializedHeaders.concepts,
        unserialized: Gwp100FactorsUnserializedHeaders.concepts,
        view: IntlKeys.concepts,
      },
      [Gwp100FactorsSerializedHeaders.filename]: {
        serialized: Gwp100FactorsSerializedHeaders.filename,
        unserialized: Gwp100FactorsUnserializedHeaders.filename,
      },
      [Gwp100FactorsSerializedHeaders.validDateFrom]: {
        serialized: Gwp100FactorsSerializedHeaders.validDateFrom,
        unserialized: Gwp100FactorsUnserializedHeaders.validDateFrom,
      },
      [Gwp100FactorsSerializedHeaders.validDateTo]: {
        serialized: Gwp100FactorsSerializedHeaders.validDateTo,
        unserialized: Gwp100FactorsUnserializedHeaders.validDateTo,
      },
      [Gwp100FactorsSerializedHeaders.uploadedAt]: {
        serialized: Gwp100FactorsSerializedHeaders.uploadedAt,
        unserialized: Gwp100FactorsUnserializedHeaders.uploadedAt,
      },
      [Gwp100FactorsSerializedHeaders.uploadedBy]: {
        serialized: Gwp100FactorsSerializedHeaders.uploadedBy,
        unserialized: Gwp100FactorsUnserializedHeaders.uploadedBy,
      },
    },
  },
};
