import { takeLatest, takeEvery, call, race, take } from 'redux-saga/effects';

import ensure from 'lib/ensure';
import { ApiClient } from 'lib/http/ApiClient';
import { ResourcesWrapper } from 'lib/http/utils';
import { ObjectWithProps } from 'lib/excel/serilizers/Cell';

import { AdminOrganizationsSerialized } from 'lib/tables/config/admin/organizations/config';
import { UnmatchedOrganizationsSerialized } from 'lib/tables/config/joindata/unmatched-organizations/config';

import filterConfigs from 'store/filters/entities';
import { FILTER_ENTITY_TYPES } from 'store/filters/reducer';
import { fetchBatchesAction, fetchBatchAction, clearResourceAction } from 'store/reducers/batchReducer';
import { serializeCertificatesInfo } from 'store/entities/certificates/kiwa/daily';
import { serializeFarmsInfo } from 'store/entities/certificates/kiwa/weekly/farms';
import { serializeRecognitionsInfo } from 'store/entities/certificates/kiwa/weekly/recognitions';
import { serializeDeviationsInfo } from 'store/entities/certificates/kiwa/weekly/deviations';
import { serializeHistoryInfo } from 'store/entities/history';
import { serializeSlaughterMessagesInfo } from 'store/entities/slaughter/messages';
import { serializeSlaughterDetailsInfo } from 'store/entities/slaughter/messageDetails';
import { serializeSlaughterPriceCorrections } from 'store/entities/slaughter/priceCorrections';
import { serializeSlaughterChargeOrPremiums } from 'store/entities/slaughter/chargeOrPremiums';
import { serializeInvoiceMessagesInfo } from 'store/entities/invoices/invoiceMessages';
import { serializeInvoiceDetailsInfo } from 'store/entities/invoices/invoiceDetails';
import { serializeCertificatesWelfare } from 'store/entities/certificates/welfare';
import { serializeCertificatesIKB } from 'store/entities/certificates/ikb';
import {
  UserPassportPayload,
  UserPassportTransformedPayload,
  serializeUserPassports,
} from 'store/entities/userPassports';
import { serializeDeliveryMessageInfo } from 'store/entities/delivery/messages/serializer';
import { serializeDeliverySegmentInfo } from 'store/entities/delivery/segment/serializer';
import { serializeDeliverySegmentContentsInfo } from 'store/entities/delivery/segmentContents/serializer';
import { serializeGenetic } from 'store/entities/genetics';
import { serializeMovementEvents } from 'store/entities/movementEvents';
import { serializeVeterinarianData } from 'store/entities/veterinarianData';
import { serializeAdminOrganizations } from 'store/entities/adminOrganizations';
import { serializeAdminUsers } from 'store/entities/admin/users';
import { serializeAdminUpdateLogs } from 'store/entities/admin/updates';
import { serializePermissionProfileTemplates } from 'store/admin/entities';
import { serializeAnimalPassports } from 'store/entities/animalPassports';
import { serializeCertificatesGrouped } from 'store/entities/certificates/groupedIndex/certificates/certificatesGrouped';
import { serializeValidityGrouped, ValidityGroupedRaw } from 'store/entities/certificates/groupedIndex/validityGrouped';
import { serializeJoindataParticipations } from 'store/entities/joindata/participations';
import { serializeJoindataRequestedParticipations } from 'store/entities/joindata/requestedParticipations';
import { serializeTransportLoadings, serializeTransportUnloadings } from 'store/entities/transports/loadings';
import { serializeTransports } from 'store/entities/transports';
import { serializeCompanyReport, serializeCompanyReports } from 'store/entities/farmerInput/companyReports';
import { serializeByProduct, serializeByProductReports } from 'store/entities/farmerInput/byProducts';
import {
  serializeFermentationReport,
  serializeFermentationReports,
} from 'store/entities/farmerInput/fermentationReports';
import { serializeManureReport, serializeManureReports } from 'store/entities/farmerInput/manureReports';
import { serializeFarmerInputUpdatesLog } from 'store/entities/farmerInput/updatesLog';

import { serializeRequestLogUsers } from 'store/entities/monitoringDashboard/users';

import { serializeRawMaterialsOrigins } from 'store/entities/rawMaterialsOrigin';

// reference tables imports
import { serializeManageUploads } from 'store/entities/referenceTables/manageUploads';
import { serializePenLevels } from 'store/entities/referenceTables/pensLevel';
import { serializePensTypeSowBarn } from 'store/entities/referenceTables/pensTypeSowBarn';
import { serializeYesNo } from 'store/entities/referenceTables/yesNo';
import { serializeReferenceTablesDashboard } from 'store/entities/referenceTables/dashboard';
import { serializeCfpCo2EmissionFactor } from 'store/entities/referenceTables/cfpCo2EmissionFactor';
import { serializeCvbTable, serializeCvbTableRecord } from 'store/entities/referenceTables/cvbTable';
import { serializeIpccEmissionFactors } from 'store/entities/referenceTables/ipccEmissionFactors';
import { serializeCfpGfliFeedprintTableFactor } from 'store/entities/referenceTables/cfpGfliFeedprintTable';
import { serializeContentOfNitricOxide } from 'store/entities/referenceTables/contentOfNitricOxide';
import { serializePigHousingEmissionFactors } from 'store/entities/referenceTables/pigHousingEmissionFactors';
import { serializeElectricityConsumptionRates } from 'store/entities/referenceTables/electricityConsumptionRates';
import { serializeGasConsumptionRates } from 'store/entities/referenceTables/gasConsumptionRates';
import { serializeWaterConsumptionRates } from 'store/entities/referenceTables/waterConsumptionRates';
import { serializeRavCodes } from 'store/entities/referenceTables/ravCodes';
import { serializeGwp100Factors } from 'store/entities/referenceTables/gwp100Factors';
import { serializeWurMcfEfem } from 'store/entities/referenceTables/wurMcfEfem';
import { serializeCl550 } from 'store/entities/referenceTables/cl550';
import { serializeCl649 } from 'store/entities/referenceTables/cl649';
import { serializeCl650 } from 'store/entities/referenceTables/cl650';
import { serializeCl607 } from 'store/entities/referenceTables/cl607';
import { serializeSuppliersNumbers } from 'store/entities/referenceTables/suppliersNumbers';
import { serializeCountryCodes } from 'store/entities/referenceTables/countryCodes';
import { serializeRavReferenceList } from 'store/entities/referenceTables/ravReferenceList';
import { serializeAllocationManure } from 'store/entities/referenceTables/allocationManure';
import { serializeGreenhouseGasEmission } from 'store/entities/referenceTables/greenhouseGasEmission';
import { serializeEntitiesList } from 'store/entities/referenceTables/entitiesList';

// farm management imports
import { serializeGroupGroups } from 'store/entities/farmManagementData/groupGroups';
import { serializeGroupWeights } from 'store/entities/farmManagementData/groupWeights';
import { serializeFtpUpdates } from 'store/entities/farmManagementData/ftpUpdates';
import { serializeGroupFinancialEvents } from 'store/entities/farmManagementData/groupFinancialEvents';
import { serializeGroupRemovals } from 'store/entities/farmManagementData/groupRemovals';
import { serializeGroupArrival } from 'store/entities/farmManagementData/groupArrival';
import { serializeGroupMoves } from 'store/entities/farmManagementData/groupMoves';
import { serializeCodesAnimalRemarks } from 'store/entities/farmManagementData/codesAnimalRemarks';
import { serializeCodesDefects } from 'store/entities/farmManagementData/codesDefects';
import { serializeCodesDiagnose } from 'store/entities/farmManagementData/codesDiagnose';
import { serializeCodesAnimalScore } from 'store/entities/farmManagementData/codesAnimalScore';
import { serializeCodesInsemination } from 'store/entities/farmManagementData/codesInsemination';
import { serializeCodesTreatments } from 'store/entities/farmManagementData/codesTreatment';
import { serializeGroupPurchasedFood } from 'store/entities/farmManagementData/groupPurchasedFood';
import { serializeGroupUsedFood } from 'store/entities/farmManagementData/groupUsedFood';
import { serializeCodesMatingType } from 'store/entities/farmManagementData/codesMatingType';
import { serializeCodesWeightType } from 'store/entities/farmManagementData/codesWeightType';
import { serializeCodesRemovalType } from 'store/entities/farmManagementData/codesRemovalType';
import { serializeCodesAnimalIdentityType } from 'store/entities/farmManagementData/codesAnimalIdentityType';
import { serializeCodesFarmOption } from 'store/entities/farmManagementData/codesFarmOption';
import { serializeCodesBarn } from 'store/entities/farmManagementData/codesBarn';
import { serializeCodesRoom } from 'store/entities/farmManagementData/codesRoom';
import { serializeCodesPen } from 'store/entities/farmManagementData/codesPen';
import { serializeCodesAccount } from 'store/entities/farmManagementData/codesAccount';
import { serializeCodesEventTypes } from 'store/entities/farmManagementData/codesEventTypes';
import { serializeGroupExpense } from 'store/entities/farmManagementData/groupExpense';

import { serializeFarmManagementPassports } from 'store/entities/admin/farmManagementPassports';
import { serializeAliases } from 'store/entities/admin/aliases';
import { serializeCarbonFootprints, serializeCarbonFootprint } from 'store/entities/carbonFootprint';
import { serializeLabels, serializeLabel } from 'store/entities/monitoringDashboard/labels';

import { serializeHfmOutputs, serializeHfmOutput } from 'store/entities/hfmOutput';

import { serializeErrorsLog } from 'store/entities/errorsLog';
import { serializeDataStatistics } from 'lib/tables/config/monitoringDashboard/data/config';
import { serializeAdminConcepts } from 'store/entities/admin/concepts';

const generateFiltersTransformer = (entity: FILTER_ENTITY_TYPES) => ({ filters = {}, ...rest }) => ({
  ...filterConfigs[entity].serializeFilters(filters),
  ...rest,
});

export const ensureGetCertificates = ensure({
  api: ApiClient.getCertificates,
  action: fetchBatchesAction,
  serializeSuccessPayload: (response) => {
    return serializeCertificatesInfo(response);
  },
  transformRequestData: generateFiltersTransformer(FILTER_ENTITY_TYPES.CERTIFICATES_DAILY),
});

export const ensureGetSlaughterMessages = ensure({
  api: ApiClient.getSlaughterMessages,
  action: fetchBatchesAction,
  serializeSuccessPayload: (response) => {
    return serializeSlaughterMessagesInfo(response);
  },
  transformRequestData: generateFiltersTransformer(FILTER_ENTITY_TYPES.SLAUGHTER_MESSAGES),
});

export const ensureGetSlaughterDetails = ensure({
  api: ApiClient.getSlaughterDetails,
  action: fetchBatchesAction,
  serializeSuccessPayload: (response) => {
    return serializeSlaughterDetailsInfo(response);
  },
  transformRequestData: generateFiltersTransformer(FILTER_ENTITY_TYPES.SLAUGHTER_DETAILS),
});

export const ensureGetSlaughterPriceCorrections = ensure({
  api: ApiClient.getSlaughterPriceCorrections,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializeSlaughterPriceCorrections,
  transformRequestData: generateFiltersTransformer(FILTER_ENTITY_TYPES.SLAUGHTER_PRICE_CORRECTIONS),
});

export const ensureGetSlaughterChargeOrPremiums = ensure({
  api: ApiClient.getSlaughterChargeOrPremiums,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializeSlaughterChargeOrPremiums,
});

export const ensureGetDeliverySegmentContentsTable = ensure({
  api: ApiClient.getDeliveryContents,
  action: fetchBatchesAction,
  serializeSuccessPayload: (response) => {
    return serializeDeliverySegmentContentsInfo(response);
  },
});

export const ensureGetFarms = ensure({
  api: ApiClient.getFarms,
  action: fetchBatchesAction,
  serializeSuccessPayload: (response) => {
    return serializeFarmsInfo(response);
  },
  transformRequestData: generateFiltersTransformer(FILTER_ENTITY_TYPES.CERTIFICATES_WEEKLY_FARMS),
});

export const ensureGetRecognitions = ensure({
  api: ApiClient.getRecognitions,
  action: fetchBatchesAction,
  serializeSuccessPayload: (response) => {
    return serializeRecognitionsInfo(response);
  },
  transformRequestData: generateFiltersTransformer(FILTER_ENTITY_TYPES.CERTIFICATES_WEEKLY_RECOGNITIONS),
});

export const ensureGetDeliverySegment = ensure({
  api: ApiClient.getDeliverySegment,
  action: fetchBatchesAction,
  serializeSuccessPayload: (response) => serializeDeliverySegmentInfo(response),
});

export const ensureGetDeliveryMessages = ensure({
  api: ApiClient.getDeliveryMessages,
  action: fetchBatchesAction,
  serializeSuccessPayload: (response) => serializeDeliveryMessageInfo(response),
  transformRequestData: generateFiltersTransformer(FILTER_ENTITY_TYPES.DELIVERY_MESSAGES),
});

export const ensureGetDeviations = ensure({
  api: ApiClient.getDeviations,
  action: fetchBatchesAction,
  serializeSuccessPayload: (response) => {
    return serializeDeviationsInfo(response);
  },
  transformRequestData: generateFiltersTransformer(FILTER_ENTITY_TYPES.CERTIFICATES_WEEKLY_DEVIATIONS),
});

export const ensureGetCertificatesWelfare = ensure({
  api: ApiClient.getCertificatesWelfare,
  action: fetchBatchesAction,
  serializeSuccessPayload: (response) => {
    return serializeCertificatesWelfare(response);
  },
  transformRequestData: generateFiltersTransformer(FILTER_ENTITY_TYPES.CERTIFICATES_WELFARE),
});

export const ensureGetCertificatesIKB = ensure({
  api: ApiClient.getCertificatesIKB,
  action: fetchBatchesAction,
  serializeSuccessPayload: (response) => {
    return serializeCertificatesIKB(response);
  },
  transformRequestData: generateFiltersTransformer(FILTER_ENTITY_TYPES.CERTIFICATES_IKB),
});

export const ensureGetInvoiceMessages = ensure({
  api: ApiClient.getInvoiceMessages,
  action: fetchBatchesAction,
  serializeSuccessPayload: (response) => {
    return serializeInvoiceMessagesInfo(response);
  },
  transformRequestData: generateFiltersTransformer(FILTER_ENTITY_TYPES.INVOICE_MESSAGES),
});
export const ensureGetInvoiceDetails = ensure({
  api: ApiClient.getInvoiceDetails,
  action: fetchBatchesAction,
  serializeSuccessPayload: (response) => {
    return serializeInvoiceDetailsInfo(response);
  },
  transformRequestData: generateFiltersTransformer(FILTER_ENTITY_TYPES.INVOICE_DETAILS),
});

export const ensureGetOrganizationPassports = ensure({
  api: ApiClient.getUserPassports,
  action: fetchBatchesAction,
  serializeSuccessPayload: (response: ResourcesWrapper<UserPassportPayload>) => {
    const transformedRequestAlike: ResourcesWrapper<UserPassportTransformedPayload> = {
      ...response,
      data: {
        ...response.data,
        resources: response.data.resources
          .map(({ passport_owners, ...org }) =>
            passport_owners.map(({ name, user_type, email }) => ({
              ...org,
              user_type,
              user_name: name,
              user_email: email,
            })),
          )
          .flat(1),
      },
    };

    return serializeUserPassports(transformedRequestAlike);
  },
});

export const ensureGetSimpleUserOrganizationPassports = ensure({
  api: ApiClient.getUserPassports,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializeUserPassports,
});

export const ensureGetAdminOrganizationPassports = ensure({
  api: ApiClient.getAdminUserPassport,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializeUserPassports,
});

export const ensureGetAdminSuppliers = ensure({
  api: ApiClient.getAdminSuppliers,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializeUserPassports,
});

export const ensureGetMovementEvents = ensure({
  api: ApiClient.getMovementEvents,
  action: fetchBatchesAction,
  serializeSuccessPayload: (response) => {
    return serializeMovementEvents(response);
  },
  transformRequestData: generateFiltersTransformer(FILTER_ENTITY_TYPES.MOVEMENT_EVENTS),
});

export const ensureGetVeterinarianData = ensure({
  api: ApiClient.getVeterinarianData,
  action: fetchBatchesAction,
  serializeSuccessPayload: (response) => {
    return serializeVeterinarianData(response);
  },
  transformRequestData: generateFiltersTransformer(FILTER_ENTITY_TYPES.VETERINARIAN_DATA),
});

export const ensureGetHistory = ensure({
  api: ApiClient.getHistory,
  action: fetchBatchesAction,
  serializeSuccessPayload: (response) => {
    return serializeHistoryInfo(response);
  },
});

export const ensureGetGenetic = ensure({
  api: ApiClient.getGenetics,
  action: fetchBatchesAction,
  serializeSuccessPayload: (response) => {
    return serializeGenetic(response);
  },
  transformRequestData: generateFiltersTransformer(FILTER_ENTITY_TYPES.GENETICS),
});

export const ensureGetRawMaterialsOrigin = ensure({
  api: ApiClient.getRawMaterialsOrigin,
  action: fetchBatchesAction,
  serializeSuccessPayload: (response) => serializeRawMaterialsOrigins(response),
  transformRequestData: generateFiltersTransformer(FILTER_ENTITY_TYPES.RAW_MATERIALS_ORIGIN),
});

export const ensureGetAdminRawMaterialsOrigin = ensure({
  api: ApiClient.getAdminRawMaterialsOrigin,
  action: fetchBatchesAction,
  serializeSuccessPayload: (response) => serializeRawMaterialsOrigins(response),
  transformRequestData: generateFiltersTransformer(FILTER_ENTITY_TYPES.RAW_MATERIALS_ORIGIN),
});

export const ensureGetAdminOrganizationList = ensure({
  api: ApiClient.getAdminOrganizationList,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializeAdminOrganizations,
});

export const ensureGetOrganizationList = ensure({
  api: ApiClient.getOrganizationList,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializeAdminOrganizations,
});

export const ensureGetAdminUsers = ensure({
  api: ApiClient.getAdminUsers,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializeAdminUsers,
});

export const ensureGetAdminConcepts = ensure({
  api: ApiClient.getAdminConcepts,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializeAdminConcepts,
});

export const ensureGetAdminUpdateLogs = ensure({
  api: ApiClient.getAdminUpdateLogs,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializeAdminUpdateLogs,
});

export const ensureGetPermissionProfileTemplates = ensure({
  api: ApiClient.adminPermissionProfileTemplates,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializePermissionProfileTemplates,
});

export const ensureGetCertificatesGrouped = ensure({
  api: ApiClient.getCertificatesGrouped,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializeCertificatesGrouped,
  transformRequestData: generateFiltersTransformer(FILTER_ENTITY_TYPES.CERTIFICATES_ROOT),
});

export const ensureGetIkbGrouped = ensure({
  api: ApiClient.getIkbGrouped,
  action: fetchBatchesAction,
  serializeSuccessPayload: (serverData: ResourcesWrapper<ValidityGroupedRaw>) => ({
    IkbGrouped: serializeValidityGrouped(serverData).ValidityGrouped,
  }),
  transformRequestData: generateFiltersTransformer(FILTER_ENTITY_TYPES.CERTIFICATES_ROOT),
});

export const ensureGetWelfareGrouped = ensure({
  api: ApiClient.getWelfareGrouped,
  action: fetchBatchesAction,
  serializeSuccessPayload: (serverData: ResourcesWrapper<ValidityGroupedRaw>) => ({
    WelfareGrouped: serializeValidityGrouped(serverData).ValidityGrouped,
  }),
  transformRequestData: generateFiltersTransformer(FILTER_ENTITY_TYPES.CERTIFICATES_ROOT),
});

export const ensureGetAnimalPassport = ensure({
  api: ApiClient.getAnimalPassports,
  action: fetchBatchAction,
  serializeSuccessPayload: serializeAnimalPassports,
  transformRequestData: generateFiltersTransformer(FILTER_ENTITY_TYPES.ANIMAL_PASSPORTS),
});

export const ensureGetCompanyReport = ensure({
  api: ApiClient.getCompanyReport,
  action: fetchBatchAction,
  serializeSuccessPayload: serializeCompanyReport,
});

export const ensureGetAdminCompanyReport = ensure({
  api: ApiClient.adminGetCompanyReport,
  action: fetchBatchAction,
  serializeSuccessPayload: serializeCompanyReport,
});

export const ensureGetCompanyReports = ensure({
  api: ApiClient.getCompanyReports,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializeCompanyReports,
  transformRequestData: generateFiltersTransformer(FILTER_ENTITY_TYPES.COMPANY_REPORT_FILTER),
});

export const ensureGetAdminCompanyReports = ensure({
  api: ApiClient.adminGetCompanyReports,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializeCompanyReports,
  transformRequestData: generateFiltersTransformer(FILTER_ENTITY_TYPES.COMPANY_REPORT_FILTER),
});

export const ensureGetByProductReport = ensure({
  api: ApiClient.getByProductReport,
  action: fetchBatchAction,
  serializeSuccessPayload: serializeByProduct,
});

export const ensureGetAdminByProductReport = ensure({
  api: ApiClient.adminGetByProductReport,
  action: fetchBatchAction,
  serializeSuccessPayload: serializeByProduct,
});

export const ensureGetByProductReports = ensure({
  api: ApiClient.getByProductReports,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializeByProductReports,
  transformRequestData: generateFiltersTransformer(FILTER_ENTITY_TYPES.FARMER_INPUT),
});

export const ensureGetAdminByProductReports = ensure({
  api: ApiClient.adminGetByProductReports,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializeByProductReports,
  transformRequestData: generateFiltersTransformer(FILTER_ENTITY_TYPES.FARMER_INPUT),
});

export const ensureGetFermentationReport = ensure({
  api: ApiClient.getFermentationReport,
  action: fetchBatchAction,
  serializeSuccessPayload: serializeFermentationReport,
});

export const ensureGetAdminFermentationReport = ensure({
  api: ApiClient.adminGetFermentationReport,
  action: fetchBatchAction,
  serializeSuccessPayload: serializeFermentationReport,
});

export const ensureGetFermentationReports = ensure({
  api: ApiClient.getFermentationReports,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializeFermentationReports,
  transformRequestData: generateFiltersTransformer(FILTER_ENTITY_TYPES.FARMER_INPUT),
});

export const ensureGetAdminFermentationReports = ensure({
  api: ApiClient.adminGetFermentationReports,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializeFermentationReports,
  transformRequestData: generateFiltersTransformer(FILTER_ENTITY_TYPES.FARMER_INPUT),
});

export const ensureGetManureReport = ensure({
  api: ApiClient.getManureReport,
  action: fetchBatchAction,
  serializeSuccessPayload: serializeManureReport,
});

export const ensureGetAdminManureReport = ensure({
  api: ApiClient.adminGetManureReport,
  action: fetchBatchAction,
  serializeSuccessPayload: serializeManureReport,
});

export const ensureGetManureReports = ensure({
  api: ApiClient.getManureReports,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializeManureReports,
  transformRequestData: generateFiltersTransformer(FILTER_ENTITY_TYPES.FARMER_INPUT),
});

export const ensureGetAdminManureReports = ensure({
  api: ApiClient.adminGetManureReports,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializeManureReports,
  transformRequestData: generateFiltersTransformer(FILTER_ENTITY_TYPES.FARMER_INPUT),
});

export const ensureGetMyJoindata = ensure({
  api: ApiClient.getMyParticipations,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializeJoindataParticipations,
});

export const ensureGetAdminParticipations = ensure({
  api: ApiClient.adminGetParticipations,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializeJoindataParticipations,
});

export const ensureGetAdminRequestedParticipations = ensure({
  api: ApiClient.adminGetRequestedParticipations,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializeJoindataRequestedParticipations,
});

export const ensureGetUnmatchedOrganizations = ensure({
  api: ApiClient.adminGetUnmatchedOrganizations,
  action: fetchBatchesAction,
  serializeSuccessPayload: (response) => {
    const { [AdminOrganizationsSerialized]: serializedResources } = serializeAdminOrganizations(response);
    return { [UnmatchedOrganizationsSerialized.UnmatchedOrganizations]: serializedResources };
  },
});

export const ensureGetTransports = ensure({
  api: ApiClient.getTransports,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializeTransports,
  transformRequestData: generateFiltersTransformer(FILTER_ENTITY_TYPES.TRANSPORTS),
});

export const ensureGetTransportLoadings = ensure({
  api: ApiClient.getTransportLoadings,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializeTransportLoadings,
});

export const ensureGetTransportUnloadings = ensure({
  api: ApiClient.getTransportUnloadings,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializeTransportUnloadings,
});

export const ensureGetFarmerInputUpdatesLog = ensure({
  api: ApiClient.adminGetFarmerInputUpdatesLog,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializeFarmerInputUpdatesLog,
});

// reference tables
export const ensureGetAdminReferenceTablesDashboard = ensure({
  api: ApiClient.adminGetReferenceTablesDashboard,
  action: fetchBatchAction,
  serializeSuccessPayload: serializeReferenceTablesDashboard,
});

export const ensureGetAdminManageUploads = ensure({
  api: ApiClient.adminGetManageUploads,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializeManageUploads,
  transformRequestData: generateFiltersTransformer(FILTER_ENTITY_TYPES.REFERENCE_TABLES),
});

export const ensureGetAdminCarbonFootprints = ensure({
  api: ApiClient.adminGetCarbonFootprints,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializeCarbonFootprints,
  transformRequestData: generateFiltersTransformer(FILTER_ENTITY_TYPES.CARBON_FOOTPRINT),
});

export const ensureGetCarbonFootprints = ensure({
  api: ApiClient.getCarbonFootprints,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializeCarbonFootprints,
  transformRequestData: generateFiltersTransformer(FILTER_ENTITY_TYPES.CARBON_FOOTPRINT),
});

export const ensureGetAdminCarbonFootprint = ensure({
  api: ApiClient.adminGetCarbonFootprint,
  action: fetchBatchAction,
  serializeSuccessPayload: serializeCarbonFootprint,
});

export const ensureGetAdminHFMOutputs = ensure({
  api: ApiClient.adminGetHFMOutputs,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializeHfmOutputs,
  transformRequestData: generateFiltersTransformer(FILTER_ENTITY_TYPES.HFM_OUTPUT),
});

export const ensureGetAdminHFMOutput = ensure({
  api: ApiClient.adminGetHFMOutput,
  action: fetchBatchAction,
  serializeSuccessPayload: serializeHfmOutput,
});

export const ensureGetCarbonFootprint = ensure({
  api: ApiClient.getCarbonFootprint,
  action: fetchBatchAction,
  serializeSuccessPayload: serializeCarbonFootprint,
});

export const ensureGetAdminPenLevels = ensure({
  api: ApiClient.adminGetPenLevels,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializePenLevels,
});

export const ensureGetAdminPensTypeSowBarn = ensure({
  api: ApiClient.adminGetPensTypeSowBarn,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializePensTypeSowBarn,
});

export const ensureGetAdminYesNo = ensure({
  api: ApiClient.adminGetYesNo,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializeYesNo,
});

export const ensureGetAdminCfpCo2EmissionFactors = ensure({
  api: ApiClient.adminGetCfpCo2EmissionFactors,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializeCfpCo2EmissionFactor,
});

export const ensureGetAdminCvbTable = ensure({
  api: ApiClient.adminGetCvbTable,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializeCvbTable,
});

export const ensureGetAdminIpccEmissionFactors = ensure({
  api: ApiClient.adminGetIpccEmissionFactors,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializeIpccEmissionFactors,
});

export const ensureGetAdminCfpGfliFeedprintTable = ensure({
  api: ApiClient.adminGetcfpGfliFeedprintTable,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializeCfpGfliFeedprintTableFactor,
});

export const ensureGetAdminContentOfNitricOxide = ensure({
  api: ApiClient.adminGetContentOfNitricOxide,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializeContentOfNitricOxide,
});

export const ensureGetAdminPigHousingEmissionFactors = ensure({
  api: ApiClient.adminGetPigHousingEmissionFactors,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializePigHousingEmissionFactors,
});

export const ensureGetAdminElectricityConsumptionRates = ensure({
  api: ApiClient.adminGetElectricityConsumptionRates,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializeElectricityConsumptionRates,
});

export const ensureGetAdminGasConsumptionRates = ensure({
  api: ApiClient.adminGetGasConsumptionRates,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializeGasConsumptionRates,
});

export const ensureGetAdminWaterConsumptionRates = ensure({
  api: ApiClient.adminGetWaterConsumptionRates,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializeWaterConsumptionRates,
});

export const ensureGetAdminRavCodes = ensure({
  api: ApiClient.adminGetRavCodes,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializeRavCodes,
});

export const ensureGetAdminGwp100Factors = ensure({
  api: ApiClient.adminGetGwp100Factors,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializeGwp100Factors,
});

export const ensureGetAdminWurMcfEfem = ensure({
  api: ApiClient.adminGetWurMfcEfem,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializeWurMcfEfem,
});

export const ensureGetAdminCl550 = ensure({
  api: ApiClient.adminGetCl550,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializeCl550,
});

export const ensureGetAdminCl649 = ensure({
  api: ApiClient.adminGetCl649,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializeCl649,
});

export const ensureGetAdminCl650 = ensure({
  api: ApiClient.adminGetCl650,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializeCl650,
});

export const ensureGetAdminCl607 = ensure({
  api: ApiClient.adminGetCl607,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializeCl607,
});

export const ensureGetAdminSuppliersNumbers = ensure({
  api: ApiClient.adminGetSuppliersNumbers,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializeSuppliersNumbers,
});

export const ensureGetAdminCountryCodes = ensure({
  api: ApiClient.adminGetCountryCodes,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializeCountryCodes,
});

export const ensureGetAdminRavReferenceList = ensure({
  api: ApiClient.adminGetRavReferenceList,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializeRavReferenceList,
});

export const ensureGetAdminAllocationManure = ensure({
  api: ApiClient.adminGetAllocationManure,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializeAllocationManure,
});

export const ensureGetAdminGreenhouseGasEmission = ensure({
  api: ApiClient.adminGetGreenhouseGasEmission,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializeGreenhouseGasEmission,
});

export const ensureGetAdminEntitiesList = ensure({
  api: ApiClient.adminGetEntitiesList,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializeEntitiesList,
});

// farm management
export const ensureGetAdminFarmGroups = ensure({
  api: ApiClient.adminGetFarmGroups,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializeGroupGroups,
  transformRequestData: generateFiltersTransformer(FILTER_ENTITY_TYPES.FARM_MANAGEMENT_CATEGORIES),
});

export const ensureGetAdminGroupWeights = ensure({
  api: ApiClient.adminGetGroupWeights,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializeGroupWeights,
  transformRequestData: generateFiltersTransformer(FILTER_ENTITY_TYPES.FARM_MANAGEMENT_CATEGORIES),
});

export const ensureGetAdminFarmManagementFtpUpdates = ensure({
  api: ApiClient.adminGetFarmManagementFtpUpdates,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializeFtpUpdates,
  transformRequestData: generateFiltersTransformer(FILTER_ENTITY_TYPES.FARM_MANAGEMENT_DATA),
});

export const ensureGetAdminGroupFinancialEvents = ensure({
  api: ApiClient.adminGetGroupFinancialEvents,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializeGroupFinancialEvents,
  transformRequestData: generateFiltersTransformer(FILTER_ENTITY_TYPES.FARM_MANAGEMENT_CATEGORIES),
});

export const ensureGetAdminGroupRemovals = ensure({
  api: ApiClient.adminGetGroupRemovals,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializeGroupRemovals,
  transformRequestData: generateFiltersTransformer(FILTER_ENTITY_TYPES.FARM_MANAGEMENT_CATEGORIES),
});

export const ensureGetAdminGroupArrival = ensure({
  api: ApiClient.adminGetGroupArrival,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializeGroupArrival,
  transformRequestData: generateFiltersTransformer(FILTER_ENTITY_TYPES.FARM_MANAGEMENT_CATEGORIES),
});

export const ensureGetAdminGroupMoves = ensure({
  api: ApiClient.adminGetGroupMoves,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializeGroupMoves,
  transformRequestData: generateFiltersTransformer(FILTER_ENTITY_TYPES.FARM_MANAGEMENT_CATEGORIES),
});

export const ensureGetAdminCodesAnimalRemarks = ensure({
  api: ApiClient.adminGetCodesAnimalRemarks,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializeCodesAnimalRemarks,
  transformRequestData: generateFiltersTransformer(FILTER_ENTITY_TYPES.FARM_MANAGEMENT_CATEGORIES),
});

export const ensureGetAdminCodesDefects = ensure({
  api: ApiClient.adminGetCodesDefects,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializeCodesDefects,
  transformRequestData: generateFiltersTransformer(FILTER_ENTITY_TYPES.FARM_MANAGEMENT_CATEGORIES),
});

export const ensureGetAdminCodesDiagnose = ensure({
  api: ApiClient.adminGetCodesDiagnose,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializeCodesDiagnose,
  transformRequestData: generateFiltersTransformer(FILTER_ENTITY_TYPES.FARM_MANAGEMENT_CATEGORIES),
});

export const ensureGetAdminCodesAnimalScore = ensure({
  api: ApiClient.adminGetCodesAnimalScore,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializeCodesAnimalScore,
  transformRequestData: generateFiltersTransformer(FILTER_ENTITY_TYPES.FARM_MANAGEMENT_CATEGORIES),
});

export const ensureGetAdminCodesInsemination = ensure({
  api: ApiClient.adminGetCodesInsemination,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializeCodesInsemination,
  transformRequestData: generateFiltersTransformer(FILTER_ENTITY_TYPES.FARM_MANAGEMENT_CATEGORIES),
});

export const ensureGetAdminCodesTreatments = ensure({
  api: ApiClient.adminGetCodesTreatments,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializeCodesTreatments,
  transformRequestData: generateFiltersTransformer(FILTER_ENTITY_TYPES.FARM_MANAGEMENT_CATEGORIES),
});

export const ensureGetAdminGroupPurchasedFood = ensure({
  api: ApiClient.adminGetGroupPurchasedFood,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializeGroupPurchasedFood,
  transformRequestData: generateFiltersTransformer(FILTER_ENTITY_TYPES.FARM_MANAGEMENT_CATEGORIES),
});

export const ensureGetAdminGroupUsedFood = ensure({
  api: ApiClient.adminGetGroupUsedFood,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializeGroupUsedFood,
  transformRequestData: generateFiltersTransformer(FILTER_ENTITY_TYPES.FARM_MANAGEMENT_CATEGORIES),
});

export const ensureGetAdminCodesMatingType = ensure({
  api: ApiClient.adminGetCodesMatingType,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializeCodesMatingType,
  transformRequestData: generateFiltersTransformer(FILTER_ENTITY_TYPES.FARM_MANAGEMENT_CATEGORIES),
});

export const ensureGetAdminCodesWeightType = ensure({
  api: ApiClient.adminGetCodesWeightType,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializeCodesWeightType,
  transformRequestData: generateFiltersTransformer(FILTER_ENTITY_TYPES.FARM_MANAGEMENT_CATEGORIES),
});

export const ensureGetAdminCodesRemovalType = ensure({
  api: ApiClient.adminGetCodesRemovalType,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializeCodesRemovalType,
  transformRequestData: generateFiltersTransformer(FILTER_ENTITY_TYPES.FARM_MANAGEMENT_CATEGORIES),
});

export const ensureGetAdminCodesAnimalIdentityType = ensure({
  api: ApiClient.adminGetCodesAnimalIdentityType,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializeCodesAnimalIdentityType,
  transformRequestData: generateFiltersTransformer(FILTER_ENTITY_TYPES.FARM_MANAGEMENT_CATEGORIES),
});

export const ensureGetAdminCodesFarmOption = ensure({
  api: ApiClient.adminGetCodesFarmOption,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializeCodesFarmOption,
  transformRequestData: generateFiltersTransformer(FILTER_ENTITY_TYPES.FARM_MANAGEMENT_CATEGORIES),
});

export const ensureGetAdminCodesBarn = ensure({
  api: ApiClient.adminGetCodesBarn,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializeCodesBarn,
  transformRequestData: generateFiltersTransformer(FILTER_ENTITY_TYPES.FARM_MANAGEMENT_CATEGORIES),
});

export const ensureGetAdminCodesRoom = ensure({
  api: ApiClient.adminGetCodesRoom,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializeCodesRoom,
  transformRequestData: generateFiltersTransformer(FILTER_ENTITY_TYPES.FARM_MANAGEMENT_CATEGORIES),
});

export const ensureGetAdminCodesPen = ensure({
  api: ApiClient.adminGetCodesPen,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializeCodesPen,
  transformRequestData: generateFiltersTransformer(FILTER_ENTITY_TYPES.FARM_MANAGEMENT_CATEGORIES),
});

export const ensureGetAdminCodesAccount = ensure({
  api: ApiClient.adminGetCodesAccount,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializeCodesAccount,
  transformRequestData: generateFiltersTransformer(FILTER_ENTITY_TYPES.FARM_MANAGEMENT_CATEGORIES),
});

export const ensureGetAdminCodesEventType = ensure({
  api: ApiClient.adminGetCodesEventType,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializeCodesEventTypes,
  transformRequestData: generateFiltersTransformer(FILTER_ENTITY_TYPES.FARM_MANAGEMENT_CATEGORIES),
});

export const ensureGetAdminGroupExpense = ensure({
  api: ApiClient.adminGetGroupExpense,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializeGroupExpense,
  transformRequestData: generateFiltersTransformer(FILTER_ENTITY_TYPES.FARM_MANAGEMENT_CATEGORIES),
});

//

export const ensureGetAdminFarmManagementPassports = ensure({
  api: ApiClient.adminGetFarmManagementPassports,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializeFarmManagementPassports,
});

export const ensureGetAdminAliases = ensure({
  api: ApiClient.adminGetPassportAliases,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializeAliases,
});

export const ensureGetAliases = ensure({
  api: ApiClient.getPassportAliases,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializeAliases,
});

export const ensureGetAdminLabels = ensure({
  api: ApiClient.adminGetLabels,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializeLabels,
});

export const ensureGetLabels = ensure({
  api: ApiClient.getLabels,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializeLabels,
});

export const ensureGetAdminLabel = ensure({
  api: ApiClient.adminGetLabel,
  action: fetchBatchAction,
  serializeSuccessPayload: serializeLabel,
});

export const ensureGetLabel = ensure({
  api: ApiClient.getLabel,
  action: fetchBatchAction,
  serializeSuccessPayload: serializeLabel,
});

export const ensureGetBatch = ensure({
  api: ApiClient.getBatch,
  action: fetchBatchAction,
});

export const serializeSuccessRequestStatistic = (
  response: ResourcesWrapper<{ request_count: number; application_name: string; requested_at: string }>,
) => ({
  requestStatistic: response.data.resources.map(
    (item: { request_count: number; application_name: string; requested_at: string }) => ({
      requestCount: item.request_count,
      applicationName: item.application_name,
      requestedAt: item.requested_at,
    }),
  ),
});

export const ensureAdminGetRequestStatistic = ensure({
  api: ApiClient.adminGetRequestStatistic,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializeSuccessRequestStatistic,
  transformRequestData: generateFiltersTransformer(FILTER_ENTITY_TYPES.MONITORING_DASHBOARD_STATISTIC),
});

export const ensureGetRequestStatistic = ensure({
  api: ApiClient.getRequestStatistic,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializeSuccessRequestStatistic,
  transformRequestData: generateFiltersTransformer(FILTER_ENTITY_TYPES.MONITORING_DASHBOARD_STATISTIC),
});

export const ensureAdminGetRequestLogsUsers = ensure({
  api: ApiClient.adminGetRequestLogsUsers,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializeRequestLogUsers,
  transformRequestData: generateFiltersTransformer(FILTER_ENTITY_TYPES.MONITORING_DASHBOARD_STATISTIC),
});

export const ensureGetRequestLogsUsers = ensure({
  api: ApiClient.getRequestLogsUsers,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializeRequestLogUsers,
  transformRequestData: generateFiltersTransformer(FILTER_ENTITY_TYPES.MONITORING_DASHBOARD_STATISTIC),
});

export const ensureGetDataStatistics = ensure({
  api: ApiClient.getDataStatistics,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializeDataStatistics,
  transformRequestData: generateFiltersTransformer(FILTER_ENTITY_TYPES.MONITORING_DASHBOARD_DATA),
});

export const ensureGetAdminErrorsLog = ensure({
  api: ApiClient.adminGetErrorsLog,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializeErrorsLog,
  transformRequestData: generateFiltersTransformer(FILTER_ENTITY_TYPES.ERRORS_LOG),
});

function* getBatches({ payload }: ObjectWithProps) {
  const { ensure, filters, params, ...rest } = payload;

  yield call(ensure, { filters, params, ...rest });
}

function* getBatch({ payload }: ObjectWithProps) {
  const { ensure, filters, params, ...rest } = payload;

  yield race({
    task: call(ensure, { filters, params, ...rest }),
    cancel: take(clearResourceAction.type),
  });
}

export default function* () {
  yield takeEvery(fetchBatchesAction.request, getBatches);
  yield takeLatest(fetchBatchAction.request, getBatch);
}
