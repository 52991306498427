import { IntlKeys } from 'lib/localization/keys';

enum ContentOfNitricOxideSerialized {
  ContentOfNitricOxide = 'ContentOfNitricOxide',
}

enum ContentOfNitricOxideUnserialized {
  ContentOfNitricOxide = 'ContentOfNitricOxide',
}

export enum ContentOfNitricOxideSerializedHeaders {
  id = 'id',
  referenceUploadID = 'referenceUploadID',
  livestockCategory = 'livestockCategory',
  manureType = 'manureType',
  valueN2oAndNo = 'valueN2oAndNo',
  unitN2oAndNo = 'unitN2oAndNo',
  valueN2 = 'valueN2',
  unitN2 = 'unitN2',
  uploadType = 'uploadType',
  filename = 'filename',
  status = 'status',
  validDateFrom = 'validDateFrom',
  validDateTo = 'validDateTo',
  notes = 'notes',
  concepts = 'concepts',
  uploadedAt = 'uploadedAt',
  uploadedBy = 'uploadedBy',
}

export const ContentOfNitricOxideUnserializedHeaders = {
  id: 'id',
  referenceUploadID: ['content_of_nitric_oxide_upload', 'id'],
  livestockCategory: 'livestock_category',
  manureType: 'manure_type',
  valueN2oAndNo: 'value_n2o_and_no',
  unitN2oAndNo: 'unit_n2o_and_no',
  valueN2: 'value_n2',
  unitN2: 'unit_n2',
  uploadType: ['content_of_nitric_oxide_upload', 'upload_type'],
  filename: ['content_of_nitric_oxide_upload', 'file_name'],
  status: ['content_of_nitric_oxide_upload', 'status'],
  validDateFrom: ['content_of_nitric_oxide_upload', 'valid_from'],
  validDateTo: ['content_of_nitric_oxide_upload', 'valid_to'],
  notes: ['content_of_nitric_oxide_upload', 'notes'],
  concepts: ['content_of_nitric_oxide_upload', 'concepts'],
  uploadedAt: ['content_of_nitric_oxide_upload', 'uploaded_at'],
  uploadedBy: ['content_of_nitric_oxide_upload', 'uploaded_by', 'full_name'],
} as const;

export const CONTENT_OF_NITRIC_OXIDE = {
  [ContentOfNitricOxideSerialized.ContentOfNitricOxide]: {
    serialized: ContentOfNitricOxideSerialized.ContentOfNitricOxide,
    unserialized: ContentOfNitricOxideUnserialized.ContentOfNitricOxide,
    view: IntlKeys.referenceTablesContentOfNitricOxide,

    columns: {
      [ContentOfNitricOxideSerializedHeaders.id]: {
        serialized: ContentOfNitricOxideSerializedHeaders.id,
        unserialized: ContentOfNitricOxideUnserializedHeaders.id,
      },
      [ContentOfNitricOxideSerializedHeaders.referenceUploadID]: {
        serialized: ContentOfNitricOxideSerializedHeaders.referenceUploadID,
        unserialized: ContentOfNitricOxideUnserializedHeaders.referenceUploadID,
      },
      [ContentOfNitricOxideSerializedHeaders.livestockCategory]: {
        serialized: ContentOfNitricOxideSerializedHeaders.livestockCategory,
        unserialized: ContentOfNitricOxideUnserializedHeaders.livestockCategory,
        view: IntlKeys.referenceTablesContentOfNitricOxideCategory,
      },
      [ContentOfNitricOxideSerializedHeaders.manureType]: {
        serialized: ContentOfNitricOxideSerializedHeaders.manureType,
        unserialized: ContentOfNitricOxideUnserializedHeaders.manureType,
        view: IntlKeys.referenceTablesContentOfNitricOxideType,
      },
      [ContentOfNitricOxideSerializedHeaders.valueN2oAndNo]: {
        serialized: ContentOfNitricOxideSerializedHeaders.valueN2oAndNo,
        unserialized: ContentOfNitricOxideUnserializedHeaders.valueN2oAndNo,
        view: IntlKeys.referenceTablesContentOfNitricOxideValueN2oNo,
      },
      [ContentOfNitricOxideSerializedHeaders.unitN2oAndNo]: {
        serialized: ContentOfNitricOxideSerializedHeaders.unitN2oAndNo,
        unserialized: ContentOfNitricOxideUnserializedHeaders.unitN2oAndNo,
        view: IntlKeys.referenceTablesContentOfNitricOxideUnitN2oNo,
      },
      [ContentOfNitricOxideSerializedHeaders.valueN2]: {
        serialized: ContentOfNitricOxideSerializedHeaders.valueN2,
        unserialized: ContentOfNitricOxideUnserializedHeaders.valueN2,
        view: IntlKeys.referenceTablesContentOfNitricOxideValueN2,
      },
      [ContentOfNitricOxideSerializedHeaders.unitN2]: {
        serialized: ContentOfNitricOxideSerializedHeaders.unitN2,
        unserialized: ContentOfNitricOxideUnserializedHeaders.unitN2,
        view: IntlKeys.referenceTablesContentOfNitricOxideUnitN2,
      },
      [ContentOfNitricOxideSerializedHeaders.status]: {
        serialized: ContentOfNitricOxideSerializedHeaders.status,
        unserialized: ContentOfNitricOxideUnserializedHeaders.status,
      },
      [ContentOfNitricOxideSerializedHeaders.uploadType]: {
        serialized: ContentOfNitricOxideSerializedHeaders.uploadType,
        unserialized: ContentOfNitricOxideUnserializedHeaders.uploadType,
      },
      [ContentOfNitricOxideSerializedHeaders.notes]: {
        serialized: ContentOfNitricOxideSerializedHeaders.notes,
        unserialized: ContentOfNitricOxideUnserializedHeaders.notes,
      },
      [ContentOfNitricOxideSerializedHeaders.concepts]: {
        serialized: ContentOfNitricOxideSerializedHeaders.concepts,
        unserialized: ContentOfNitricOxideUnserializedHeaders.concepts,
        view: IntlKeys.concepts,
      },
      [ContentOfNitricOxideSerializedHeaders.filename]: {
        serialized: ContentOfNitricOxideSerializedHeaders.filename,
        unserialized: ContentOfNitricOxideUnserializedHeaders.filename,
      },
      [ContentOfNitricOxideSerializedHeaders.validDateFrom]: {
        serialized: ContentOfNitricOxideSerializedHeaders.validDateFrom,
        unserialized: ContentOfNitricOxideUnserializedHeaders.validDateFrom,
      },
      [ContentOfNitricOxideSerializedHeaders.validDateTo]: {
        serialized: ContentOfNitricOxideSerializedHeaders.validDateTo,
        unserialized: ContentOfNitricOxideUnserializedHeaders.validDateTo,
      },
      [ContentOfNitricOxideSerializedHeaders.uploadedAt]: {
        serialized: ContentOfNitricOxideSerializedHeaders.uploadedAt,
        unserialized: ContentOfNitricOxideUnserializedHeaders.uploadedAt,
      },
      [ContentOfNitricOxideSerializedHeaders.uploadedBy]: {
        serialized: ContentOfNitricOxideSerializedHeaders.uploadedBy,
        unserialized: ContentOfNitricOxideUnserializedHeaders.uploadedBy,
      },
    },
  },
};
