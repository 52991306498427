import { IntlKeys } from 'lib/localization/keys';

enum CarbonFootprintSerialized {
  CarbonFootprint = 'CarbonFootprint',
  CarbonFootprintsSubmit = 'CarbonFootprintsSubmit',
}

enum CarbonFootprintUnserialized {
  CarbonFootprint = 'CarbonFootprint',
  CarbonFootprintsSubmit = 'CarbonFootprintsSubmit',
}

export enum CarbonFootprintSerializedHeaders {
  id = 'id',
  ubn = 'ubn',
  kvk = 'kvk',
  kvkName = 'kvkName',
  companyType = 'companyType',
  cultivationAndProductionOfFeedBreeder = 'cultivationAndProductionOfFeedBreeder',
  transportOfFeedBreeder = 'transportOfFeedBreeder',
  manureStorageOfFeedBreeder = 'manureStorageOfFeedBreeder',
  manureTransportOfFeedBreeder = 'manureTransportOfFeedBreeder',
  entericFermentationBreeder = 'entericFermentationBreeder',
  energyUseBreeder = 'energyUseBreeder',
  breedingTotal = 'breedingTotal',
  breedingPerPiglet = 'breedingPerPiglet',
  sowsValue = 'sowsValue',
  pigletsValue = 'pigletsValue',
  sows = 'sows',
  piglets = 'piglets',
  economicAllocationTotalEuro = 'economicAllocationTotalEuro',
  economicAllocationTotalKg = 'economicAllocationTotalKg',
  pigletsFattener = 'pigletsFattener',
  transportPigletsFattener = 'transportPigletsFattener',
  cultivationAndProductionOfFeedFattener = 'cultivationAndProductionOfFeedFattener',
  transportOfFeedFattener = 'transportOfFeedFattener',
  manureStorageOfFeedFattener = 'manureStorageOfFeedFattener',
  manureTransportOfFeedFattener = 'manureTransportOfFeedFattener',
  entericFermentationFattener = 'entericFermentationFattener',
  energyUseFattener = 'energyUseFattener',
  fatteningTotal = 'fatteningTotal',
  perKgOfLiveWeight = 'perKgOfLiveWeight',
  perKgOfLiveWeightAverage = 'perKgOfLiveWeightAverage',
  status = 'status',
  validDateFrom = 'validDateFrom',
  validDateTo = 'validDateTo',
  notes = 'notes',
  uploadedAt = 'uploadedAt',
  fileName = 'fileName',
  uploadedBy = 'uploadedBy',
}

export const CarbonFootprintUnserializedHeaders = {
  id: 'id',
  ubn: 'ubn',
  kvk: 'kvk',
  kvkName: 'kvk_name',
  companyType: 'company_type',
  cultivationAndProductionOfFeedBreeder: ['breeding_total', 'cultivation_and_production_of_feed_co2_breeder'],
  transportOfFeedBreeder: ['breeding_total', 'transport_of_feed_co2_breeder'],
  manureStorageOfFeedBreeder: ['breeding_total', 'manure_storage_of_feed_co2_breeder'],
  manureTransportOfFeedBreeder: ['breeding_total', 'manure_transport_of_feed_co2_breeder'],
  entericFermentationBreeder: ['breeding_total', 'enteric_fermentation_co2_breeder'],
  energyUseBreeder: ['breeding_total', 'energy_use_co2_breeder'],
  breedingTotal: ['breeding_total', 'total'],
  breedingPerPiglet: ['breeding_total', 'per_piglet'],
  sowsValue: ['economic_allocation_total', 'sows_value'],
  pigletsValue: ['economic_allocation_total', 'piglets_value'],
  sows: ['economic_allocation_total', 'sows_co2'],
  piglets: ['economic_allocation_total', 'piglets_co2'],
  economicAllocationTotalEuro: ['economic_allocation_total', 'total_euro_value'],
  economicAllocationTotalKg: ['economic_allocation_total', 'total_kg_value'],
  pigletsFattener: ['fattening_total', 'piglets_co2'],
  transportPigletsFattener: ['fattening_total', 'transport_piglets_co2_fattener'],
  cultivationAndProductionOfFeedFattener: ['fattening_total', 'cultivation_and_production_of_feed_co2_fattener'],
  transportOfFeedFattener: ['fattening_total', 'transport_of_feed_co2_fattener'],
  manureStorageOfFeedFattener: ['fattening_total', 'manure_storage_of_feed_co2_fattener'],
  manureTransportOfFeedFattener: ['fattening_total', 'manure_transport_of_feed_co2_fattener'],
  entericFermentationFattener: ['fattening_total', 'enteric_fermentation_co2_fattener'],
  energyUseFattener: ['fattening_total', 'energy_use_co2_fattener'],
  fatteningTotal: ['fattening_total', 'total'],
  perKgOfLiveWeight: 'co2_per_kg_of_live_weight',
  perKgOfLiveWeightAverage: 'co2_per_kg_of_live_weight_average',
  status: 'status',
  validDateFrom: 'valid_from',
  validDateTo: 'valid_to',
  notes: 'notes',
  uploadedAt: 'uploaded_at',
  fileName: 'file_name',
  uploadedBy: ['uploaded_by', 'full_name'],
} as const;

export enum CarbonFootprintsSubmitSerializedHeaders {
  id = 'id',
  status = 'status',
  validDateFrom = 'validDateFrom',
  validDateTo = 'validDateTo',
  notes = 'notes',
}

export const CarbonFootprintsSubmitUnserializedHeaders = {
  id: 'id',
  status: 'status',
  validDateFrom: 'valid_from',
  validDateTo: 'valid_to',
  notes: 'notes',
} as const;

export const CARBON_FOOTPRINTS = {
  [CarbonFootprintSerialized.CarbonFootprint]: {
    serialized: CarbonFootprintSerialized.CarbonFootprint,
    unserialized: CarbonFootprintUnserialized.CarbonFootprint,
    view: IntlKeys.titleFarmsList,

    columns: {
      [CarbonFootprintSerializedHeaders.id]: {
        serialized: CarbonFootprintSerializedHeaders.id,
        unserialized: CarbonFootprintUnserializedHeaders.id,
        view: IntlKeys.carbonFootprintId,
      },
      [CarbonFootprintSerializedHeaders.ubn]: {
        serialized: CarbonFootprintSerializedHeaders.ubn,
        unserialized: CarbonFootprintUnserializedHeaders.ubn,
        view: IntlKeys.carbonFootprintUbn,
      },
      [CarbonFootprintSerializedHeaders.kvk]: {
        serialized: CarbonFootprintSerializedHeaders.kvk,
        unserialized: CarbonFootprintUnserializedHeaders.kvk,
        view: IntlKeys.carbonFootprintKvk,
      },
      [CarbonFootprintSerializedHeaders.kvkName]: {
        serialized: CarbonFootprintSerializedHeaders.kvkName,
        unserialized: CarbonFootprintUnserializedHeaders.kvkName,
        view: IntlKeys.carbonFootprintKvkName,
      },
      [CarbonFootprintSerializedHeaders.companyType]: {
        serialized: CarbonFootprintSerializedHeaders.companyType,
        unserialized: CarbonFootprintUnserializedHeaders.companyType,
        view: IntlKeys.carbonFootprintCompanyType,
      },
      [CarbonFootprintSerializedHeaders.cultivationAndProductionOfFeedBreeder]: {
        serialized: CarbonFootprintSerializedHeaders.cultivationAndProductionOfFeedBreeder,
        unserialized: CarbonFootprintUnserializedHeaders.cultivationAndProductionOfFeedBreeder,
        view: IntlKeys.carbonFootprintCultivationAndProductionOfFeedCo2Breeder,
      },
      [CarbonFootprintSerializedHeaders.transportOfFeedBreeder]: {
        serialized: CarbonFootprintSerializedHeaders.transportOfFeedBreeder,
        unserialized: CarbonFootprintUnserializedHeaders.transportOfFeedBreeder,
        view: IntlKeys.carbonFootprintTransportOfFeedCo2Breeder,
      },
      [CarbonFootprintSerializedHeaders.manureStorageOfFeedBreeder]: {
        serialized: CarbonFootprintSerializedHeaders.manureStorageOfFeedBreeder,
        unserialized: CarbonFootprintUnserializedHeaders.manureStorageOfFeedBreeder,
        view: IntlKeys.carbonFootprintManureStorageOfFeedCo2Breeder,
      },
      [CarbonFootprintSerializedHeaders.manureTransportOfFeedBreeder]: {
        serialized: CarbonFootprintSerializedHeaders.manureTransportOfFeedBreeder,
        unserialized: CarbonFootprintUnserializedHeaders.manureTransportOfFeedBreeder,
        view: IntlKeys.carbonFootprintManureTransportOfFeedCo2Breeder,
      },
      [CarbonFootprintSerializedHeaders.entericFermentationBreeder]: {
        serialized: CarbonFootprintSerializedHeaders.entericFermentationBreeder,
        unserialized: CarbonFootprintUnserializedHeaders.entericFermentationBreeder,
        view: IntlKeys.carbonFootprintEntericFermentationCo2Breeder,
      },
      [CarbonFootprintSerializedHeaders.energyUseBreeder]: {
        serialized: CarbonFootprintSerializedHeaders.energyUseBreeder,
        unserialized: CarbonFootprintUnserializedHeaders.energyUseBreeder,
        view: IntlKeys.carbonFootprintEnergyUseCo2Breeder,
      },
      [CarbonFootprintSerializedHeaders.breedingTotal]: {
        serialized: CarbonFootprintSerializedHeaders.breedingTotal,
        unserialized: CarbonFootprintUnserializedHeaders.breedingTotal,
      },
      [CarbonFootprintSerializedHeaders.breedingPerPiglet]: {
        serialized: CarbonFootprintSerializedHeaders.breedingPerPiglet,
        unserialized: CarbonFootprintUnserializedHeaders.breedingPerPiglet,
      },
      [CarbonFootprintSerializedHeaders.sowsValue]: {
        serialized: CarbonFootprintSerializedHeaders.sowsValue,
        unserialized: CarbonFootprintUnserializedHeaders.sowsValue,
        view: IntlKeys.carbonFootprintSowsValue,
      },
      [CarbonFootprintSerializedHeaders.pigletsValue]: {
        serialized: CarbonFootprintSerializedHeaders.pigletsValue,
        unserialized: CarbonFootprintUnserializedHeaders.pigletsValue,
        view: IntlKeys.carbonFootprintPigletsValue,
      },
      [CarbonFootprintSerializedHeaders.sows]: {
        serialized: CarbonFootprintSerializedHeaders.sows,
        unserialized: CarbonFootprintUnserializedHeaders.sows,
        view: IntlKeys.carbonFootprintSowsCo2,
      },
      [CarbonFootprintSerializedHeaders.piglets]: {
        serialized: CarbonFootprintSerializedHeaders.piglets,
        unserialized: CarbonFootprintUnserializedHeaders.piglets,
        view: IntlKeys.carbonFootprintPigletsCo2,
      },
      [CarbonFootprintSerializedHeaders.economicAllocationTotalEuro]: {
        serialized: CarbonFootprintSerializedHeaders.economicAllocationTotalEuro,
        unserialized: CarbonFootprintUnserializedHeaders.economicAllocationTotalEuro,
      },
      [CarbonFootprintSerializedHeaders.economicAllocationTotalKg]: {
        serialized: CarbonFootprintSerializedHeaders.economicAllocationTotalKg,
        unserialized: CarbonFootprintUnserializedHeaders.economicAllocationTotalKg,
      },
      [CarbonFootprintSerializedHeaders.pigletsFattener]: {
        serialized: CarbonFootprintSerializedHeaders.pigletsFattener,
        unserialized: CarbonFootprintUnserializedHeaders.pigletsFattener,
      },
      [CarbonFootprintSerializedHeaders.transportPigletsFattener]: {
        serialized: CarbonFootprintSerializedHeaders.transportPigletsFattener,
        unserialized: CarbonFootprintUnserializedHeaders.transportPigletsFattener,
        view: IntlKeys.carbonFootprintTransportPigletsCo2Fattener,
      },
      [CarbonFootprintSerializedHeaders.cultivationAndProductionOfFeedFattener]: {
        serialized: CarbonFootprintSerializedHeaders.cultivationAndProductionOfFeedFattener,
        unserialized: CarbonFootprintUnserializedHeaders.cultivationAndProductionOfFeedFattener,
        view: IntlKeys.carbonFootprintCultivationAndProductionOfFeedCo2Fattener,
      },
      [CarbonFootprintSerializedHeaders.transportOfFeedFattener]: {
        serialized: CarbonFootprintSerializedHeaders.transportOfFeedFattener,
        unserialized: CarbonFootprintUnserializedHeaders.transportOfFeedFattener,
        view: IntlKeys.carbonFootprintTransportOfFeedCo2Fattener,
      },
      [CarbonFootprintSerializedHeaders.manureStorageOfFeedFattener]: {
        serialized: CarbonFootprintSerializedHeaders.manureStorageOfFeedFattener,
        unserialized: CarbonFootprintUnserializedHeaders.manureStorageOfFeedFattener,
        view: IntlKeys.carbonFootprintManureStorageOfFeedCo2Fattener,
      },
      [CarbonFootprintSerializedHeaders.manureTransportOfFeedFattener]: {
        serialized: CarbonFootprintSerializedHeaders.manureTransportOfFeedFattener,
        unserialized: CarbonFootprintUnserializedHeaders.manureTransportOfFeedFattener,
        view: IntlKeys.carbonFootprintManureTransportOfFeedCo2Fattener,
      },
      [CarbonFootprintSerializedHeaders.entericFermentationFattener]: {
        serialized: CarbonFootprintSerializedHeaders.entericFermentationFattener,
        unserialized: CarbonFootprintUnserializedHeaders.entericFermentationFattener,
        view: IntlKeys.carbonFootprintEntericFermentationCo2Fattener,
      },
      [CarbonFootprintSerializedHeaders.energyUseFattener]: {
        serialized: CarbonFootprintSerializedHeaders.energyUseFattener,
        unserialized: CarbonFootprintUnserializedHeaders.energyUseFattener,
        view: IntlKeys.carbonFootprintEnergyUseCo2Fattener,
      },
      [CarbonFootprintSerializedHeaders.fatteningTotal]: {
        serialized: CarbonFootprintSerializedHeaders.fatteningTotal,
        unserialized: CarbonFootprintUnserializedHeaders.fatteningTotal,
      },
      [CarbonFootprintSerializedHeaders.perKgOfLiveWeight]: {
        serialized: CarbonFootprintSerializedHeaders.perKgOfLiveWeight,
        unserialized: CarbonFootprintUnserializedHeaders.perKgOfLiveWeight,
        view: IntlKeys.carbonFootprintKgSlaughterWeight,
      },
      [CarbonFootprintSerializedHeaders.perKgOfLiveWeightAverage]: {
        serialized: CarbonFootprintSerializedHeaders.perKgOfLiveWeightAverage,
        unserialized: CarbonFootprintUnserializedHeaders.perKgOfLiveWeightAverage,
        view: IntlKeys.carbonFootprintKgSlaughterWeight,
      },
      [CarbonFootprintSerializedHeaders.status]: {
        serialized: CarbonFootprintSerializedHeaders.status,
        unserialized: CarbonFootprintUnserializedHeaders.status,
        view: IntlKeys.carbonFootprintStatus,
      },
      [CarbonFootprintSerializedHeaders.fileName]: {
        serialized: CarbonFootprintSerializedHeaders.fileName,
        unserialized: CarbonFootprintUnserializedHeaders.fileName,
        view: IntlKeys.carbonFootprintFileName,
      },
      [CarbonFootprintSerializedHeaders.validDateFrom]: {
        serialized: CarbonFootprintSerializedHeaders.validDateFrom,
        unserialized: CarbonFootprintUnserializedHeaders.validDateFrom,
        view: IntlKeys.carbonFootprintValidDateFrom,
      },
      [CarbonFootprintSerializedHeaders.validDateTo]: {
        serialized: CarbonFootprintSerializedHeaders.validDateTo,
        unserialized: CarbonFootprintUnserializedHeaders.validDateTo,
        view: IntlKeys.carbonFootprintValidDateTo,
      },
      [CarbonFootprintSerializedHeaders.notes]: {
        serialized: CarbonFootprintSerializedHeaders.notes,
        unserialized: CarbonFootprintUnserializedHeaders.notes,
        view: IntlKeys.carbonFootprintNotes,
      },
      [CarbonFootprintSerializedHeaders.uploadedAt]: {
        serialized: CarbonFootprintSerializedHeaders.uploadedAt,
        unserialized: CarbonFootprintUnserializedHeaders.uploadedAt,
      },
      [CarbonFootprintSerializedHeaders.uploadedBy]: {
        serialized: CarbonFootprintSerializedHeaders.uploadedBy,
        unserialized: CarbonFootprintUnserializedHeaders.uploadedBy,
      },
    },
  },
};

export const CARBON_FOOTPRINTS_SUBMIT = {
  [CarbonFootprintSerialized.CarbonFootprintsSubmit]: {
    serialized: CarbonFootprintSerialized.CarbonFootprintsSubmit,
    unserialized: CarbonFootprintUnserialized.CarbonFootprintsSubmit,

    columns: {
      [CarbonFootprintsSubmitSerializedHeaders.id]: {
        serialized: CarbonFootprintsSubmitSerializedHeaders.id,
        unserialized: CarbonFootprintsSubmitUnserializedHeaders.id,
      },
      [CarbonFootprintsSubmitSerializedHeaders.status]: {
        serialized: CarbonFootprintsSubmitSerializedHeaders.status,
        unserialized: CarbonFootprintsSubmitUnserializedHeaders.status,
      },
      [CarbonFootprintsSubmitSerializedHeaders.validDateFrom]: {
        serialized: CarbonFootprintsSubmitSerializedHeaders.validDateFrom,
        unserialized: CarbonFootprintsSubmitUnserializedHeaders.validDateFrom,
      },
      [CarbonFootprintsSubmitSerializedHeaders.validDateTo]: {
        serialized: CarbonFootprintsSubmitSerializedHeaders.validDateTo,
        unserialized: CarbonFootprintsSubmitUnserializedHeaders.validDateTo,
      },
      [CarbonFootprintsSubmitSerializedHeaders.notes]: {
        serialized: CarbonFootprintsSubmitSerializedHeaders.notes,
        unserialized: CarbonFootprintsSubmitUnserializedHeaders.notes,
      },
    },
  },
};
