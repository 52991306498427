import { AxiosError } from 'axios';

export type BackRecordsErrorsSingle = {
  errors: Record<string, string[]>;
};
export const stringifySingleItemErrors = ({ response }: AxiosError<BackRecordsErrorsSingle>) => {
  if (!response) return ['Something went wrong'];

  const payload = JSON.parse(response.config.data);
  const data: Record<string, string> = payload.resource ? payload.resource : payload;
  const backErrors = response.data?.errors;

  return Object.entries(backErrors).map(([field, error]) => {
    const conflictPointer = data?.[field] !== undefined ? `${field}: "${data[field]}"` : '';

    return `${conflictPointer} ${error}`;
  });
};

export type BackRecordsErrorsMultiple = {
  success: boolean;
  record_errors: Array<{ index: number } & BackRecordsErrorsSingle>;
};
export const stringifyMultipleItemsErrors = ({ response }: AxiosError<BackRecordsErrorsMultiple>) => {
  if (!response) return [['Something went wrong']];

  const payload = JSON.parse(response.config.data);
  const data: Record<string, unknown>[] = payload.resources ? payload.resources : payload;
  const backErrors = response.data?.record_errors;

  return backErrors?.map(({ errors, index }) => {
    const conflictValue = data[index];

    return Object.entries(errors).map(([field, error]) => {
      const value = conflictValue[field] !== undefined ? conflictValue[field] : conflictValue[field + '_id'];
      return `Row ${index + 1} ${field}: "${value}" ${error}`;
    });
  });
};
