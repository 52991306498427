import { get } from 'lodash';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { CellSerializer, PropertyPath } from 'lib/excel/serilizers/Cell';
import { dateToFormattedString, serializeObjectSnakeCaseToCamelCase } from 'lib/excel/serilizers/utils';
import { ResourcesWrapper, ResourceWrapper } from 'lib/http/utils';
import { SERVICE_FIELDS } from 'lib/excel/config/service';
import { USER_PASSPORTS, UserPassportsUnserializedHeaders } from 'lib/tables/config/userPassports/config';
import { AdminUserSerialized, AdminUserUnserialized, serializeAdminUsers } from 'store/entities/admin/users';
import { ADMIN_USERS } from 'lib/tables/config/admin/users/config';
import {
  PermissionProfileResponseEntry,
  PermissionProfileResponseEntrySerialized,
  serializePermissionProfile,
} from '../admin/entities';
import { LabelPayload, LabelSerialized } from './monitoringDashboard/labels';

const { columns, serialized, unserialized } = USER_PASSPORTS.UserPassports;

export interface UbnAliasesSerialized {
  value: string;
}

export interface UserPassportConcept {
  concept: string;
  dateFrom?: string;
  dateTo?: string;
}

export interface UserPassportPayload {
  id: number;
  registration_name: string;
  kvk: string;
  concepts: UserPassportConcept[];
  ubn: string;
  passport_aliases: UbnAliasesSerialized[];
  custom_ubn_name: string | null;
  gln: null | string;
  btw: string;
  vion_relation_number: string;
  // new
  certificates: string[];
  certificates_other: string;
  genetic_suppliers: string[];
  genetic_supplier_other: string;
  ki_stations: string[];
  ki_station_other: string;
  sow_lines: string[];
  sow_line_other: string;
  breeding_program: string;
  sire_lines: string[];
  sire_line_other: string;
  veterinary_practice: string;
  veterinary_practice_other: string;
  feed_supplier_other: string;
  fms_names: string[];
  fms_module_agrisysts: string[];
  fms_module_agro_coops: string[];
  fms_module_agrovisions: string[];
  feeding_systems: string[];
  feeding_system_other: string;
  climate_systems: string[];
  climate_system_other: string;
  //
  veterinarian: string;
  type_of_company: string;
  feed_suppliers: string;
  phone: string;
  place: string;
  postcode: string;
  street: string;
  status: string;
  status_updated_by: string;
  status_updated_at: string;
  passport_owners: Array<{
    id: number;
    user_type: string;
    email: string;
    name: string;
  }>;
  organization: {
    id: number;
    email: string;
    kvk: string;
    kvk_company_name: string;
    logo: {
      url: string;
    };
  };

  [UserPassportsUnserializedHeaders.permissionProfileTemplateId]: number | null;
  [UserPassportsUnserializedHeaders.organizationPermissionProfile]: PermissionProfileResponseEntry | null;

  labels: LabelPayload[];

  created_at: string;
  updated_at: string;
  updated_by: {
    email: string;
    name: string;
  };
}

export interface UserPassportTransformedPayload {
  id: number;
  registration_name: string;
  kvk: string;
  concepts: UserPassportConcept[];
  ubn: string;
  custom_ubn_name: string | null;
  gln: null | string;
  btw: string;
  vion_relation_number: string;
  // new
  certificates: string[];
  certificates_other: string;
  genetic_suppliers: string[];
  genetic_supplier_other: string;
  ki_stations: string[];
  ki_station_other: string;
  sow_lines: string[];
  sow_line_other: string;
  breeding_program: string;
  sire_lines: string[];
  sire_line_other: string;
  veterinary_practice: string;
  veterinary_practice_other: string;
  feed_supplier_other: string;
  fms_names: string[];
  fms_module_agrisysts: string[];
  fms_module_agro_coops: string[];
  fms_module_agrovisions: string[];
  feeding_systems: string[];
  feeding_system_other: string;
  climate_systems: string[];
  climate_system_other: string;
  //
  veterinarian: string;
  type_of_company: string;
  feed_suppliers: string;
  phone: string;
  place: string;
  postcode: string;
  street: string;
  organization: {
    id: number;
    email: string;
    kvk: string;
    kvk_company_name: string;
    logo: {
      url: string;
    };
  };

  [UserPassportsUnserializedHeaders.permissionProfileTemplateId]: number | null;
  [UserPassportsUnserializedHeaders.organizationPermissionProfile]: PermissionProfileResponseEntry | null;

  user_type: string;
  user_name: string;
  user_email: string;

  created_at: string;
  updated_at: string;
}

export interface UserPassportSerialized {
  id: number;
  registrationName: string;
  kvk: string;
  concepts: UserPassportConcept[];
  ubn: string;
  ubnAliases: string[];
  customUbnName: string;
  gln: string;
  // btw: string;
  vionRelationNumber: string;
  // new
  certificates: string[];
  certificatesOther: string;
  geneticSuppliers: string[];
  geneticSupplierOther: string;
  kiStations: string[];
  kiStationOther: string;
  sowLines: string[];
  sowLineOther: string;
  breedingProgram: string;
  sireLines: string[];
  sireLineOther: string;
  veterinaryPractice: string;
  veterinaryPracticeOther: string;
  feedSupplierOther: string;
  fmsNames: string[];
  fmsModuleAgrisysts: string[];
  fmsModuleAgroCoops: string[];
  fmsModuleAgrovisions: string[];
  feedingSystems: string[];
  feedingSystemOther: string;
  climateSystems: string[];
  climateSystemOther: string;
  //
  veterinarian: string;
  typeOfCompany: string;
  feedSuppliers: string;
  email: string;
  phone: string;
  addressPlace: string;
  addressPostcode: string;
  addressStreet: string;
  vatNum: string;
  name: string;
  type: string;
  status: string;
  statusUpdatedBy: string;
  statusUpdatedAt: string;

  organization: {
    id: number;
    email: string;
    kvk: string;
    kvkCompanyName: string;
    logo: {
      url: string;
    };
  };
  passportOwners: AdminUserSerialized[];

  permissionProfileTemplateId: number;
  organizationPermissionProfile: PermissionProfileResponseEntrySerialized;

  labels: LabelSerialized[];

  createdAt: string;
  updatedAt: string;
  updatedBy: {
    email: string;
    full_name: string;
  };
  blockchainTimestamp: string;
  blockchainHashKey: string;
}

export const getSerializer = (data: DocumentData) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(unserialized, serialized, [
      new CellSerializer(SERVICE_FIELDS.id.unserialized, SERVICE_FIELDS.id.serialized),
      new CellSerializer(columns.registrationName.unserialized, columns.registrationName.serialized),
      new CellSerializer(columns.kvk.unserialized, columns.kvk.serialized),
      new CellSerializer(columns.concepts.unserialized, columns.concepts.serialized),
      new CellSerializer(columns.ubn.unserialized, columns.ubn.serialized),
      new CellSerializer(columns.customUbnName.unserialized, columns.customUbnName.serialized),
      new CellSerializer(columns.gln.unserialized, columns.gln.serialized, undefined, undefined, 'EMPTY'),
      new CellSerializer(columns.vionRelationNumber.unserialized, columns.vionRelationNumber.serialized),
      // new
      new CellSerializer(columns.certificates.unserialized, columns.certificates.serialized),
      new CellSerializer(columns.certificatesOther.unserialized, columns.certificatesOther.serialized),
      new CellSerializer(columns.geneticSuppliers.unserialized, columns.geneticSuppliers.serialized),
      new CellSerializer(columns.geneticSupplierOther.unserialized, columns.geneticSupplierOther.serialized),
      new CellSerializer(columns.kiStations.unserialized, columns.kiStations.serialized),
      new CellSerializer(columns.kiStationOther.unserialized, columns.kiStationOther.serialized),
      new CellSerializer(columns.sowLines.unserialized, columns.sowLines.serialized),
      new CellSerializer(columns.sowLineOther.unserialized, columns.sowLineOther.serialized),
      new CellSerializer(columns.breedingProgram.unserialized, columns.breedingProgram.serialized),
      new CellSerializer(columns.sireLines.unserialized, columns.sireLines.serialized),
      new CellSerializer(columns.sireLineOther.unserialized, columns.sireLineOther.serialized),
      new CellSerializer(columns.veterinaryPractice.unserialized, columns.veterinaryPractice.serialized),
      new CellSerializer(columns.veterinaryPracticeOther.unserialized, columns.veterinaryPracticeOther.serialized),
      new CellSerializer(columns.feedSupplierOther.unserialized, columns.feedSupplierOther.serialized),
      new CellSerializer(columns.fmsNames.unserialized, columns.fmsNames.serialized),
      new CellSerializer(columns.fmsModuleAgrisysts.unserialized, columns.fmsModuleAgrisysts.serialized),
      new CellSerializer(columns.fmsModuleAgroCoops.unserialized, columns.fmsModuleAgroCoops.serialized),
      new CellSerializer(columns.fmsModuleAgrovisions.unserialized, columns.fmsModuleAgrovisions.serialized),
      new CellSerializer(columns.feedingSystems.unserialized, columns.feedingSystems.serialized),
      new CellSerializer(columns.feedingSystemOther.unserialized, columns.feedingSystemOther.serialized),
      new CellSerializer(columns.climateSystems.unserialized, columns.climateSystems.serialized),
      new CellSerializer(columns.climateSystemOther.unserialized, columns.climateSystemOther.serialized),
      //
      new CellSerializer(columns.feedSuppliers.unserialized, columns.feedSuppliers.serialized),
      new CellSerializer(columns.phone.unserialized, columns.phone.serialized),
      new CellSerializer(columns.addressPlace.unserialized, columns.addressPlace.serialized),
      new CellSerializer(columns.addressPostcode.unserialized, columns.addressPostcode.serialized),
      new CellSerializer(columns.addressStreet.unserialized, columns.addressStreet.serialized),
      new CellSerializer(columns.vatNum.unserialized, columns.vatNum.serialized),
      new CellSerializer(columns.veterinarian.unserialized, columns.veterinarian.serialized),
      new CellSerializer(columns.typeOfCompany.unserialized, columns.typeOfCompany.serialized),
      new CellSerializer(columns.status.unserialized, columns.status.serialized),
      new CellSerializer(columns.statusUpdatedBy.unserialized, columns.statusUpdatedBy.serialized),
      new CellSerializer(columns.statusUpdatedAt.unserialized, columns.statusUpdatedAt.serialized),

      new CellSerializer(
        columns.organization.unserialized,
        columns.organization.serialized,
        serializeObjectSnakeCaseToCamelCase,
      ),

      new CellSerializer(
        columns.passportOwners.unserialized,
        columns.passportOwners.serialized,
        (data, sheetData, excelRow) => {
          const pseudoResponse = ({
            data: { resources: excelRow?.passport_owners || [] },
          } as unknown) as ResourcesWrapper<AdminUserUnserialized>;

          return serializeAdminUsers(pseudoResponse)[ADMIN_USERS.AdminUsers.serialized];
        },
      ),

      new CellSerializer(
        columns.ubnAliases.unserialized,
        columns.ubnAliases.serialized,
        (
          data: DocumentData,
          sheetData: Array<object>,
          excelRow: { [index: string]: any },
          propertyOriginalPath: PropertyPath,
        ) => {
          const value = get(excelRow, propertyOriginalPath);
          return value?.map((item: UbnAliasesSerialized) => item?.value);
        },
      ),

      new CellSerializer(
        columns.permissionProfileTemplateId.unserialized,
        columns.permissionProfileTemplateId.serialized,
      ),

      new CellSerializer(
        columns.organizationPermissionProfile.unserialized,
        columns.organizationPermissionProfile.serialized,
        (data, sheetData, excelRow, propertyOriginalName) => {
          const permissionProfile = excelRow[propertyOriginalName as string] || {
            id: 0,
            title: '',
            notes: '',
            name: '',
            permission_rules: [],
          };

          return serializePermissionProfile(permissionProfile);
        },
      ),

      new CellSerializer(columns.labels.unserialized, columns.labels.serialized),

      new CellSerializer(
        SERVICE_FIELDS.createdAt.unserialized,
        SERVICE_FIELDS.createdAt.serialized,
        dateToFormattedString,
      ),
      new CellSerializer(SERVICE_FIELDS.updatedAt.unserialized, SERVICE_FIELDS.updatedAt.serialized),
      new CellSerializer(SERVICE_FIELDS.updatedBy.unserialized, SERVICE_FIELDS.updatedBy.serialized),
      new CellSerializer(SERVICE_FIELDS.blockchainHashKey.unserialized, SERVICE_FIELDS.blockchainHashKey.serialized),
      new CellSerializer(
        SERVICE_FIELDS.blockchainTimestamp.unserialized,
        SERVICE_FIELDS.blockchainTimestamp.serialized,
      ),
    ]),
  ]);
};

export const serializeUserPassport = (serverData: ResourceWrapper<UserPassportTransformedPayload>) => {
  const data = {
    [unserialized]: [serverData.data.resource],
  };

  const serializer = getSerializer(data);

  return serializer.serialize()[unserialized][0] as UserPassportSerialized;
};

export const serializeUserPassports = (serverData: ResourcesWrapper<UserPassportTransformedPayload>) => {
  const data = {
    [unserialized]: serverData.data.resources,
  };

  const serializer = getSerializer(data);

  return serializer.serialize();
};
