import { takeEvery, put, select, call } from 'redux-saga/effects';
import { push, LOCATION_CHANGE } from 'connected-react-router';
import { matchPath } from 'react-router';
import { isEmpty } from 'lodash';
import { AxiosResponse } from 'axios';
import { History } from 'history';

import routes, { ChildRouteType } from 'routes';
import ensure from 'lib/ensure';
import { CustomAction } from 'lib/actions';
import { ApiClient } from 'lib/http/ApiClient';
import { FilterValueType } from 'lib/filters';
import { ResourcesWrapper } from 'lib/http/utils';
import { formatDate } from 'lib/helpers/formatDate';
import { IntlKeys } from 'lib/localization/keys';

import filterConfigs from 'store/filters/entities';
import { AdminOrganizationPayload } from 'store/entities/adminOrganizations';
import { currentRouteSelector } from 'store/reducers/routerReducer';
import {
  getFilterOptionsAction,
  setCurrentFiltersAction,
  setFiltersAction,
  setFiltersQueryAction,
} from 'store/filters/actions';
import { FILTER_ENTITY_TYPES } from './reducer';
import { userHasPermissionSelector } from '../auth/reducer';
import { PERMISSIONS } from '../auth/permissions';
import { CompanyReportPayload } from '../entities/farmerInput/companyReports';
import { ObjectWithProps } from "../../lib/excel/serilizers/Cell";

function* handleSetCurrentFilters({ payload }: CustomAction<Partial<Record<string, FilterValueType>>>) {
  //
  const currentRoute: ChildRouteType = yield select(currentRouteSelector) as unknown;

  if (currentRoute.filterType) {
    yield* handleSetFiltersQuery(
      setFiltersQueryAction({
        entity: currentRoute.filterType,
        filters: payload,
      }),
    );
  } else {
    console.warn(`Expected filter entity but got ${currentRoute.filterType}`);
  }
}

function* handleSetFiltersQuery({
  payload,
}: CustomAction<{ entity: FILTER_ENTITY_TYPES; filters: Partial<Record<string, FilterValueType>> }>) {
  //
  const { entity, filters } = payload;

  const { getQuery } = filterConfigs[entity];

  yield put(
    push({
      search: getQuery(filters),
      state: {
        filterType: entity,
      },
    }),
  );
}

function* handleLocationChange({ payload }: CustomAction<History<undefined | { filterType?: FILTER_ENTITY_TYPES }>>) {
  const currentRoute = routes.find((route) =>
    matchPath(payload.location.pathname, {
      path: route.path,
      exact: true,
    }),
  );

  const newEntity = payload.location.state?.filterType || currentRoute?.filterType;

  if (newEntity) {
    const { getValuesFromQuery } = filterConfigs[newEntity];

    const filtersValue = getValuesFromQuery(payload.location.search);

    if (!isEmpty(filtersValue)) {
      yield put(
        setFiltersAction({
          entity: newEntity,
          filters: filtersValue,
        }),
      );
    }
  }
}

export type FilterOptionsEntities =
  | 'ubn'
  | 'ubnId'
  | 'vriReportsUbn'
  | 'vriPassportId'
  | 'vriComparisonPassportId'
  | 'kvk'
  | 'orgKvk'
  | 'organizationIds'
  | 'purposesIds'
  | 'uploadedBy'
  | 'co2UploadedBy'
  | 'co2Ubn'
  | 'companyReports'
  | 'userPassportsIds'
  | 'labels'
  | 'concepts'
  | 'companies'
  | 'feedSupplier';

export const getFilterOptionsEnsure = (entityKey: FilterOptionsEntities, isAdmin?: boolean) => {
  switch (entityKey) {
    ////////////////////////////////////////////////////////////////////////////////////////
    case 'ubn':
      return ensure({
        api: ApiClient.getUbnList,
        action: getFilterOptionsAction,
        serializeSuccessPayload: (response: AxiosResponse) => {
          return response.data.resources.map(({ ubn, name }: Record<'ubn' | 'name', string>) => ({
            label: `${name} - ${ubn}`,
            value: ubn,
          }));
        },
      });
    case 'ubnId':
      return ensure({
        api: ApiClient.getUbnList,
        action: getFilterOptionsAction,
        serializeSuccessPayload: (response: AxiosResponse) => {
          return response.data.resources.map(({ ubn, name, id }: Record<'ubn' | 'name' | 'id', string>) => ({
            label: `${name} - ${ubn}`,
            value: id,
          }));
        },
      });
    case 'vriReportsUbn':
      return ensure({
        api: isAdmin ? ApiClient.adminGetReportsUbnList : ApiClient.getReportsUbnList,
        action: getFilterOptionsAction,
        serializeSuccessPayload: (response: AxiosResponse) => {
          return response.data.resources.map(({ ubn, name }: Record<'ubn' | 'name', string>) => ({
            label: `${name} - ${ubn}`,
            value: ubn,
          }));
        },
      });
    case 'vriPassportId':
      return ensure({
        api: ApiClient.getUserPassportUbnList,
        action: getFilterOptionsAction,
        serializeSuccessPayload: (response: AxiosResponse) => {
          return response.data.resources.map(({ ubn, name, id }: Record<'ubn' | 'name' | 'id', string>) => ({
            label: `${name} - ${ubn}`,
            value: Number(id).toString(),
          }));
        },
      });
    case 'vriComparisonPassportId':
      return ensure({
        api: ApiClient.getUserPassportUbnList,
        action: getFilterOptionsAction,
        serializeSuccessPayload: (response: AxiosResponse) => {
          return response.data.resources.map(({ ubn, name, id }: Record<'ubn' | 'name' | 'id', string>) => ({
            label: `${name} - ${ubn}`,
            value: Number(id).toString(),
          }));
        },
      });
    ////////////////////////////////////////////////////////////////////////////////////////
    case 'kvk':
      return ensure({
        api: isAdmin ? ApiClient.getAdminOrganizationList : ApiClient.getOrganizationList,
        action: getFilterOptionsAction,
        serializeSuccessPayload: (response: ResourcesWrapper<AdminOrganizationPayload>) => {
          return response.data.resources.map((organization) => {
            const { id, kvk, kvk_company_name, user_passports } = organization;
            const kvkLabel = `ID: ${id}, KVK: ${kvk}, ${kvk_company_name}`;

            return {
              label: kvkLabel,
              value: kvk,

              related: {
                ubn: user_passports.map(({ ubn }) => ({
                  label: ubn,
                  value: ubn,
                  groupLabel: kvkLabel,
                })),
              },
            };
          });
        },
      });
    case 'orgKvk':
      return ensure({
        api: isAdmin ? ApiClient.getAdminOrganizationKvkList : ApiClient.getOrganizationKvkList,
        action: getFilterOptionsAction,
        serializeSuccessPayload: (response: ResourcesWrapper<AdminOrganizationPayload>) => {
          return response.data.resources.map((organization) => {
            const { id, kvk, kvk_company_name, user_passports } = organization;
            const kvkLabel = `ID: ${id}, KVK: ${kvk}, ${kvk_company_name}`;

            return {
              label: kvkLabel,
              value: kvk,

              related: {
                ubn: user_passports.map(({ ubn }) => ({
                  label: ubn,
                  value: ubn,
                  groupLabel: kvkLabel,
                })),
              },
            };
          });
        },
      });
    ////////////////////////////////////////////////////////////////////////////////////////
    case 'organizationIds':
      return ensure({
        api: isAdmin ? ApiClient.getAdminOrganizationList : ApiClient.getOrganizationList,
        action: getFilterOptionsAction,
        serializeSuccessPayload: (response: ResourcesWrapper<AdminOrganizationPayload>) => {
          return response.data.resources.map((organization) => {
            const { id: organizationId, kvk, kvk_company_name, user_passports } = organization;
            const kvkLabel = `ID: ${organizationId}, KVK: ${kvk}, ${kvk_company_name}`;

            return {
              label: kvkLabel,
              value: organizationId,

              related: {
                userPassportIds: user_passports.map(({ id, ubn }) => ({
                  label: ubn,
                  value: id,
                  groupLabel: kvkLabel,
                })),
              },
            };
          });
        },
      });
    ////////////////////////////////////////////////////////////////////////////////////////
    case 'purposesIds':
      return ensure({
        api: ApiClient.getPurposesList,
        action: getFilterOptionsAction,
        serializeSuccessPayload: (response: AxiosResponse) => {
          return response.data.resources.map(({ name, id }: Record<'id' | 'name', string>) => ({
            label: name,
            value: id,
          }));
        },
      });
    ////////////////////////////////////////////////////////////////////////////////////////
    case 'uploadedBy':
      return ensure({
        api: isAdmin ? ApiClient.getAdminUsers : ApiClient.getUsers,
        action: getFilterOptionsAction,
        serializeSuccessPayload: (response: AxiosResponse) => {
          return response.data.resources.map(({ id, full_name }: Record<'id' | 'full_name', string>) => {
            return {
              label: full_name,
              value: Number(id).toString(),
            };
          });
        },
      });

    case 'companyReports':
      return ensure({
        api: isAdmin ? ApiClient.adminGetCompanyReports : ApiClient.getCompanyReports,
        action: getFilterOptionsAction,
        serializeSuccessPayload: (response: AxiosResponse) => {
          return response.data.resources.map(
            ({
              id,
              reporting_period_date_start,
              reporting_period_date_end,
              status,
              updated_by,
              updated_at,
            }: CompanyReportPayload) => {
              return {
                label: {
                  intlId: IntlKeys.farmerInputCompanyDataOption,
                  values: {
                    id,
                    from: formatDate(reporting_period_date_start),
                    to: formatDate(reporting_period_date_end),
                    status,
                    updatedBy: updated_by?.full_name,
                    updatedAt: formatDate(updated_at),
                  },
                },
                value: id,

                related: {
                  reportingPeriodDateStart: reporting_period_date_start,
                  reportingPeriodDateEnd: reporting_period_date_end,
                },
              };
            },
          );
        },
      });

    case 'co2UploadedBy':
      return ensure({
        api: ApiClient.getCarbonFootprintsUsers,
        action: getFilterOptionsAction,
        serializeSuccessPayload: (response: AxiosResponse) => {
          return response.data.resources.map(({ id, full_name }: Record<'id' | 'full_name', string>) => {
            return {
              label: full_name,
              value: Number(id).toString(),
            };
          });
        },
      });

    case 'co2Ubn':
      return ensure({
        api: ApiClient.getUserPassportUbnList,
        action: getFilterOptionsAction,
        serializeSuccessPayload: (response: AxiosResponse) => {
          return response.data.resources.map(({ ubn, name }: Record<'ubn' | 'name', string>) => ({
            label: `${name} - ${ubn}`,
            value: Number(ubn).toString(),
          }));
        },
      });

    case 'userPassportsIds':
      return ensure({
        api: isAdmin ? ApiClient.getAdminUserPassport : ApiClient.getUserPassports,
        action: getFilterOptionsAction,
        serializeSuccessPayload: (response: AxiosResponse) => {
          return response.data.resources.map(
            ({ id, ubn, registration_name }: Record<'id' | 'ubn' | 'registration_name', string>) => ({
              label: `${ubn}, ${registration_name}`,
              value: id,
            }),
          );
        },
      });

    case 'labels':
      return ensure({
        api: isAdmin ? ApiClient.adminGetLabels : ApiClient.getLabels,
        action: getFilterOptionsAction,
        serializeSuccessPayload: (response: AxiosResponse) => {
          return response.data.resources.map(({ id, name, color }: Record<'id' | 'name' | 'color', string>) => ({
            label: name,
            value: id,
            color,
          }));
        },
      });

    case 'concepts':
      return ensure({
        api: isAdmin ? ApiClient.adminGetConceptList : ApiClient.getConceptList,
        action: getFilterOptionsAction,
        serializeSuccessPayload: (response: AxiosResponse) => {
          return response.data.resources.map((concept: string) => ({
            label: concept,
            value: concept,
          }));
        },
      });

    case 'companies':
      return ensure({
        api: isAdmin ? ApiClient.adminGetCompaniesList : ApiClient.getCompaniesList,
        action: getFilterOptionsAction,
        serializeSuccessPayload: (response: AxiosResponse) => {
          return response.data.resources.map((company: string) => ({
            label: company,
            value: company,
          }));
        },
      });

    case 'feedSupplier':
      return ensure({
        api: ApiClient.getFeedSuppliers,
        action: getFilterOptionsAction,
        serializeSuccessPayload: (response: AxiosResponse) => {
          return response.data.map((feedSupplier: string) => ({
            label: feedSupplier,
            value: feedSupplier,
          }));
        },
      });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const assertUnreachable = (_x: never): never => {
    // To ensure we never miss cases in switch
    throw new Error(`Invalid filter options key ${_x}`);
  };
  return assertUnreachable(entityKey);
};

function* handleGetFilterOptions({ payload }: CustomAction<{ filterOptionsKey: string, filter?: ObjectWithProps }>) {
  const isAdmin: boolean = (yield select(userHasPermissionSelector))(PERMISSIONS.LEVEL1);
  const ensure = getFilterOptionsEnsure(payload.filterOptionsKey as FilterOptionsEntities, isAdmin);

  if (ensure) {
    const args: any = { meta: { optionKey: payload.filterOptionsKey } }
    if (payload.filter) {
      args.filter = payload.filter;
    }
    yield call(ensure, args);
  }
}

export default function* () {
  yield takeEvery(setFiltersQueryAction.type, handleSetFiltersQuery);
  yield takeEvery(setCurrentFiltersAction.type, handleSetCurrentFilters);
  yield takeEvery(getFilterOptionsAction.request, handleGetFilterOptions);
  yield takeEvery(LOCATION_CHANGE, handleLocationChange);
}
