import { IntlKeys } from 'lib/localization/keys';

enum GreenhouseGasEmissionSerialized {
  GreenhouseGasEmission = 'GreenhouseGasEmission',
}

enum GreenhouseGasEmissionUnserialized {
  GreenhouseGasEmission = 'GreenhouseGasEmission',
}

export enum GreenhouseGasEmissionSerializedHeaders {
  id = 'id',
  referenceUploadID = 'referenceUploadID',
  country = 'country',
  location = 'location',
  category = 'category',
  account = 'account',
  extraExternalId = 'extraExternalId',
  name = 'name',
  emissionFactor = 'emissionFactor',
  unit = 'unit',
  conversionFactor = 'conversionFactor',
  formulaCf = 'formulaCf',
  co2Unit = 'co2Unit',
  uploadType = 'uploadType',
  filename = 'filename',
  status = 'status',
  validDateFrom = 'validDateFrom',
  validDateTo = 'validDateTo',
  notes = 'notes',
  concepts = 'concepts',
  uploadedAt = 'uploadedAt',
  uploadedBy = 'uploadedBy',
}

export const GreenhouseGasEmissionUnserializedHeaders = {
  id: 'id',
  referenceUploadID: ['greenhouse_gas_emission_upload', 'id'],
  country: 'country',
  location: 'location',
  category: 'category',
  account: 'account',
  extraExternalId: 'extra_external_id',
  name: 'name',
  emissionFactor: 'emission_factor',
  unit: 'unit',
  conversionFactor: 'conversion_factor',
  formulaCf: 'formula_cf',
  co2Unit: 'co2_unit',
  uploadType: ['greenhouse_gas_emission_upload', 'upload_type'],
  filename: ['greenhouse_gas_emission_upload', 'file_name'],
  status: ['greenhouse_gas_emission_upload', 'status'],
  validDateFrom: ['greenhouse_gas_emission_upload', 'valid_from'],
  validDateTo: ['greenhouse_gas_emission_upload', 'valid_to'],
  notes: ['greenhouse_gas_emission_upload', 'notes'],
  concepts: ['greenhouse_gas_emission_upload', 'concepts'],
  uploadedAt: ['greenhouse_gas_emission_upload', 'uploaded_at'],
  uploadedBy: ['greenhouse_gas_emission_upload', 'uploaded_by', 'full_name'],
} as const;

export const GREENHOUSE_GAS_EMISSION = {
  [GreenhouseGasEmissionSerialized.GreenhouseGasEmission]: {
    serialized: GreenhouseGasEmissionSerialized.GreenhouseGasEmission,
    unserialized: GreenhouseGasEmissionUnserialized.GreenhouseGasEmission,
    view: IntlKeys.referenceTablesGreenhouseGasEmission,

    columns: {
      [GreenhouseGasEmissionSerializedHeaders.id]: {
        serialized: GreenhouseGasEmissionSerializedHeaders.id,
        unserialized: GreenhouseGasEmissionUnserializedHeaders.id,
      },
      [GreenhouseGasEmissionSerializedHeaders.referenceUploadID]: {
        serialized: GreenhouseGasEmissionSerializedHeaders.referenceUploadID,
        unserialized: GreenhouseGasEmissionUnserializedHeaders.referenceUploadID,
      },
      [GreenhouseGasEmissionSerializedHeaders.country]: {
        serialized: GreenhouseGasEmissionSerializedHeaders.country,
        unserialized: GreenhouseGasEmissionUnserializedHeaders.country,
        view: IntlKeys.referenceTablesGreenhouseGasEmissionCountry,
      },
      [GreenhouseGasEmissionSerializedHeaders.location]: {
        serialized: GreenhouseGasEmissionSerializedHeaders.location,
        unserialized: GreenhouseGasEmissionUnserializedHeaders.location,
        view: IntlKeys.referenceTablesGreenhouseGasEmissionLocation,
      },
      [GreenhouseGasEmissionSerializedHeaders.category]: {
        serialized: GreenhouseGasEmissionSerializedHeaders.category,
        unserialized: GreenhouseGasEmissionUnserializedHeaders.category,
        view: IntlKeys.referenceTablesGreenhouseGasEmissionCategory,
      },
      [GreenhouseGasEmissionSerializedHeaders.account]: {
        serialized: GreenhouseGasEmissionSerializedHeaders.account,
        unserialized: GreenhouseGasEmissionUnserializedHeaders.account,
        view: IntlKeys.referenceTablesGreenhouseGasEmissionAccount,
      },
      [GreenhouseGasEmissionSerializedHeaders.extraExternalId]: {
        serialized: GreenhouseGasEmissionSerializedHeaders.extraExternalId,
        unserialized: GreenhouseGasEmissionUnserializedHeaders.extraExternalId,
        view: IntlKeys.referenceTablesGreenhouseGasEmissionExtraExternalId,
      },
      [GreenhouseGasEmissionSerializedHeaders.name]: {
        serialized: GreenhouseGasEmissionSerializedHeaders.name,
        unserialized: GreenhouseGasEmissionUnserializedHeaders.name,
        view: IntlKeys.referenceTablesGreenhouseGasEmissionName,
      },
      [GreenhouseGasEmissionSerializedHeaders.emissionFactor]: {
        serialized: GreenhouseGasEmissionSerializedHeaders.emissionFactor,
        unserialized: GreenhouseGasEmissionUnserializedHeaders.emissionFactor,
        view: IntlKeys.referenceTablesGreenhouseGasEmissionEmissionFactor,
      },
      [GreenhouseGasEmissionSerializedHeaders.unit]: {
        serialized: GreenhouseGasEmissionSerializedHeaders.unit,
        unserialized: GreenhouseGasEmissionUnserializedHeaders.unit,
        view: IntlKeys.referenceTablesGreenhouseGasEmissionUnit,
      },
      [GreenhouseGasEmissionSerializedHeaders.conversionFactor]: {
        serialized: GreenhouseGasEmissionSerializedHeaders.conversionFactor,
        unserialized: GreenhouseGasEmissionUnserializedHeaders.conversionFactor,
        view: IntlKeys.referenceTablesGreenhouseGasEmissionConversionFactor,
      },
      [GreenhouseGasEmissionSerializedHeaders.formulaCf]: {
        serialized: GreenhouseGasEmissionSerializedHeaders.formulaCf,
        unserialized: GreenhouseGasEmissionUnserializedHeaders.formulaCf,
        view: IntlKeys.referenceTablesGreenhouseGasEmissionFormulaCf,
      },
      [GreenhouseGasEmissionSerializedHeaders.co2Unit]: {
        serialized: GreenhouseGasEmissionSerializedHeaders.co2Unit,
        unserialized: GreenhouseGasEmissionUnserializedHeaders.co2Unit,
        view: IntlKeys.referenceTablesGreenhouseGasEmissionCo2Unit,
      },
      [GreenhouseGasEmissionSerializedHeaders.status]: {
        serialized: GreenhouseGasEmissionSerializedHeaders.status,
        unserialized: GreenhouseGasEmissionUnserializedHeaders.status,
      },
      [GreenhouseGasEmissionSerializedHeaders.uploadType]: {
        serialized: GreenhouseGasEmissionSerializedHeaders.uploadType,
        unserialized: GreenhouseGasEmissionUnserializedHeaders.uploadType,
      },
      [GreenhouseGasEmissionSerializedHeaders.notes]: {
        serialized: GreenhouseGasEmissionSerializedHeaders.notes,
        unserialized: GreenhouseGasEmissionUnserializedHeaders.notes,
      },
      [GreenhouseGasEmissionSerializedHeaders.concepts]: {
        serialized: GreenhouseGasEmissionSerializedHeaders.concepts,
        unserialized: GreenhouseGasEmissionUnserializedHeaders.concepts,
        view: IntlKeys.concepts,
      },
      [GreenhouseGasEmissionSerializedHeaders.filename]: {
        serialized: GreenhouseGasEmissionSerializedHeaders.filename,
        unserialized: GreenhouseGasEmissionUnserializedHeaders.filename,
      },
      [GreenhouseGasEmissionSerializedHeaders.validDateFrom]: {
        serialized: GreenhouseGasEmissionSerializedHeaders.validDateFrom,
        unserialized: GreenhouseGasEmissionUnserializedHeaders.validDateFrom,
      },
      [GreenhouseGasEmissionSerializedHeaders.validDateTo]: {
        serialized: GreenhouseGasEmissionSerializedHeaders.validDateTo,
        unserialized: GreenhouseGasEmissionUnserializedHeaders.validDateTo,
      },
      [GreenhouseGasEmissionSerializedHeaders.uploadedAt]: {
        serialized: GreenhouseGasEmissionSerializedHeaders.uploadedAt,
        unserialized: GreenhouseGasEmissionUnserializedHeaders.uploadedAt,
      },
      [GreenhouseGasEmissionSerializedHeaders.uploadedBy]: {
        serialized: GreenhouseGasEmissionSerializedHeaders.uploadedBy,
        unserialized: GreenhouseGasEmissionUnserializedHeaders.uploadedBy,
      },
    },
  },
};
