import { SERVICE_FIELDS } from 'lib/excel/config/service';
import { IntlKeys } from 'lib/localization/keys/__keys';

enum AdminConceptsOriginal {
  AdminConcepts = 'Concept',
}

enum AdminConceptsSerialized {
  AdminConcepts = 'AdminConcepts',
}

enum AdminConceptsUnserialized {
  AdminConcepts = 'AdminConcepts',
}

export const AdminConceptsUnserializedHeaders = {
  name: 'name',
  companies: 'companies',
  inUse: 'in_use'
};

export enum AdminConceptsSerializedHeaders {
  name = 'name',
  companies = 'companies',
  inUse = 'inUse'
}

export enum AdminConceptsOriginalHeaders {
  name = 'name',
  companies = 'companies',
  inUse = 'in_use'
}

export const ADMIN_CONCEPTS = {
  [AdminConceptsSerialized.AdminConcepts]: {
    serialized: AdminConceptsSerialized.AdminConcepts,
    unserialized: AdminConceptsUnserialized.AdminConcepts,
    original: AdminConceptsOriginal.AdminConcepts,
    view: IntlKeys.concepts,
    viewName: 'Concepts',

    columns: {
      [SERVICE_FIELDS.id.serialized]: {
        serialized: SERVICE_FIELDS.id.serialized,
        unserialized: SERVICE_FIELDS.id.unserialized,
        original: SERVICE_FIELDS.id.unserialized,
        view: SERVICE_FIELDS.id.view,
      },
      [AdminConceptsSerializedHeaders.name]: {
        serialized: AdminConceptsSerializedHeaders.name,
        unserialized: AdminConceptsUnserializedHeaders.name,
        original: AdminConceptsOriginalHeaders.name,
        view: IntlKeys.name,
      },
      [AdminConceptsSerializedHeaders.companies]: {
        serialized: AdminConceptsSerializedHeaders.companies,
        unserialized: AdminConceptsUnserializedHeaders.companies,
        original: AdminConceptsOriginalHeaders.companies,
        view: IntlKeys.adminUsersCompanies,
      },
      [AdminConceptsSerializedHeaders.inUse]: {
        serialized: AdminConceptsSerializedHeaders.inUse,
        unserialized: AdminConceptsUnserializedHeaders.inUse,
        original: AdminConceptsOriginalHeaders.inUse,
      },
      [SERVICE_FIELDS.createdAt.serialized]: {
        serialized: SERVICE_FIELDS.createdAt.serialized,
        unserialized: SERVICE_FIELDS.createdAt.unserialized,
        original: SERVICE_FIELDS.createdAt.unserialized,
        view: SERVICE_FIELDS.createdAt.view,
      },
      [SERVICE_FIELDS.updatedAt.serialized]: {
        serialized: SERVICE_FIELDS.updatedAt.serialized,
        unserialized: SERVICE_FIELDS.updatedAt.unserialized,
        original: SERVICE_FIELDS.updatedAt.unserialized,
        view: SERVICE_FIELDS.updatedAt.view,
      },
    },
  },
};
