enum OrgPassportOptionsSerialized {
  OrgPassportOptions = 'OrgPassportOptions',
}

enum OrgPassportOptionsUnserialized {
  OrgPassportOptions = 'OrgPassportOptions',
}

export enum OrgPassportOptionsSerializedHeaders {
  feedSuppliers = 'feedSuppliers',
  geneticSuppliers = 'geneticSuppliers',
  sowLines = 'sowLines',
  sireLines = 'sireLines',
  veterinaryPractice = 'veterinaryPractice',
  fmsModuleAgrisysts = 'fmsModuleAgrisysts',
  fmsModuleAgroCoops = 'fmsModuleAgroCoops',
  fmsModuleAgrovisions = 'fmsModuleAgrovisions',
  feedingSystems = 'feedingSystems',
  climateSystems = 'climateSystems',
  kiStation = 'kiStation',
  fmsNames = 'fmsNames',
  certificates = 'certificates',
}

export enum OrgPassportOptionsUnserializedHeaders {
  feedSuppliers = 'feed_suppliers',
  geneticSuppliers = 'genetic_suppliers',
  sowLines = 'sow_lines',
  sireLines = 'sire_lines',
  veterinaryPractice = 'veterinary_practice',
  fmsModuleAgrisysts = 'fms_module_agrisysts',
  fmsModuleAgroCoops = 'fms_module_agro_coops',
  fmsModuleAgrovisions = 'fms_module_agrovisions',
  feedingSystems = 'feeding_systems',
  climateSystems = 'climate_systems',
  kiStation = 'ki_stations',
  fmsNames = 'fms_names',
  certificates = 'certificates',
}

export const ORG_PASSPORT_OPTIONS = {
  [OrgPassportOptionsSerialized.OrgPassportOptions]: {
    serialized: OrgPassportOptionsSerialized.OrgPassportOptions,
    unserialized: OrgPassportOptionsUnserialized.OrgPassportOptions,
    columns: {
      [OrgPassportOptionsSerializedHeaders.feedSuppliers]: {
        serialized: OrgPassportOptionsSerializedHeaders.feedSuppliers,
        unserialized: OrgPassportOptionsUnserializedHeaders.feedSuppliers,
      },
      [OrgPassportOptionsSerializedHeaders.geneticSuppliers]: {
        serialized: OrgPassportOptionsSerializedHeaders.geneticSuppliers,
        unserialized: OrgPassportOptionsUnserializedHeaders.geneticSuppliers,
      },
      [OrgPassportOptionsSerializedHeaders.sowLines]: {
        serialized: OrgPassportOptionsSerializedHeaders.sowLines,
        unserialized: OrgPassportOptionsUnserializedHeaders.sowLines,
      },
      [OrgPassportOptionsSerializedHeaders.sireLines]: {
        serialized: OrgPassportOptionsSerializedHeaders.sireLines,
        unserialized: OrgPassportOptionsUnserializedHeaders.sireLines,
      },
      [OrgPassportOptionsSerializedHeaders.veterinaryPractice]: {
        serialized: OrgPassportOptionsSerializedHeaders.veterinaryPractice,
        unserialized: OrgPassportOptionsUnserializedHeaders.veterinaryPractice,
      },
      [OrgPassportOptionsSerializedHeaders.fmsModuleAgrisysts]: {
        serialized: OrgPassportOptionsSerializedHeaders.fmsModuleAgrisysts,
        unserialized: OrgPassportOptionsUnserializedHeaders.fmsModuleAgrisysts,
      },
      [OrgPassportOptionsSerializedHeaders.fmsModuleAgroCoops]: {
        serialized: OrgPassportOptionsSerializedHeaders.fmsModuleAgroCoops,
        unserialized: OrgPassportOptionsUnserializedHeaders.fmsModuleAgroCoops,
      },
      [OrgPassportOptionsSerializedHeaders.fmsModuleAgrovisions]: {
        serialized: OrgPassportOptionsSerializedHeaders.fmsModuleAgrovisions,
        unserialized: OrgPassportOptionsUnserializedHeaders.fmsModuleAgrovisions,
      },
      [OrgPassportOptionsSerializedHeaders.feedingSystems]: {
        serialized: OrgPassportOptionsSerializedHeaders.feedingSystems,
        unserialized: OrgPassportOptionsUnserializedHeaders.feedingSystems,
      },
      [OrgPassportOptionsSerializedHeaders.climateSystems]: {
        serialized: OrgPassportOptionsSerializedHeaders.climateSystems,
        unserialized: OrgPassportOptionsUnserializedHeaders.climateSystems,
      },
      [OrgPassportOptionsSerializedHeaders.kiStation]: {
        serialized: OrgPassportOptionsSerializedHeaders.kiStation,
        unserialized: OrgPassportOptionsUnserializedHeaders.kiStation,
      },
      [OrgPassportOptionsSerializedHeaders.fmsNames]: {
        serialized: OrgPassportOptionsSerializedHeaders.fmsNames,
        unserialized: OrgPassportOptionsUnserializedHeaders.fmsNames,
      },
      [OrgPassportOptionsSerializedHeaders.certificates]: {
        serialized: OrgPassportOptionsSerializedHeaders.certificates,
        unserialized: OrgPassportOptionsUnserializedHeaders.certificates,
      },
    },
  },
};
