import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer } from 'lib/excel/serilizers/Cell';
import { ResourcesWrapper } from 'lib/http/utils';

import { WATER_CONSUMPTION_RATES } from './config';
import { ManageUploadPayload } from '../manageUploads';

export type WaterConsumptionRatesPayload = {
  id: number;
  household_people_quantity: string | null;
  water_consumption: number | null;
  water_consumption_rate_upload: ManageUploadPayload;
};

export type WaterConsumptionRatesSerialized = {
  id: number;
  referenceUploadID: number;
  householdPeopleQuantity: string;
  waterConsumption: number;
  uploadType: string;
  status: 'active' | 'inactive';
  filename: string;
  validDateFrom: string | null;
  validDateTo: string | null;
  notes: string;
  concepts: string[];
  uploadedAt: string;
  uploadedBy: string;
};

const { columns, serialized, unserialized } = WATER_CONSUMPTION_RATES.WaterConsumptionRates;

const getDefaultSerializer = (data: DocumentData<WaterConsumptionRatesPayload[]>) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(unserialized, serialized, [
      new CellSerializer(columns.id.unserialized, columns.id.serialized),
      new CellSerializer(columns.referenceUploadID.unserialized, columns.referenceUploadID.serialized),
      new CellSerializer(columns.householdPeopleQuantity.unserialized, columns.householdPeopleQuantity.serialized),
      new CellSerializer(columns.waterConsumption.unserialized, columns.waterConsumption.serialized),
      new CellSerializer(columns.uploadType.unserialized, columns.uploadType.serialized),
      new CellSerializer(columns.status.unserialized, columns.status.serialized),
      new CellSerializer(columns.filename.unserialized, columns.filename.serialized),
      new CellSerializer(
        columns.validDateFrom.unserialized,
        columns.validDateFrom.serialized,
        undefined,
        undefined,
        null,
      ),
      new CellSerializer(columns.validDateTo.unserialized, columns.validDateTo.serialized, undefined, undefined, null),
      new CellSerializer(columns.notes.unserialized, columns.notes.serialized),
      new CellSerializer(columns.concepts.unserialized, columns.concepts.serialized),
      new CellSerializer(columns.uploadedAt.unserialized, columns.uploadedAt.serialized),
      new CellSerializer(columns.uploadedBy.unserialized, columns.uploadedBy.serialized),
    ]),
  ]);
};

export const serializeWaterConsumptionRates = (serverData: ResourcesWrapper<WaterConsumptionRatesPayload>) => {
  const data = {
    [unserialized]: serverData.data.resources,
  };

  const serializer = getDefaultSerializer(data);

  return serializer.serialize();
};
